<template>
  <div class="dictionary-management-container">
    <div class="dictionary-management-box">
      <div class="left">
        <div class="header">
          <span class="title">字典类型列表</span>
          <el-button type="primary" @click="addType" class="add-button">添加类型</el-button>
        </div>
        <div class="line"></div>
        <el-scrollbar style="height: calc(100vh - 120px)">
          <el-table
            :data="types"
            class="custom-table"
            :show-header="false"
            highlight-current-row="true"
            @row-click="handleTypeClick"
          >
            <el-table-column
              prop="typeName"
              label="类型名称"
              width="200"
              align="center"
              header-align="center"
            ></el-table-column>
            <el-table-column
              prop="typeCode"
              label="类型代码"
              width="200"
              align="center"
              header-align="center"
            ></el-table-column>
            <el-table-column label="操作" align="center" header-align="center" width="150">
              <template v-slot="scope">
                <div class="icon-group">
                  <el-button @click="editType(scope.row)" type="button">
                    <el-icon size="14">
                      <Edit />
                    </el-icon>
                    编辑
                  </el-button>
                  <el-button @click="deleteType(scope.row)" type="button">
                    <el-icon size="14">
                      <Delete />
                    </el-icon>
                    删除
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
      </div>
      <div class="right">
        <div class="header">
          <span class="title">字典项</span>
          <el-button type="primary" @click="showAddItemDialog" class="add-button">添加字典项</el-button>
        </div>
        <div class="line"></div>
        <div class="table-container">
          <el-table :data="items" highlight-current-row="true" :show-header="false" class="table" width="60%">
            <el-table-column
              prop="dictLabel"
              label="字典项名称"
              width="auto"
              min-width="20%"
              align="center"
              header-align="center"
            ></el-table-column>
            <el-table-column
              prop="dictValue"
              label="字典项代码"
              width="auto"
              min-width="20%"
              align="center"
              header-align="center"
            ></el-table-column>
            <el-table-column label="操作" align="center" header-align="center" width="auto" min-width="20%">
              <template v-slot="scope">
                <div class="icon-group">
                  <el-button @click="editItem(scope.row)" type="button">
                    <el-icon size="16">
                      <Edit />
                    </el-icon>
                    编辑
                  </el-button>
                  <el-button @click="deleteItem(scope.row)" type="button">
                    <el-icon size="16">
                      <Delete />
                    </el-icon>
                    删除
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- 编辑字典类型弹出框 -->
    <el-dialog :title="editDialogTitle" v-model="editDialogVisible" width="30%">
      <el-form :model="editForm">
        <el-form-item label="类型代码" label-width="100px">
          <el-input v-model="editForm.typeCode" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="类型名称" label-width="100px">
          <el-input v-model="editForm.typeName" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="saveEdit">保存</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 添加字典项弹出框 -->
    <el-dialog title="添加字典项" v-model="addItemDialogVisible" width="30%">
      <el-form :model="newItemForm">
        <el-form-item label="字典项名称" label-width="100px">
          <el-input v-model="newItemForm.dictLabel" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="字典项代码" label-width="100px">
          <el-input v-model="newItemForm.dictValue" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addItemDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="saveNewItem">保存</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 编辑字典项弹出框 -->
    <el-dialog title="编辑字典项" v-model="editItemDialogVisible" width="30%">
      <el-form :model="editItemForm">
        <el-form-item label="字典项名称" label-width="100px">
          <el-input v-model="editItemForm.dictLabel" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="字典项代码" label-width="100px">
          <el-input v-model="editItemForm.dictValue" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editItemDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="saveEditItem">保存</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { Edit, Delete } from '@element-plus/icons-vue'
import { useAuthStore } from '@/stores/authStore'
import { ElLoading } from 'element-plus'

export default {
  components: {
    Edit,
    Delete
  },
  data() {
    return {
      types: [],
      items: [],
      editDialogVisible: false,
      editDialogTitle: '编辑字典类型',
      editForm: {
        typeCode: '',
        typeName: ''
      },
      addItemDialogVisible: false,
      newItemForm: {
        dictLabel: '',
        dictValue: ''
      },
      selectedType: null,
      editItemForm: {
        id: null,
        typeCode: '',
        typeName: '',
        dictLabel: '',
        dictValue: '',
        status: 1,
        type: 1
      },
      editItemDialogVisible: false
    }
  },
  methods: {
    async fetchDictList() {
      const loading = ElLoading.service({
        fullscreen: true,
        lock: true,
        text: '加载中...',
        background: 'rgba(255, 255, 255, 0.1)'
      })
      const authStore = useAuthStore()
      try {
        const response = await authStore.getDictTypeList()
        if (response.code === 200) {
          this.types = response.data
          if (this.types.length > 0) {
            this.handleTypeClick(this.types[0])
          }
        } else {
          this.$message.error('获取字典列表失败')
        }
      } catch (error) {
        console.error('Failed to fetch dictionary list:', error)
        this.$message.error('获取字典列表失败')
      } finally {
        loading.close()
      }
    },
    async fetchDictItems(typeCode) {
      const loading = ElLoading.service({
        fullscreen: true,
        lock: true,
        text: '加载中...',
        background: 'rgba(255, 255, 255, 0.1)'
      })
      const authStore = useAuthStore()
      try {
        const response = await authStore.getDictListByTypeCode(typeCode)
        if (response.code === 200) {
          this.items = response.data
        } else {
          this.$message.error('获取字典项失败')
        }
      } catch (error) {
        console.error('Failed to fetch dictionary items:', error)
        this.$message.error('获取字典项失败')
      } finally {
        loading.close()
      }
    },
    handleTypeClick(row) {
      this.selectedType = row
      this.fetchDictItems(row.typeCode)
    },
    addType() {
      this.editDialogTitle = '添加字典类型'
      this.editForm = { typeCode: '', typeName: '' }
      this.editDialogVisible = true
    },
    editType(type) {
      this.editDialogTitle = '编辑字典类型'
      this.editForm = { ...type }
      this.editDialogVisible = true
    },
    async saveEdit() {
      const loading = ElLoading.service({
        fullscreen: true,
        lock: true,
        text: '加载中...',
        background: 'rgba(255, 255, 255, 0.1)'
      })
      const authStore = useAuthStore()
      try {
        if (this.editDialogTitle === '编辑字典类型') {
          const response = await authStore.updateDictType(this.editForm.typeCode, this.editForm)
          if (response.code === 200) {
            this.$message.success('更新成功')
            this.editDialogVisible = false
            this.fetchDictList()
          } else {
            this.$message.error('更新失败')
          }
        } else if (this.editDialogTitle === '添加字典类型') {
          if (
            this.types.some(
              (type) => type.typeCode === this.editForm.typeCode || type.typeName === this.editForm.typeName
            )
          ) {
            this.$message.error('类型名称或代码已存在，请重新输入')
            return
          }
          const response = await authStore.addDictType(this.editForm)
          if (response.code === 200) {
            this.$message.success('添加成功')
            this.editDialogVisible = false
            this.fetchDictList()
          } else {
            this.$message.error('添加失败')
          }
        }
      } catch (error) {
        console.error('Failed to save dictionary type:', error)
        this.$message.error('保存失败')
      } finally {
        loading.close()
      }
    },
    async deleteType(type) {
      const loading = ElLoading.service({
        fullscreen: true,
        lock: true,
        text: '加载中...',
        background: 'rgba(255, 255, 255, 0.1)'
      })
      const authStore = useAuthStore()
      try {
        const response = await authStore.deleteDictType(type.typeCode)
        if (response.code === 200) {
          this.$message.success('删除成功')
          this.fetchDictList()
        } else {
          this.$message.error('删除失败')
        }
      } catch (error) {
        console.error('Failed to delete dictionary type:', error)
        this.$message.error('删除失败')
      } finally {
        loading.close()
      }
    },
    showAddItemDialog() {
      this.newItemForm = { dictLabel: '', dictValue: '' }
      this.addItemDialogVisible = true
    },
    async saveNewItem() {
      const loading = ElLoading.service({
        fullscreen: true,
        lock: true,
        text: '加载中...',
        background: 'rgba(255, 255, 255, 0.1)'
      })
      const authStore = useAuthStore()
      const itemData = {
        typeCode: this.selectedType.typeCode,
        typeName: this.selectedType.typeName,
        dictLabel: this.newItemForm.dictLabel,
        dictValue: this.newItemForm.dictValue
      }
      try {
        if (
          this.items.some(
            (item) => item.dictLabel === this.newItemForm.dictLabel || item.dictValue === this.newItemForm.dictValue
          )
        ) {
          this.$message.error('字典项名称或代码已存在，请重新输入')
          return
        }
        const response = await authStore.addDictItem(itemData)
        if (response.code === 200) {
          this.$message.success('添加成功')
          this.addItemDialogVisible = false
          this.fetchDictItems(itemData.typeCode)
        } else {
          this.$message.error('添加失败')
        }
      } catch (error) {
        console.error('Failed to add dictionary item:', error)
        this.$message.error('添加失败')
      } finally {
        loading.close()
      }
    },
    editItem(item) {
      this.editItemForm = {
        id: item.id,
        typeCode: this.selectedType.typeCode,
        typeName: this.selectedType.typeName,
        dictLabel: item.dictLabel,
        dictValue: item.dictValue,
        status: 1,
        type: 1
      }
      this.editItemDialogVisible = true
    },
    async saveEditItem() {
      const loading = ElLoading.service({
        fullscreen: true,
        lock: true,
        text: '加载中...',
        background: 'rgba(255, 255, 255, 0.1)'
      })
      const authStore = useAuthStore()
      try {
        const response = await authStore.updateDictItem(this.editItemForm.id, this.editItemForm)
        if (response.code === 200) {
          this.$message.success('更新成功')
          this.editItemDialogVisible = false
          this.fetchDictItems(this.editItemForm.typeCode)
        } else {
          this.$message.error('更新失败')
        }
      } catch (error) {
        console.error('Failed to update dictionary item:', error)
        this.$message.error('更新失败')
      } finally {
        loading.close()
      }
    },
    async deleteItem(item) {
      const loading = ElLoading.service({
        fullscreen: true,
        lock: true,
        text: '加载中...',
        background: 'rgba(255, 255, 255, 0.1)'
      })
      const authStore = useAuthStore()
      try {
        const response = await authStore.deleteDictItem(item.id)
        if (response.code === 200) {
          this.$message.success('删除成功')
          this.fetchDictItems(this.selectedType.typeCode)
        } else {
          this.$message.error('删除失败')
        }
      } catch (error) {
        console.error('Failed to delete dictionary item:', error)
        this.$message.error('删除失败')
      } finally {
        loading.close()
      }
    }
  },
  mounted() {
    this.fetchDictList()
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
  /* 确保 padding 不会增加元素的总高度 */
}

.dictionary-management-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* 居左布局 */
  background-color: #474747;
  min-height: 100vh;
  font-size: 16px;
  line-height: 20px;
}

.dictionary-management-box {
  width: calc(100% - 20px);
  /* 减去左右padding */
  height: calc(100vh - 40px);
  /* 减去上下padding */
  box-sizing: border-box;
  display: flex;
  position: relative;
  margin: 20px 20px 20px 0px;
  position: relative;
}

.header {
  display: flex;
  align-items: center;
  padding: 20px 20px 0px 20px;
}

.title {
  color: #057ab2;
  font-size: 16px;
  font-weight: 500;
  margin-right: auto;
}

.add-button {
  margin-left: 20px;
  background-color: #057ab2;
  /* 上传模板按钮的背景色 */
  border-color: #057ab2;
}

.add-button:hover {
  margin-left: 20px;
  background-color: #005f8a;
  /* 上传模板按钮的背景色 */
  border-color: #005f8a;
}

.line {
  width: 100%;
  height: 1px;
  background-color: #ebeef5;
  margin-top: 20px;
  position: relative;
}

.left {
  width: 600px;
  border: #ebeef5 1px solid;
  border-radius: 12px;
  background-color: white;
  overflow: hidden;
  position: relative;
}

.right {
  border: #ebeef5 1px solid;
  border-radius: 12px;
  background-color: white;
  flex: 1;
  margin-left: 10px;
  overflow: auto;
  position: relative;
}

.el-table th,
.el-table td {
  text-align: center;
  /* 表头和单元格内容居中对齐 */
}

.icon-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  /* 图标之间的间距 */
}

.icon-group .el-button {
  border: none;
  color: #057ab2;
  background-color: transparent;
}
.icon-group .el-button + .el-button {
  margin-left: 0px;
}

.icon-group .el-button:hover {
  background-color: rgba(5, 122, 178, 0.25);
  color: #057ab2;
}

.ctrl {
  width: 88px;
  height: 32px;
  background-color: #057ab2;
}

.ctrl:hover {
  background-color: #005f8a;
  /* 上传模板按钮悬停时的背景色 */
  border-color: #005f8a;
}

.table-container {
  flex: 1;
  overflow: auto;
  position: relative;
}
.el-scrollbar__wrap {
  overflow-x: hidden;
}
.custom-table {
  width: 100%;
}

table {
  position: absolute;
  top: 0;
  left: 710px;
  right: 0;
  overflow: auto;
}
</style>

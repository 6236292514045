<template>
  <div class="template-management-container">
    <div class="template-management-box">
      <div class="header">
        <span class="title">打印模板</span>
        <el-input v-model="search" placeholder="请输入内容" class="search-input" clearable>
          <template #prefix>
            <el-icon>
              <Search />
            </el-icon>
          </template>
        </el-input>
        <el-button type="primary" @click="showDialog = true" class="upload-button">上传模板</el-button>
      </div>
      <el-scrollbar class="table-container">
        <el-table :data="filteredTemplates" class="custom-table" :header-cell-style="{ background: '#F5F6F7' }">
          <el-table-column prop="name" label="模板名称" width="auto" min-width="15%"></el-table-column>
          <el-table-column prop="type" label="模板类型" width="auto" min-width="15%"></el-table-column>
          <el-table-column label="分组模板" width="auto" min-width="25%">
            <template v-slot="scope">
              {{ scope.row.groupType === 1 ? '是' : '否' }}
            </template>
          </el-table-column>
          <el-table-column label="状态" width="auto" min-width="15%">
            <template v-slot="scope">
              {{ scope.row.status === 1 ? '启用' : '未启用' }}
            </template>
          </el-table-column>
          <el-table-column prop="updateTime" label="更新时间" width="auto" min-width="20%">
            <template v-slot="{ row }">
              {{ new Date(row.updateTime).toLocaleString() }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="auto" min-width="10%" align="center" header-align="center">
            <template v-slot="scope">
              <div class="icon-group">
                <el-button class="action1" @click="editTemplate(scope.row)">
                  <el-icon size="16">
                    <Edit />
                  </el-icon>
                </el-button>
                <el-button class="action1"
                  ><el-icon size="16" @click="deleteTemplate(scope.row)">
                    <Delete />
                  </el-icon>
                </el-button>
                <el-button class="action1"
                  ><el-icon size="16" @click="viewTemplate(scope.row)">
                    <View />
                  </el-icon>
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-scrollbar>
      <el-dialog title="打印模板设置" v-model="showDialog" width="360px" style="border-radius: 8px">
        <el-form>
          <el-form-item label="模板名称">
            <el-input v-model="form.templateName" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="模板类型">
            <el-select v-model="form.templateType" placeholder="请选择">
              <el-option label="A4" value="A4"></el-option>
              <el-option label="A5" value="A5"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分组模板">
            <el-radio-group v-model="form.groupTemplate">
              <el-radio label="是"></el-radio>
              <el-radio label="否"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="模板状态">
            <el-radio-group v-model="form.templateStatus">
              <el-radio label="启用"></el-radio>
              <el-radio label="禁用"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="上传文件">
            <el-upload :http-request="customUploadRequest" list-type="text" :on-error="handleUploadError" :limit="1">
              <el-button type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="showDialog = false" style="width: 88px; height: 32px">关闭</el-button>
          <el-button type="primary" class="ctrl" @click="saveTemplate">保存</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { Search, Edit, Delete, View } from '@element-plus/icons-vue'
import { useAuthStore } from '@/stores/authStore'
import { ElLoading } from 'element-plus'

export default {
  components: {
    Search,
    Edit,
    Delete,
    View
  },
  data() {
    return {
      search: '',
      showDialog: false,
      form: {
        templateName: '',
        templateType: 'A4', // 默认选择A4
        groupTemplate: '否',
        templateStatus: '启用'
      },
      templates: [],
      currentPage: 1,
      pageSize: 10,
      isEditMode: false
    }
  },
  computed: {
    filteredTemplates() {
      if (!this.search) return this.templates
      const lowerSearch = this.search.toLowerCase()
      return this.templates.filter((template) =>
        Object.values(template).some((value) => String(value).toLowerCase().includes(lowerSearch))
      )
    }
  },
  methods: {
    async fetchTemplates() {
      const loading = ElLoading.service({
        fullscreen: true,
        lock: true,
        text: '加载中...',
        background: 'rgba(255, 255, 255, 0.1)'
      })
      const authStore = useAuthStore()
      const params = { pageNumber: 1, pageSize: 10000 }
      try {
        const response = await authStore.getTemplateList(params)
        console.log(response)
        this.templates = response.data.data
        console.log(this.templates)
      } catch (error) {
        console.error('Failed to fetch templates:', error)
      } finally {
        loading.close()
      }
    },
    uploadTemplate() {
      console.log('上传模板')
      // 上传模板的逻辑
    },
    editTemplate(template) {
      console.log('编辑模板', template)
      this.currentTemplateId = template.id
      this.form = {
        templateName: template.name,
        templateType: template.type,
        groupTemplate: template.groupType === 1 ? '是' : '否',
        templateStatus: template.status === 1 ? '启用' : '禁用'
      }
      this.isEditMode = true
      this.showDialog = true
    },
    async deleteTemplate(template) {
      const loading = ElLoading.service({
        fullscreen: true,
        lock: true,
        text: '加载中...',
        background: 'rgba(255, 255, 255, 0.1)'
      })
      const authStore = useAuthStore()
      try {
        const response = await authStore.deleteTemplate(template.id)
        if (response.code === 200) {
          this.templates = this.templates.filter((t) => t.id !== template.id)
          this.$message.success('模板删除成功')
        } else {
          this.$message.error('模板删除失败')
        }
      } catch (error) {
        console.error('Failed to delete template:', error)
        this.$message.error('模板删除失败')
      } finally {
        loading.close()
      }
    },
    async viewTemplate(template) {
      const loading = ElLoading.service({
        fullscreen: true,
        lock: true,
        text: '加载中...',
        background: 'rgba(255, 255, 255, 0.1)'
      })
      const authStore = useAuthStore()
      try {
        const pdfData = await authStore.getTemplatePDF(template.id)
        const url = window.URL.createObjectURL(new Blob([pdfData], { type: 'application/pdf' }))
        window.open(url, '_blank')
      } catch (error) {
        console.error('Failed to view template PDF:', error)
        this.$message.error('无法查看模板PDF')
      } finally {
        loading.close()
      }
    },
    handlePageChange(page) {
      this.currentPage = page
    },
    async customUploadRequest({ file }) {
      try {
        const authStore = useAuthStore()
        const response = await authStore.uploadFile(file)
        this.handleUploadSuccess(response, file)
      } catch (error) {
        console.error('Failed to upload file:', error)
        this.$message.error('文件上传失败')
      }
    },
    async handleUploadSuccess(response, file) {
      try {
        // 去掉文件后缀
        const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.')
        this.form.templateName = fileNameWithoutExtension
        this.form.fileUrl = response.data // 假设服务器返回文件地址
        this.$message.success('文件上传成功')
      } catch (error) {
        console.error('Failed to process upload success:', error)
        this.$message.error('文件上传失败')
      }
    },
    async addTemplate() {
      const loading = ElLoading.service({
        fullscreen: true,
        lock: true,
        text: '加载中...',
        background: 'rgba(255, 255, 255, 0.1)'
      })
      const authStore = useAuthStore()
      const templateData = [
        {
          name: this.form.templateName,
          url: this.form.fileUrl,
          type: this.form.templateType,
          groupType: this.form.groupTemplate === '是' ? 1 : 0,
          deviceTypeId: null
        }
      ]
      try {
        const response = await authStore.addTemplate(templateData)
        if (response.code === 200) {
          this.templates.push(response.data[0])
          this.showDialog = false
          this.$message.success('模板添加成功')
        } else {
          this.$message.error('模板添加失败')
        }
      } catch (error) {
        console.error('Failed to add template:', error)
        this.$message.error('模板添加失败')
      } finally {
        loading.close()
      }
    },
    async updateTemplate() {
      const loading = ElLoading.service({
        fullscreen: true,
        lock: true,
        text: '加载中...',
        background: 'rgba(255, 255, 255, 0.1)'
      })
      const authStore = useAuthStore()
      const templateData = {
        name: this.form.templateName,
        type: this.form.templateType,
        groupType: this.form.groupTemplate === '是' ? 1 : 0,
        status: this.form.templateStatus === '启用' ? 1 : 0,
        fileUrl: this.form.fileUrl
      }
      try {
        const response = await authStore.updateTemplate(this.currentTemplateId, templateData)
        if (response.code === 200) {
          const index = this.templates.findIndex((t) => t.id === this.currentTemplateId)
          if (index !== -1) {
            this.templates.splice(index, 1, response.data)
          }
          this.showDialog = false
          this.$message.success('模板更新成功')
        } else {
          this.$message.error('模板更新失败')
        }
      } catch (error) {
        console.error('Failed to update template:', error)
        this.$message.error('模板更新失败')
      } finally {
        loading.close()
      }
    },
    saveTemplate() {
      if (this.isEditMode) {
        this.updateTemplate()
      } else {
        this.addTemplate()
      }
      this.isEditMode = false
    }
  },
  async mounted() {
    await this.fetchTemplates()
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
  /* 确保 padding 不会增加元素的总高度 */
}

.template-management-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* 居左布局 */
  background-color: #474747;
  min-height: 100vh;
  font-size: 16px;
  line-height: 20px;
}

.template-management-box {
  width: calc(100% - 20px);
  /* 减去左右padding */
  height: calc(100vh - 40px);
  /* 减去上下padding */
  background-color: #f0f3f5;
  border-radius: 12px;
  padding: 20px 20px 0px 20px;
  box-sizing: border-box;
  position: relative;
  margin: 20px 20px 20px 0px;
  /* 距离顶部20px */
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  color: #057ab2;
  font-size: 16px;
  font-weight: 500;
  margin-right: auto;
}

.search-input {
  width: 300px;
  margin-right: 20px;
}

.upload-button {
  margin-left: 20px;
  background-color: #057ab2;
  /* 上传模板按钮的背景色 */
  border-color: #057ab2;
}

.upload-button:hover {
  background-color: #005f8a;
  /* 上传模板按钮悬停时的背景色 */
  border-color: #005f8a;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}
.custom-table {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  width: 100%;
}
.pagination {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.icon-group {
  display: flex;
  gap: 2px;
}

.icon-group .action1 {
  border: none;
  color: #057ab2;
  background-color: transparent;
}

.icon-group .action1:hover {
  background-color: rgba(5, 122, 178, 0.25);
  color: #057ab2;
  border: none;
}

:deep(.icon-group .el-button + .el-button) {
  margin-left: 0px;
}

.el-table th,
.el-table td {
  text-align: center;
  /* 表头和单元格内容居中对齐 */
}

.el-pagination .el-pager .el-pagination__pager li.active {
  background-color: #057ab2;
  /* 分页选中当前页背景色 */
  border-color: #057ab2;
  color: #fff;
}

.table-container {
  /* flex: 1; */
  overflow: auto;
  position: relative;
  height: calc(100vh - 125px);
}

.adddialog {
  border-radius: 8px;
}

.ctrl {
  width: 88px;
  height: 32px;
  background-color: #057ab2;
}

.ctrl:hover {
  background-color: #005f8a;
  /* 上传模板按钮悬停时的背景色 */
  border-color: #005f8a;
}

.pagination-container {
  height: 52px;
  /* 与表格底部预留空间匹配 */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.pagination {
  display: flex;
  align-items: center;
}

.pagination-info {
  text-align: center;
  margin-left: 10px;
  margin-top: 10px;
}
</style>

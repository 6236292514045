<template>
  <div class="user-management-container">
    <div class="user-management-box">
      <div class="header">
        <span class="title">用户管理</span>
        <div class="actions">
          <el-input placeholder="请输入" v-model="searchQuery" class="search-input">
            <template #prefix>
              <el-icon>
                <Search />
              </el-icon>
            </template>
          </el-input>
          <el-button type="primary" @click="syncUsers" class="custom-button1">同步用户</el-button>
          <el-button type="primary" @click="openDialog('add')" class="custom-button">添加用户</el-button>
        </div>
      </div>
      <div class="table-container">
        <el-table :data="paginatedData" class="custom-table" :header-cell-style="{ background: '#F5F6F7' }" style="height: 100%;">
          <el-table-column prop="name" label="姓名" width="auto" min-width="8%">
            <template v-slot="scope">
              <div class="name-column">
                <el-avatar :src="scope.row.avatar" class="user-avatar"></el-avatar>
                <span>{{ scope.row.avatar }}</span><span>{{ scope.row.name }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="code" label="工号" width="auto" min-width="7%"></el-table-column>
          <el-table-column prop="sex" label="性别" width="auto" min-width="5%">
            <template v-slot="scope">
              <span v-if="scope.row.sex !== null">
                {{ scope.row.sex === 'man' ? '男' : '女' }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="birthdayDate" label="出生日期" width="auto" min-width="10%"
            :formatter="formatBirthdayDate">
          </el-table-column>
          <el-table-column prop="account" label="账号" width="auto" min-width="10%"></el-table-column>
          <el-table-column prop="roles" show-overflow-tooltip label="角色" width="auto" min-width="10%">
            <template v-slot="scope">
              <span>{{ scope.row.roles.map((role) => role.roleName).join(', ') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="签名" width="auto" min-width="9%">
            <template v-slot="scope">
              <img :src="scope.row.signImage" class="signature-image" style="height: 26px" />
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态" width="auto" min-width="8%">
            <template v-slot="scope">
              <span :class="scope.row.status == '1' ? 'status-published' : 'status-abord'">{{
                scope.row.status == '1' ? '已启用' : '已禁用'
              }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="departmentName" label="所属科室" width="auto" show-overflow-tooltip
            min-width="8%"></el-table-column>
          <el-table-column prop="accountExpireDate" label="账号有效截止日期" width="auto" :formatter="formatAccountExpireDate"
            min-width="9%"></el-table-column>
          <el-table-column label="操作" width="auto" min-width="12%" align="center" header-align="center">
            <template v-slot="scope">
              <div class="action-buttons">
                <el-button class="action-button" @click="editUser(scope.row)">
                  <el-icon :size="18">
                    <Edit />
                  </el-icon>
                  <span>编辑</span>
                </el-button>
                <el-button class="action-button" style="margin-left: 2px" @click="configureUser(scope.row)">
                  <el-icon :size="14">
                    <img width="14" src="@/assets/dun.png" />
                  </el-icon>
                  <span>权限</span>
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-container">
        <el-pagination 
          background 
          layout="prev, pager, next, sizes,total " 
          :total="total" 
          :page-sizes="[25, 50, 100]"
          :page-size="pageSize"
          :current-page="currentPage" 
          @current-change="handlePageChange"
          @size-change="handleSizeChange"
          class="pagination">
        </el-pagination>
      </div>
    </div>
    <el-dialog :title="dialogTitle" v-model="showDialog" width="360px" style="border-radius: 8px" :close-on-click-modal="false" :close-on-press-escape="false">
      <el-form :model="currentUser" label-width="80px">
        <el-form-item label="头像">
          <div class="avatar-container">
            <img v-if="currentUser.avatar" :src="currentUser.avatar" style="width: 46px; height: 46px" />
            <el-upload v-else action="#" list-type="picture-card" :limit="1" :on-change="handleAvatarSuccess"
              :file-list="avatarFileList" :auto-upload="false">
              <el-icon class="avatar-uploader-icon">
                <Plus />
              </el-icon>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input v-model="currentUser.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item>
          <template #label> 工号 <span class="required-star">*</span> </template>
          <el-input v-model="currentUser.code" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-radio-group v-model="currentUser.sex">
            <el-radio value="男">男</el-radio>
            <el-radio value="女">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="生日">
          <el-date-picker v-model="currentUser.birthdayDate" type="date" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <template #label> 账号 <span class="required-star">*</span> </template>
          <el-input v-model="currentUser.account" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="currentUser.password" type="password" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="失效日期">
          <el-date-picker v-model="currentUser.accountExpireDate" type="date" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <template #label> 角色 <span class="required-star">*</span> </template>
          <el-select v-model="currentUser.roles" multiple placeholder="请选择角色">
            <el-option v-for="role in roleOptions" :key="role.id" :label="role.roleName" :value="role.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="签名照片">
          <div class="signature-container">
            <img v-if="currentUser.signImage" :src="currentUser.signImage" style="width: 46px; height: 46px" />
            <el-upload v-else action="#" :auto-upload="false" list-type="picture" :limit="1"
              :on-change="handleSignatureSuccess" :file-list="signatureFileList">
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item>
          <template #label> 所属科室 <span class="required-star">*</span> </template>
          <el-select v-model="currentUser.deptId" placeholder="请选择科室" filterable>
            <el-option
              v-for="dept in departmentList"
              :key="dept.id"
              :label="dept.name"
              :value="dept.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false" class="footer-button">关闭</el-button>
        <el-button type="primary" @click="saveUser" class="footer-button">保存</el-button>
      </div>
    </el-dialog>

    <el-dialog title="数据权限设置" v-model="showPermissionsDialog" width="620px" :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="permissions-container">
        <div class="permissions-column">
          <h3>科室设备权限</h3>
          <hr class="full-width-line" />
          <el-scrollbar style="min-height: 400px; max-height: 400px">
            <el-tree ref="departmentTree" :data="departmentTree" show-checkbox node-key="id" default-expand-all
              :props="defaultProps" v-model="currentPermissions.departments">
            </el-tree>
          </el-scrollbar>
        </div>
        <div class="permissions-column" style="margin-left: 10px">
          <h3>报告字段编辑权限</h3>
          <hr class="full-width-line" />
          <el-scrollbar style="min-height: 400px; max-height: 400px">
            <el-checkbox v-model="isAllChecked" @change="toggleAllFields">全选</el-checkbox>
            <el-checkbox-group v-model="selectedFields" @change="handleFieldsChange">
              <el-checkbox v-for="field in displayFields" 
                          :key="field.column" 
                          :label="field.column">
                {{ field.label }}
              </el-checkbox>
            </el-checkbox-group>
          </el-scrollbar>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showPermissionsDialog = false" class="footer-button">关闭</el-button>
        <el-button type="primary" @click="savePermissions" class="footer-button">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Edit, Setting, Plus, Check, Close, Search } from '@element-plus/icons-vue'
import { useAuthStore } from '@/stores/authStore'
import dayjs from 'dayjs'
import { encryptPassword } from '@/utils/crypto'
export default {
  name: 'UserManagement',
  components: {
    Edit,
    Setting,
    Plus,
    Check,
    Close,
    Search
  },
  data() {
    return {
      isEdit: false,
      searchQuery: '',
      searchDebounceTimer: null,
      showDialog: false,
      showPermissionsDialog: false,
      dialogTitle: '添加用户',
      dialogVisible: false,
      dialogImageUrl: '',
      currentUser: {},
      hospitalList: [],
      departmentList: [],
      selectedHospitalCode: '',
      currentPermissions: {},
      isAllChecked: true,
      avatarFileList: [],
      signatureFileList: [],
      tableData: [],
      departmentTree: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      currentPage: 1,
      pageSize: 25,
      total: 0,
      selectedFields: [],
      loading: null,
    }
  },
  computed: {
    filteredTableData() {
      if (!this.searchQuery || this.searchQuery.trim() === '') {
        return this.tableData
      }
      const query = this.searchQuery.toLowerCase().trim()
      const result = []
      const len = this.tableData.length
      for (let i = 0; i < len; i++) {
        const user = this.tableData[i]
        if (
          (user.name && user.name.toLowerCase().includes(query)) ||
          (user.code && user.code.toLowerCase().includes(query)) ||
          (user.account && user.account.toLowerCase().includes(query))
        ) {
          result.push(user)
        }
      }
      return result
    },
    paginatedData() {
      if (this.searchQuery && this.searchQuery.trim() !== '') {
        const start = (this.currentPage - 1) * this.pageSize
        const end = start + this.pageSize
        return this.filteredTableData.slice(start, end)
      }
      return this.tableData
    },
    displayFields() {
      const authStore = useAuthStore()
      return (authStore.settings.patientFieldSettings || [])
        .filter(field => field.column !== 'id')
    }
  },
  methods: {
    debounce(func, delay = 300) {
      return (...args) => {
        if (this.searchDebounceTimer) {
          clearTimeout(this.searchDebounceTimer)
        }
        this.searchDebounceTimer = setTimeout(() => {
          func.apply(this, args)
        }, delay)
      }
    },
    formatBirthdayDate(row) {
      if (row.birthdayDate) {
        return dayjs(row.birthdayDate).format('YYYY-MM-DD')
      }
      return ''
    },
    formatAccountExpireDate(row) {
      if (row.accountExpireDate) {
        return dayjs(row.accountExpireDate).format('YYYY-MM-DD')
      }
      return ''
    },
    async syncUsers() {
      try {
        const loading = this.$loading({
          lock: true,
          text: '加载中...',
          background: 'rgba(255, 255, 255, 0.1)'
        })
        const authStore = useAuthStore()
        await authStore.syncUser()
        this.$message.success('用户同步成功')
        this.fetchHospitalList()
        const response = await authStore.getUser({ pageIndex: this.currentPage, pageSize: this.pageSize })
        this.tableData = response.data.data
        this.total = response.data.total
        loading.close()
      } catch (error) {
        this.$message.error('用户同步失败: ' + error.message)
        loading.close()
      }
    },
    async fetchHospitalList() {
      try {
        const loading = this.$loading({
          lock: true,
          text: '加载中...',
          background: 'rgba(255, 255, 255, 0.1)'
        })
        const authStore = useAuthStore()
        const response = await authStore.hospitallist()
        this.hospitalList = response.data

        // 获取所有医院的科室列表
        this.departmentList = []
        for (const hospital of this.hospitalList) {
          const deptResponse = await authStore.getDepartmentList(hospital.hospitalCode)
          this.departmentList.push(...deptResponse.data)
        }

        const deviceResponse = await authStore.devicelist()
        const deviceList = deviceResponse.data
        this.departmentTree = await Promise.all(
          this.hospitalList.map(async (hospital) => {
            const deptResponse = await authStore.getDepartmentList(hospital.hospitalCode)
            const departmentList = deptResponse.data
            return {
              id: hospital.hospitalCode,
              label: hospital.hospitalName,
              children: departmentList.map((department) => ({
                id: department.id,
                label: department.name,
                children: deviceList
                  .filter((device) => device.deptId === department.id)
                  .map((device) => ({
                    id: device.id,
                    label: device.name,
                    type: 'device'
                  }))
              }))
            }
          })
        )
        loading.close()
      } catch (error) {
        this.$message.error('获取医院列表失败: ' + error.message)
        loading.close()
      }
    },
    async fetchUsers(isReset = false) {
      if (this.loading) {
        return
      }
      
      try {
        this.loading = this.$loading({
          lock: true,
          text: '加载中...',
          background: 'rgba(255, 255, 255, 0.1)'
        })
        
        const authStore = useAuthStore()
        
        // 当搜索词为空时，直接使用分页查询
        if (!this.searchQuery || this.searchQuery.trim() === '') {
          const response = await authStore.getUser({ 
            pageIndex: this.currentPage, 
            pageSize: this.pageSize 
          })
          this.tableData = this.processUserData(response.data.data)
          this.total = response.data.total
        } else if (!isReset) { // 只在非重置状态下获取所有数据
          // 有搜索词时的逻辑
          const response = await authStore.getUser({ pageIndex: 1, pageSize: 99999 })
          this.tableData = this.processUserData(response.data.data)
          this.total = this.filteredTableData.length
        }
      } catch (error) {
        this.$message.error('用户列表请求失败 ' + error.message)
      } finally {
        if (this.loading) {
          this.loading.close()
          this.loading = null
        }
      }
    },
    processUserData(users) {
      return users.map((user) => {
        const department = this.departmentList.find((dept) => dept.id === user.deptId)
        return {
          ...user,
          departmentName: department ? department.name : ''
        }
      })
    },
    async fetchRoles() {
      try {
        const loading = this.$loading({
          lock: true,
          text: '加载中...',
          background: 'rgba(255, 255, 255, 0.1)'
        })
        const authStore = useAuthStore()
        const response = await authStore.rolelist()
        this.roleOptions = response.map((role) => ({ roleName: role.roleName, id: role.id }))
        loading.close()
      } catch (error) {
        this.$message.error('角色列表请求失败 ' + error.message)
        loading.close()
      }
    },
    addUser() {
      console.log('Add user')
    },
    editUser(user) {
      const department = this.departmentList.find((dept) => dept.id === user.deptId)
      this.currentUser = { 
        ...user, 
        roles: user.roles.map((role) => role.id),
        deptId: department ? department.id : null,
      }
      this.avatarFileList = user.avatar ? [{ url: user.avatar }] : []
      this.signatureFileList = user.signImage ? [{ url: user.signImage }] : []
      this.openDialog('edit')
    },
    // 递归遍历树形结构，构建 selectedKeys
    buildSelectedKeys(treeData, dataPermission) {
      const selectedKeys = []
      treeData.forEach(node => {
        // 根据节点的 type 属性判断类型
        if (node.type === 'device') {
          // 设备节点
          if (dataPermission.devices?.includes(node.id)) {
            selectedKeys.push(node.id)
          }
        } else if (node.children) {
          // 医院和科室节点
          if (node.children[0]?.type === 'device') {
            // 科室节点
            if (dataPermission.departments?.includes(node.id)) {
              selectedKeys.push(node.id)
            }
          } else {
            // 医院节点
            if (dataPermission.hospitals?.includes(node.hospitalCode)) {
              selectedKeys.push(node.hospitalCode)
            }
          }
        }

        // 递归处理子节点
        if (node.children && node.children.length > 0) {
          selectedKeys.push(...this.buildSelectedKeys(node.children, dataPermission))
        }
      })
      return selectedKeys
    },
    configureUser(user) {
      const authStore = useAuthStore()
      const patientFields = authStore.settings.patientFieldSettings || []

      let dataPermission = {}
      try {
        dataPermission = JSON.parse(user.dataPermission || '{}')
      } catch (e) {
        console.error('解析数据权限失败:', e)
        dataPermission = {}
      }

      this.currentUser = user

      const existingFields = dataPermission.editColumns || []
      
      this.selectedFields = patientFields
        .filter(field => field.column !== 'id')
        .filter(field => {
          if(field.column === 'analyzeDate') {
            return existingFields.includes(field.column)
          }
          return existingFields.includes(`patient.${field.column}`)
        })
        .map(field => field.column)

      this.currentPermissions = {
        hospitals: dataPermission.hospitals || [],
        departments: dataPermission.departments || [],
        devices: dataPermission.devices || [],
        fields: existingFields
      }

      console.log('dataPermission:', dataPermission)
      this.isAllChecked = this.selectedFields.length === this.displayFields.length
      this.fetchHospitalList().then(() => {
        this.$nextTick(() => {
          const treeRef = this.$refs.departmentTree
          if (treeRef) {
            // 先清空所有选中状态
            treeRef.setCheckedKeys([])
            
            // 输出调试信息
            console.log('Department Tree:', this.departmentTree)
            console.log('Data Permission:', dataPermission)
            
            // 使用递归函数构建 selectedKeys
            const selectedKeys = this.buildSelectedKeys(this.departmentTree, dataPermission)
            
            // 输出调试信息
            console.log('Selected Keys:', selectedKeys)
            
            // 设置选中状态
            if (selectedKeys.length > 0) {
              treeRef.setCheckedKeys(selectedKeys)
            }
          }
        })
      })

      this.showPermissionsDialog = true
    },
    deleteUser(user) {
      // 删除用户处理逻辑
    },
    handlePageChange(page) {
      this.currentPage = page
      if (!this.searchQuery || this.searchQuery.trim() === '') {
        this.fetchUsers()
      }
    },
    openDialog(type, user = null) {
      if (type === 'edit') {
        this.dialogTitle = '编辑用户'
        this.isEdit = true
      } else {
        this.dialogTitle = '添加用户'
        this.currentUser = {
          roles: [],
          deptId: null
        }
        this.avatarFileList = []
        this.signatureFileList = []
        this.isEdit = false
      }
      this.showDialog = true
    },
    async saveUser() {
      if (
        !this.currentUser.code ||
        !this.currentUser.account ||
        (!this.currentUser.password && !this.isEdit) ||
        !this.currentUser.roles.length
      ) {
        this.$message.error('工号、账号、密码和角色为必填项')
        return
      }
      try {
        const loading = this.$loading({
          lock: true,
          text: '保存中...',
          background: 'rgba(255, 255, 255, 0.1)'
        })
        const userData = {
          code: this.currentUser.code,
          name: this.currentUser.name,
          account: this.currentUser.account,
          birthdayDate: this.currentUser.birthdayDate ? dayjs(this.currentUser.birthdayDate).format('YYYY-MM-DD') : null,
          status: 1,
          deptId: this.currentUser.deptId,
          sex: this.currentUser.sex,
          roles: this.currentUser.roles.map((roleId) => {
            const role = this.roleOptions.find((r) => r.value === roleId)
            return {
              id: roleId,
              roleName: role ? role.roleName : ''
            }
          }),
          accountExpireDate: this.currentUser.accountExpireDate
            ? dayjs(this.currentUser.accountExpireDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
            : null,
          password: this.currentUser.password,
          avatar: this.currentUser.avatar,
          signImage: this.currentUser.signImage
        }
        const publicKey = '049b1992cb81ce0f6776b1a91a253c34405851199b36e6e99c2b39a0d76140b183635519ad2f171bc9dcd33042c62a91c795efff219d04ab1ecfd7920a8b2a0afe'
        if (userData.password) {
          userData.password = encryptPassword(userData.password, publicKey)
        }
        const authStore = useAuthStore()
        let response
        if (this.currentUser.id) {
          response = await authStore.updateUser(this.currentUser.id, userData)
        } else {
          response = await authStore.addUser(userData)
        }
        if (response.msg === 'success') {
          this.$message.success('用户保存成功')
          this.showDialog = false
          this.fetchUsers()
        }
        loading.close()
      } catch (error) {
        this.$message.error('用户保存失败: ' + error.message)
        loading.close()
      }
    },
    async savePermissions() {
      const loading = this.$loading({
          lock: true,
          text: '保存中...',
          background: 'rgba(255, 255, 255, 0.1)'
        })
      try {
        const treeRef = this.$refs.departmentTree
        if (!treeRef) return
        const checkedNodes = treeRef.getCheckedNodes()
        const halfCheckedNodes = treeRef.getHalfCheckedNodes()
        const hospitals = []
        const departments = []
        const devices = []
        checkedNodes.forEach((node) => {
          if (node.type === 'device') {
            devices.push(node.id)
          } else if (node.children && node.children.length > 0) {
            if (node.children[0].children) {
              hospitals.push(node.id)
            } else {
              departments.push(node.id)
            }
          }
        })
        halfCheckedNodes.forEach((node) => {
          if (node.children && node.children.length > 0) {
            if (node.children[0].children) {
              hospitals.push(node.id)
            } else {
              departments.push(node.id)
            }
          }
        })
        const permissions = {
          hospitals: [...new Set(hospitals)],
          departments: [...new Set(departments)],
          devices: [...new Set(devices)],
          editColumns: this.currentPermissions.fields,
          defaultDevicePath: ''
        }
        //需要把用户的其他所有数据都保存上,额外附加permissions
        const userData = {
          ...this.currentUser,
          dataPermission: JSON.stringify(permissions)
        }
        console.log('userData:', userData)
        // loading.close()
        //return
        const authStore = useAuthStore()
        await authStore.updateUserPermissions(this.currentUser.id, userData)
        this.$message.success('权限保存成功')
        //刷新用户列表
        this.fetchUsers()
        authStore.userData = await authStore.getCurrentUser()
        this.showPermissionsDialog = false
      } catch (error) {
        this.$message.error('权限保存失败: ' + error.message)
      }finally{
        loading.close()
      }
    },
    toggleAllFields(checked) {
      if (checked) {
        this.selectedFields = this.displayFields.map(field => field.column)
      } else {
        this.selectedFields = []
      }
      
      this.handleFieldsChange(this.selectedFields)
    },
    handleFieldsChange(selectedColumns) {
      this.currentPermissions.fields = selectedColumns.map(column => {
        return column === 'analyzeDate' ? column : `patient.${column}`
      })
      
      this.isAllChecked = selectedColumns.length === this.displayFields.length
    },
    handleAvatarSuccess(file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.currentUser.avatar = e.target.result
      }
      reader.readAsDataURL(file.raw)
    },
    handleSignatureSuccess(file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.currentUser.signImage = e.target.result
      }
      reader.readAsDataURL(file.raw)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handleSizeChange(size) {
      this.pageSize = size
      this.currentPage = 1
      if (!this.searchQuery || this.searchQuery.trim() === '') {
        this.fetchUsers()
      }
    },
  },

  mounted() {
    this.fetchHospitalList()
    this.fetchUsers()
    this.fetchRoles()
  },

  watch: {
    searchQuery: {
      handler(newVal) {
        if (this.searchDebounceTimer) {
          clearTimeout(this.searchDebounceTimer)
        }

        // 使用防抖处理所有情况
        this.searchDebounceTimer = setTimeout(() => {
          this.currentPage = 1
          // 如果是清空搜索，直接使用本地数据
          if (!newVal || newVal.trim() === '') {
            this.total = this.tableData.length
            return
          }
          this.fetchUsers()
        }, 300)
      }
    }
  },
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.user-management-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #474747;
  min-height: 100vh;
  font-size: 16px;
  line-height: 20px;
}

.user-management-box {
  width: calc(100% - 20px);
  height: calc(100vh - 40px);
  background-color: #f0f3f5;
  border-radius: 12px;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  position: relative;
  margin: 20px 20px 20px 0px;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  color: #057ab2;
}

.header h1 {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}

.actions {
  display: flex;
  align-items: center;
}

.search-input {
  margin-right: 10px;
}

.custom-button1 {
  background-color: white;
  color: black;
  border-color: #f0f3f5;
}

.custom-button {
  background-color: #057ab2;
  color: white;
  border: none;
}

.custom-button:hover {
  background-color: #045a8d;
  border: none;
}

.name-column {
  display: flex;
  align-items: center;
}

.user-avatar {
  margin-right: 8px;
  width: 30px;
  height: 30px;
}

.custom-table {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 52px;
  overflow: auto;
  border-radius: 12px;
}

.action-icon {
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: center;
}

.title {
  color: #057ab2;
  font-size: 16px;
  font-weight: 500;
  margin-right: auto;
}

.status-published {
  color: #56b22b;
}

.status-abord {
  color: red;
}

.dialog-footer {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.action-buttons .action-button {
  border: none;
  color: #057ab2;
  width: 68px;
}

:deep(.avatar-container .el-upload--picture-card),
:deep(.avatar-container .el-upload-list__item),
:deep(.avatar-container .el-upload-list__item-actions) {
  width: 48px;
  height: 48px;
}

:deep(.signature-container .el-upload--picture-card, .el-upload-list__item.el-upload-list__item-actions) {
  height: 20px;
}

:deep(.signature-container .el-upload-list--picture .el-upload-list__item-thumbnail) {
  height: 20px;
}

.action-button:hover {
  background-color: rgba(5, 122, 178, 0.25);
  color: #057ab2;
}

:deep(.el-table--enable-row-hover .el-table__body tr:hover > td) {
  background-color: rgba(0, 0, 0, 0) !important;
}

.pagination-container {
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-info {
  text-align: center;
  margin-left: 10px;
}

.table-container {
  flex: 1;
  overflow: auto;
  position: relative;
}

.permissions-container {
  display: flex;
  border-radius: 8px;
}

.permissions-column {
  background-color: #f0f3f5;
  border: #f0f3f5 1px solid;
  border-radius: 8px;
  flex: 1;
  padding: 0px 10px 10px 10px;
  min-height: 300px;
}

.permissions-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.full-width-line {
  width: 100%;
  border: none;
  border-bottom: 1px solid #dcdcdc;
  margin: 10px 0;
}

.footer-button {
  width: 88px;
  height: 32px;
}

:deep(.el-dialog__title) {
  font-size: 20px;
  font-weight: 700;
}

.required-star {
  color: red;
}
</style>

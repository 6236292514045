<template>
  <div class="login-page">
    <div class="welcome-section">
      <span style="color: #057ab2; font-size: 36px; font-weight: 700">Welcome</span>
      <span style="font-size: 70px; font-weight: 700; color: #45494c">Radiometer POCT管理系统</span>
      <p style="font-size: 32px; font-weight: 400; color: #5e6266">{{ descriptionText }}</p>
      <el-button type="primary" class="help-button">
        获取帮助
        <el-icon style="margin-left: 30px"><arrow-right /></el-icon>
      </el-button>
    </div>
    <div class="login-section">
      <div class="login-card">
        <!-- <img src="@/assets/logo.png" alt="AQURE" class="logo" /> -->
        <p style="font-size: 70px; font-weight: 800; color: #057ab2; margin-top: 3px; margin-bottom: 18px">POCT-IS</p>

        <el-tabs v-model="activeTab">
          <el-tab-pane label="账号登录" name="account">
            <el-form :model="loginForm" class="login-form" @submit.native.prevent="handleLogin" ref="loginFormRef">
              <el-form-item>
                <el-input
                  v-model="loginForm.username"
                  placeholder="用户名"
                  @input="removeInvalidCharacters('username', /^[a-zA-Z0-9]*$/)"
                ></el-input>
              </el-form-item>
              <el-form-item :error="formError">
                <el-input
                  show-password
                  v-model="loginForm.password"
                  type="password"
                  placeholder="密码"
                  @input="removeInvalidCharacters('password', /^[a-zA-Z0-9!@#$%^&*]*$/)"
                >
                </el-input>
              </el-form-item>
              <!-- <el-form-item>
                <a href="#" class="forgot-password">忘记密码?</a>
              </el-form-item> -->
              <el-form-item>
                <el-button type="primary" @click="handleLogin" class="login-button">登录</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="CA登录" name="ca">
            <el-form
              :model="caLoginForm"
              class="login-form"
              @submit.native.prevent="handleCALogin"
              ref="caLoginFormRef"
            >
              <el-form-item>
                <el-select v-model="caLoginForm.selectedCertificates" placeholder="选择证书">
                  <el-option v-for="item in certificates" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="handleCALogin" class="login-button">登录</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="扫码登录" name="qr">
            <div class="login-form">
              <div class="qr-container">
                <img :src="'data:image/png;base64,' + qrCodeData" alt="QR Code" class="qr-code" v-if="qrCodeData" />
                <div v-if="isQRCodeExpired" class="qr-overlay">
                  <el-button type="primary" @click="refreshQRCode" class="refresh-button">刷新二维码</el-button>
                </div>
                <div v-if="isLoginSuccess" class="qr-overlay success-overlay">
                  <el-icon class="success-icon"><circle-check /></el-icon>
                  <span class="success-text">登录成功</span>
                </div>
              </div>
              <p class="qr-tip" :class="{ 'error-tip': errorMessage }">
                {{ errorMessage || (isLoginSuccess ? '登录成功，正在跳转...' : `扫描上方二维码 `)}}<span v-if="!errorMessage && !isLoginSuccess" class="countdown">{{ countdown }}s</span>
              </p>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div class="footer">
          <p>雷度米特医疗设备（上海）有限公司</p>
          <!-- <div class="separator"></div>
          <p>地址：上海市长宁区福泉北路518号1座3楼</p>
          <p>电话：021-60158555</p>
          <p>传真：021-60158578</p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted, onBeforeUnmount, nextTick, watch } from 'vue'
import { ArrowRight, View, CircleCheck } from '@element-plus/icons-vue'
import { useAuthStore } from '@/stores/authStore'
import { useRouter } from 'vue-router'
import { sm2 } from 'sm-crypto'

export default {
  name: 'Login',
  components: {
    ArrowRight,
    View,
    CircleCheck
  },
  setup() {
    const authStore = useAuthStore()
    const router = useRouter()
    const activeTab = ref('account')

    const loginForm = reactive({
      username: '',
      password: ''
    })

    // 动态文字内容
    const descriptionText = ref('选择Radiometer POCT数字化管理软件，实现POCT设备的全流程、全设备的集中闭环管理。')

    const caLoginForm = reactive({
      selectedCertificates: 'certificate1'
    })

    const certificates = [
      { value: 'certificate1', label: '证书1' },
      { value: 'certificate2', label: '证书2' },
      { value: 'certificate3', label: '证书3' }
    ]

    const loginFormRef = ref()
    const caLoginFormRef = ref()

    const formError = ref('')
    const passwordVisible = ref(false)

    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        handleLogin()
      }
    }

    onMounted(() => {
      caLoginForm.selectedCertificates = [certificates[0].value]; // 默认选择第一个选项
      
      // 检查URL中是否包含SSO参数
      const currentUrl = window.location.href;
      console.log('当前URL:', currentUrl);  // 添加日志
      
      // 尝试解析完整URL
      try {
        const urlObj = new URL(currentUrl);
        const emplCode = urlObj.searchParams.get('emplCode');
        const secretKey = urlObj.searchParams.get('secretKey');
        
        console.log('获取到的参数:', { emplCode, secretKey });  // 添加日志
        
        if (emplCode && secretKey) {
          console.log('开始SSO登录');  // 添加日志
          handleSSOLogin(emplCode, secretKey);
        }
      } catch (error) {
        console.error('解析URL参数失败:', error);  // 添加错误日志
      }
    });

    const handleSSOLogin = async (emplCode, secretKey) => {
      try {
        const success = await authStore.ssoLogin({
          emplCode,
          secretKey
        });
        
        if (success.code === 200) {
          router.push({ path: '/' }); // 重定向到首页
        } else {
          console.error('SSO登录失败:', success.msg);
        }
      } catch (error) {
        console.error('SSO登录失败:', error.message);
      }
    };

    onBeforeUnmount(() => {
      //document.removeEventListener('keydown', handleKeyPress);
    })

    const validateUsername = (username) => {
      const usernamePattern = /^[a-zA-Z0-9]+$/
      return usernamePattern.test(username)
    }

    const validatePassword = (password) => {
      const passwordPattern = /^[a-zA-Z0-9!@#$%^&*]+$/
      return passwordPattern.test(password)
    }

    const handleLogin = async () => {
      formError.value = ''
      console.log('开始登录')
      if (!loginForm.username) {
        formError.value = '用户名不能为空'
        console.log('用户名不能为空')
        return
      }
      if (!validateUsername(loginForm.username)) {
        formError.value = '用户名只能包含字母和数字'
        console.log('用户名只能包含字母和数字')
        return
      }
      if (!loginForm.password) {
        formError.value = '密码不能为空'
        console.log('密码不能为空')
        return
      }
      if (!validatePassword(loginForm.password)) {
        formError.value = '密码只能包含字母、数字和特殊符号'
        console.log('密码只能包含字母、数字和特殊符号')
        return
      }

      // 使用 SM2 加密密码
      const publicKey =
        '049b1992cb81ce0f6776b1a91a253c34405851199b36e6e99c2b39a0d76140b183635519ad2f171bc9dcd33042c62a91c795efff219d04ab1ecfd7920a8b2a0afe' // 替换为实际的公钥
      const encryptedPassword = sm2.doEncrypt(loginForm.password, publicKey)
      console.log('密码加密完成')

      try {
        const success = await authStore.login({
          username: loginForm.username,
          password: encryptedPassword
        })
        console.log('登录请求返回', success)
        if (success.code == 200) {
          // 登录成功时停止二维码相关的所有操作
          stopPolling();
          clearTimeout(qrCodeTimer);
          router.push({ path: '/' }) // 重定向到首页
        } else {
          formError.value = success.msg
          console.log('登录失败', success.msg)
        }
      } catch (error) {
        formError.value = '登录请求失败: ' + error.message
        console.log('登录请求失败', error.message)
      }
    }

    const handleCALogin = async () => {
      try {
        // Add CA login logic here
        console.log('CA Login')
      } catch (error) {
        console.error('CA 登录请求失败', error.message)
        alert('CA 登录请求失败: ' + error.message)
      }
    }

    const handleQRLogin = () => {
      console.log('扫码登录')
      // 在这里添加扫码登录的逻辑
    }

    const togglePasswordVisibility = () => {
      passwordVisible.value = !passwordVisible.value
    }

    const removeInvalidCharacters = (field, pattern) => {
      loginForm[field] = loginForm[field].replace(new RegExp(`[^${pattern.source}]`, 'g'), '')
    }

    const qrCodeData = ref(null);
    const requestId = ref(null);
    const errorMessage = ref('');
    const isQRCodeExpired = ref(false);
    const isLoginSuccess = ref(false);
    const countdown = ref(120);
    let qrCodeTimer = null;
    let pollTimer = null;
    let countdownTimer = null;  // 添加倒计时定时器

    const startQRCodeTimer = () => {
      clearTimeout(qrCodeTimer);
      clearInterval(countdownTimer);  // 清除之前的倒计时
      isQRCodeExpired.value = false;
      countdown.value = 120;  // 重置倒计时
      
      // 启动倒计时
      countdownTimer = setInterval(() => {
        countdown.value--;
        if (countdown.value <= 0) {
          clearInterval(countdownTimer);
          isQRCodeExpired.value = true;
          errorMessage.value = '二维码已过期，请点击刷新';
          stopPolling();
        }
      }, 1000);

      qrCodeTimer = setTimeout(() => {
        isQRCodeExpired.value = true;
        errorMessage.value = '二维码已过期，请点击刷新';
        stopPolling();
        clearInterval(countdownTimer);  // 清除倒计时
      }, 120000);
    };

    const stopPolling = () => {
      if (pollTimer) {
        clearTimeout(pollTimer);
        pollTimer = null;
      }
      if (countdownTimer) {  // 停止倒计时
        clearInterval(countdownTimer);
        countdownTimer = null;
      }
    };

    const pollQRCode = async () => {
      // 先检查倒计时，如果已经到0或更小，立即停止
      if (countdown.value <= 0 || isQRCodeExpired.value) {
        stopPolling();
        return;
      }

      try {
        const result = await authStore.pollQRCodeStatus(requestId.value, countdown.value <= 0 || isQRCodeExpired.value);
        if (result && result.data && result.data.token) {
          stopPolling();
          clearTimeout(qrCodeTimer);
          console.log('登录成功', result);
          isLoginSuccess.value = true;
          setTimeout(() => {
            router.push({ path: '/' });
          }, 1000);
          return;
        }

        // 再次检查倒计时，如果在请求过程中倒计时已结束，就不再继续
        if (countdown.value > 0 && !isQRCodeExpired.value) {
          pollTimer = setTimeout(() => {
            pollQRCode();
          }, 1000);
        }
      } catch (error) {
        if (countdown.value <= 0 || isQRCodeExpired.value) {
          stopPolling();
          return;
        }

        if (error.message === '二维码超时') {
          stopPolling();
          isQRCodeExpired.value = true;
          errorMessage.value = '二维码已过期，请点击刷新';
          return;
        }

        if (!isQRCodeExpired.value && countdown.value > 0) {
          errorMessage.value = error.message;
          pollTimer = setTimeout(() => {
            pollQRCode();
          }, 1000);
        }
      }
    };

    const fetchQRCode = async () => {
      try {
        stopPolling();  // 获取新二维码前停止之前的轮询
        clearTimeout(qrCodeTimer); // 清除之前的过期计时器
        errorMessage.value = '';
        isQRCodeExpired.value = false;
        const data = await authStore.getQRCode();
        qrCodeData.value = data.data.imgBase64;
        requestId.value = data.data.requestId;
        startQRCodeTimer();
        // 确保倒计时启动后再开始轮询
        nextTick(() => {
          pollQRCode();
        });
      } catch (error) {
        errorMessage.value = '获取二维码失败: ' + error.message;
      }
    };

    const refreshQRCode = () => {
      fetchQRCode();
    };

    onBeforeUnmount(() => {
      clearTimeout(qrCodeTimer);
      clearInterval(countdownTimer);  // 清除倒计时
      stopPolling();
    });

    // 监听tab切换
    const handleTabChange = (tab) => {
      if (tab === 'qr' && (!qrCodeData.value || isQRCodeExpired.value)) {
        fetchQRCode();
      }
    };

    watch(activeTab, (newTab) => {
      handleTabChange(newTab);
    });

    return {
      activeTab,
      loginForm,
      caLoginForm,
      certificates,
      loginFormRef,
      caLoginFormRef,
      handleLogin,
      handleCALogin,
      handleQRLogin,
      formError,
      passwordVisible,
      togglePasswordVisibility,
      removeInvalidCharacters,
      descriptionText,
      qrCodeData,
      errorMessage,
      isQRCodeExpired,
      refreshQRCode,
      stopPolling,
      qrCodeTimer,
      countdown,  // 添加到返回值中
      isLoginSuccess
    }
  }
}
</script>

<style scoped>
.login-page {
  display: flex;
  height: 100vh;
  background: url('@/assets/background.png') no-repeat center center;
  background-size: cover;
  position: relative;
}

.welcome-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-left: 150px;
  color: #333;
  /* 根据提供的图更改颜色 */
}

.welcome-section h2 {
  color: #007bff;
  /* 根据提供的图更改颜色 */
  font-size: 36px;
}

.welcome-section h1 {
  font-size: 60px;
  margin: 20px 0;
  color: #333;
  /* 根据提供的图更改颜色 */
}

.welcome-section p {
  font-size: 32px;
  margin: 32px 0;
  color: #666;
  /* 根据提供的图更改颜色 */
  max-width: 900px;
  /* 设置最大宽度 */
  word-wrap: break-word;
  /* 自动换行 */
}

.help-button {
  height: 60px;
  width: 180px;
  font-size: 24px;
  line-height: 40px;
  background-color: #057ab2;
  margin-top: 40px;
}

.login-section {
  position: absolute;
  top: 50%;
  right: 92px;
  /* 距右 92px */
  transform: translateY(-50%);
  width: 400px;
  height: 800px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-card {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 60px;
  /* 顶部距离 */
}

.logo {
  width: 320px;
  /* Logo 宽度调整为 271px */
  height: 80px;
  margin-top: 30px;
  margin-bottom: 18px;
  /* 底部距离 48px */
}

.login-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form .el-form-item {
  width: 320px;
}

.login-form .el-input,
.login-form .el-select {
  width: 320px;
  height: 48px;
  line-height: 48px;
}

.el-input .el-input__inner,
.el-select .el-input__inner {
  height: 48px;
  line-height: 48px;
}

.forgot-password {
  position: absolute;
  text-decoration: none;
  right: 0;
  color: #409eff;
}

.login-button {
  width: 320px;
  height: 48px;
  background-color: #057ab2;
}

.footer {
  position: absolute;
  bottom: 24px;
  text-align: center;
  font-size: 14px;
  color: #5e6266;
  font-weight: 400;
  width: 100%;
}

.footer .separator {
  width: 268px;
  height: 1px;
  background-color: #ddd;
  margin: 8px auto;
}

.footer p {
  margin: 0;
}

.footer p:first-child {
  font-size: 14px;
  font-weight: bold;
  color: black;
  line-height: 24px;
}

.footer p:not(:first-child) {
  font-size: 12px;
  color: #999;
  line-height: 20px;
}

.error-message {
  color: red;
  font-size: 14px;
  text-align: left;
  margin-top: -10px;
  margin-bottom: 10px;
}

:deep(.el-tabs__item) {
  color: #8694a2;
  /** tab颜色更改*/
  font-weight: 500;
}

:deep(.el-tabs__item.is-active) {
  color: #057ab2;
  /** tab颜色更改*/
  font-weight: 500;
}

:deep(.el-button--primary) {
  background-color: #057ab2;
  border-color: #057ab2;
}

:deep(.el-button--primary:hover) {
  background-color: #045a8d;
  border-color: #045a8d;
}

/**扫码部分样式 */
.el-tabs__header {
  display: flex;
  justify-content: flex-start; /* Tab标签栏居左对齐 */
  border-bottom: 1px solid #e8e8e8;
}

.el-tabs__nav-wrap {
  flex: 1;
}

.el-tabs__nav {
  justify-content: flex-start;
  display: flex;
}

.qr-container {
  width: 320px;  /* 与输入框宽度一致 */
  height: 200px;
  position: relative;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;  /* 二维码在容器中居中 */
}

.qr-code {
  width: 200px;  /* 二维码本身保持200px */
  height: 200px;
}

.qr-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr-tip {
  font-size: 14px;
  color: #606266;
  margin: 10px 0;
}

.error-tip {
  color: #f56c6c;
}

.refresh-button {
  width: 120px;
  height: 36px;
}

.success-overlay {
  background: rgba(255, 255, 255, 0.95);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.success-icon {
  font-size: 40px;
  color: #67c23a;
  margin-bottom: 10px;
}

.success-text {
  font-size: 18px;
  color: #67c23a;
  font-weight: 500;
}

:deep(.el-tabs__content) {
  width: 100%;
}

:deep(.el-tab-pane) {
  width: 100%;
}

.countdown {
  color: #057AB2;
  font-weight: 500;
}
</style>

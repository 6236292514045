import axios from 'axios'
import { useAuthStore } from '@/stores/authStore'

// 动态获取配置
async function getConfig() {
  try {
    const response = await fetch('/config.json')
    return await response.json()
  } catch (error) {
    console.error('无法加载配置文件:', error)
    return { baseURL: '' }
  }
}

let apiClient = null

async function createApiClient() {
  const config = await getConfig()
  
  apiClient = axios.create({
    baseURL: config.baseURL,
    headers: {
      'Content-Type': 'application/json'
    },
    timeout: 1000000
  })

  // 请求拦截器
  apiClient.interceptors.request.use(
    (config) => {
      const authStore = useAuthStore()
      // 如果请求不是登录请求，则添加 token
      if (!config.url.includes('/auth/login')) {
        if (authStore.getToken) {
          config.headers.Authorization = `${authStore.getToken}`
        }
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  return apiClient
}

// 初始化 apiClient
(async () => {
  try {
    apiClient = await createApiClient()
  } catch (error) {
    console.error('Failed to initialize apiClient:', error)
  }
})()

// 导出获取 apiClient 的函数
export async function getApiClient() {
  if (!apiClient) {
    await createApiClient()
  }
  return apiClient
}

export default {
  async get(...args) {
    const client = await getApiClient()
    return client.get(...args)
  },
  async post(...args) {
    const client = await getApiClient()
    return client.post(...args)
  },
  async put(...args) {
    const client = await getApiClient()
    return client.put(...args)
  },
  async delete(...args) {
    const client = await getApiClient()
    return client.delete(...args)
  }
}

<template>
  <div class="department-management-container">
    <div class="department-management-box">
      <div class="header">
        <span class="title">科室管理</span>
        <el-input v-model="search" placeholder="请输入内容" class="search-input" clearable>
          <template #prefix>
            <el-icon>
              <Search />
            </el-icon>
          </template>
        </el-input>
        <el-button type="primary" @click="syncDepartment" class="sync-button">同步科室</el-button>
        <el-button type="primary" @click="openCreateModal" class="create-button">创建科室</el-button>
      </div>
      <div class="table-container">
        <el-scrollbar style="height: 98%">
          <el-table :data="departments" style="width: 100%" height="100%" :header-cell-style="{ background: '#F5F6F7' }"
            class="custom-table">
            <el-table-column prop="id" label="科室ID" align="left" width="auto" min-width="30%"
              header-align="left"></el-table-column>
            <el-table-column prop="name" label="科室名称" align="left" width="auto" min-width="15%"
              header-align="left"></el-table-column>
            <el-table-column prop="code" label="科室代码" align="left" width="auto" min-width="15%"
              header-align="left"></el-table-column>
            <el-table-column prop="area" label="所属院区" align="left" width="auto" min-width="20%"
              header-align="left"></el-table-column>
            <el-table-column prop="updateTime" label="更新时间" align="left" width="auto" min-width="20%"
              header-align="left" :formatter="formatDate"></el-table-column>:
            <el-table-column label="操作" align="center" width="auto" min-width="20%" header-align="center">
              <template v-slot="scope">
                <el-button @click="openEditModal(scope.row)" type="button" class="action1">
                  <el-icon size="16">
                    <Edit />
                  </el-icon>
                  <span>编辑</span>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
      </div>
      <!-- <div class="pagination-container">
        <el-pagination background layout="prev, pager, next" :total="filteredDepartments.length" :page-size="pageSize"
          @current-change="handlePageChange" class="pagination" />
        <div class="pagination-info">
          共 {{ filteredDepartments.length }} 条
        </div>
      </div> -->
    </div>

    <!-- Modal -->
    <el-dialog v-model="isModalVisible" width="360px" height="244px" :before-close="closeModal"
      style="border-radius: 8px" class="custom-dialog">
      <template #title>
        <span>{{ isEdit ? '编辑科室' : '创建科室' }}</span>
      </template>
      <el-form :model="form">
        <el-form-item label="科室名称">
          <el-input v-model="form.name" placeholder="请输入科室名称"></el-input>
        </el-form-item>
        <el-form-item label="科室代码">
          <el-input v-model="form.code" placeholder="请输入科室代码"></el-input>
        </el-form-item>
        <el-form-item label="所属医院">
          <el-select v-model="form.orgCode" placeholder="请选择医院">
            <el-option v-for="hospital in hospitals" :key="hospital.hospitalCode" :label="hospital.hospitalName"
              :value="hospital.hospitalCode">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeModal">关闭</el-button>
        <el-button type="primary" class="ctrl" @click="handleSave">{{ isEdit ? '保存' : '创建' }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Search, Edit } from '@element-plus/icons-vue'
import { useAuthStore } from '@/stores/authStore'
import dayjs from 'dayjs'
import { ElLoading } from 'element-plus'

export default {
  components: {
    Search,
    Edit
  },
  data() {
    return {
      search: '',
      departments: [],
      currentPage: 1,
      pageSize: 10,
      isModalVisible: false,
      isEdit: false,
      form: {
        id: '',
        name: '',
        code: '',
        area: '',
        orgCode: ''
      },
      currentDepartment: null,
      hospitals: []
    }
  },
  computed: {
    filteredDepartments() {
      if (!this.search) return this.departments
      return this.departments.filter((department) =>
        Object.values(department).some((value) => value.includes(this.search))
      )
    },
    paginatedDepartments() {
      const start = (this.currentPage - 1) * this.pageSize
      const end = this.currentPage * this.pageSize
      return this.filteredDepartments.slice(start, end)
    }
  },
  methods: {
    formatDate(row) {
      if (!row.updateTime) return ''
      return dayjs(row.updateTime).format('YYYY-MM-DD HH:mm:ss')
    },
    async syncDepartment() {
      const loading = ElLoading.service({
        fullscreen: true,
        lock: true,
        text: '加载中...',
        background: 'rgba(255, 255, 255, 0.1)'
      })
      const authStore = useAuthStore()
      try {
        const syncResponse = await authStore.syncHospitalDepartments()
        console.log(syncResponse)
        if (syncResponse.code === 200) {
          console.log('同步科室成功')
          this.$message.success('同步科室成功')
          this.fetchDepartments()
        } else {
          console.error('同步科室失败', syncResponse.message)
          this.$message.error('同步科室失败')
        }
      } catch (error) {
        console.error('同步科室失败', error)
      } finally {
        loading.close()
      }
    },
    async fetchDepartments() {
      const loading = ElLoading.service({
        fullscreen: true,
        lock: true,
        text: '加载中...',
        background: 'rgba(255, 255, 255, 0.1)'
      })
      const authStore = useAuthStore()
      try {
        const response = await authStore.getHospitalDepartments()
        if (response.code === 200) {
          this.departments = response.data
          this.departments.forEach((department) => {
            const hospital = this.hospitals.find((h) => h.hospitalCode === department.orgCode)
            if (hospital) {
              department.area = hospital.hospitalName
            }
          })
        } else {
          console.error('获取科室列表失败', response.message)
        }
      } catch (error) {
        console.error('获取科室列表失败', error)
      } finally {
        loading.close()
      }
    },
    async fetchHospitals() {
      const loading = ElLoading.service({
        fullscreen: true,
        lock: true,
        text: '加载中...',
        background: 'rgba(255, 255, 255, 0.1)'
      })
      const authStore = useAuthStore()
      try {
        const response = await authStore.hospitallist()
        if (response.code === 200) {
          this.hospitals = response.data
        } else {
          console.error('获取医院列表失败', response.message)
        }
      } catch (error) {
        console.error('获取医院列表失败', error)
      } finally {
        loading.close()
      }
    },
    openCreateModal() {
      this.isEdit = false
      const defaultOrgCode = this.hospitals.length > 0 ? this.hospitals[0].hospitalCode : ''

      this.form = {
        id: '',
        name: '',
        code: '',
        area: '',
        orgCode: defaultOrgCode
      }
      this.isModalVisible = true
    },
    openEditModal(department) {
      this.isEdit = true
      const hospital = this.hospitals.find((h) => h.hospitalCode === department.orgCode)
      this.form = {
        ...department,
        orgCode: hospital ? department.orgCode : ''
      }
      this.currentDepartment = department
      this.isModalVisible = true
    },
    handleSave() {
      const loading = ElLoading.service({
        fullscreen: true,
        lock: true,
        text: '加载中...',
        background: 'rgba(255, 255, 255, 0.1)'
      })
      const authStore = useAuthStore()
      if (this.isEdit) {
        authStore
          .updateDepartment(this.currentDepartment.id, this.form)
          .then((response) => {
            console.log('科室更新成功', response)
            this.$message.success('科室更新成功')
            this.fetchDepartments()
          })
          .catch((error) => {
            this.$message.error('科室更新失败', error.message)
            console.error('科室更新失败', error)
          })
          .finally(() => {
            loading.close()
          })
      } else {
        this.departments.push({ ...this.form, updated: new Date().toISOString().split('T')[0] })
        authStore
          .addDepartment(this.form)
          .then((response) => {
            console.log('创建科室成功', response)
            this.$message.success('创建科室成功')
            this.fetchDepartments()
          })
          .catch((error) => {
            this.$message.error('创建科室失败', error.message)
            console.error('创建科室失败', error)
          })
          .finally(() => {
            loading.close()
          })
      }
      this.isModalVisible = false
    },
    closeModal() {
      this.isModalVisible = false
    },
    handlePageChange(page) {
      this.currentPage = page
    }
  },
  async mounted() {
    await this.fetchHospitals() // 在组件挂载时获取医院列表
    this.fetchDepartments() // 在组件挂载时同步科室
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.department-management-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #474747;
  min-height: 100vh;
  font-size: 16px;
  line-height: 20px;
}

.department-management-box {
  width: calc(100% - 20px);
  height: calc(100vh - 40px);
  background-color: #f0f3f5;
  border-radius: 12px;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  position: relative;
  margin: 20px 20px 20px 0px;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  color: #057ab2;
  font-size: 16px;
  font-weight: 500;
  margin-right: auto;
}

.search-input {
  width: 300px;
  margin-right: 20px;
}

.sync-button,
.create-button {
  margin-left: 20px;
  background-color: #057ab2;
  border-color: #057ab2;
}

.sync-button:hover,
.create-button:hover {
  background-color: #005f8a;
  border-color: #005f8a;
}

.pagination {
  display: flex;
  justify-content: center;
}

.pagination-info {
  text-align: center;
  margin-left: 10px;
}

.custom-table {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 52px;
  overflow: auto;
  border-radius: 12px;
}

.el-table th,
.el-table td {
  text-align: center;
}

.table-container {
  flex: 1;
  overflow: auto;
  position: relative;
}

.pagination-container {
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.dialog-footer {
  text-align: right;
}

.ctrl {
  width: 88px;
  height: 32px;
  background-color: #057ab2;
}

.ctrl:hover {
  background-color: #005f8a;
  border-color: #005f8a;
}

.action1 {
  border: none;
  color: #057ab2;
  background-color: transparent;
}

.action1:hover {
  background-color: rgba(5, 122, 178, 0.25);
  color: #057ab2;
  border: none;
}
</style>

<template>
  <div class="home-page">
    <Sidebar @toggleCollapse="handleToggleCollapse" />
    <div class="content">
      <router-view :is-aside-collapsed="isAsideCollapsed"></router-view> <!-- 显示当前路由匹配的组件 -->
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';
import { useAuthStore } from '@/stores/authStore'; // 认证状态存储
import { useRouter } from 'vue-router'; // 路由器
import { onMounted, ref, nextTick } from 'vue'; // 引入 onMounted 钩子
import axios from 'axios';

export default {
  name: 'Home',
  components: {
    Sidebar
  },
  setup() {
    const authStore = useAuthStore();
    const router = useRouter();
    const isAsideCollapsed = ref(false);
    const settings = ref({});
    const controller = ref(null);

    const fetchSettings = async () => {
      try {
        if (controller.value) {
          controller.value.abort();
        }
        controller.value = new AbortController();
        
        const configList = await authStore.fetchConfigList({
          signal: controller.value.signal
        });
        configList.forEach(config => {
          switch (config.configName) {
            case 'REVIEW_TO_PUBLISH':
              settings.value.publishEnabled = config.configValue === 'on';
              break;
            case 'REVIEWER_DIFF_CHECKER':
              settings.value.sh = config.configValue === 'on';
              break;
            case 'CANCEL_OWNER_REVIEW':
              settings.value.selfsh = config.configValue === 'on';
              break;
            case 'SIGN_WITH_IMAGE':
              settings.value.imgs = config.configValue === 'on';
              break;
            case 'USER_LOGIN_TOKEN_TIMEOUT':
              settings.value.sessionTimeout = parseInt(config.configValue, 10);
              break;
            case 'PATIENT_SHOW_COLUMN':
              settings.value.patientFieldSettings = JSON.parse(config.configValue);
              break;
            case 'REPORT_VIEW_REPORTS_SHOW_COLUMN':
              settings.value.reportFieldsSettings = JSON.parse(config.configValue);
              break;
            case 'REPORT_SEARCH_REPORTS_SHOW_COLUMN':
              settings.value.queryFieldsSettings = JSON.parse(config.configValue);
              break;
            case 'PARAM_SHOW_DOTLEN':
              settings.value.dotLen = config.configValue;
              break;
            default:
              break;
          }
        });
        authStore.updateSettings(settings.value);
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('请求被取消');
          return;
        }
        console.error('Error fetching settings:', error);
      }
    };

    const checkTokenTimeout = async () => {
      const loginTime = sessionStorage.getItem('loginTime');
      if (loginTime && settings.value.sessionTimeout) {
        const currentTime = Date.now();
        const timeElapsed = (currentTime - Number(loginTime)) / 3600000; // 转换为小时
        console.log('token是否超时:', timeElapsed, settings.value.sessionTimeout)
        if (timeElapsed > settings.value.sessionTimeout) {
          logout(); // 如果超时，调用 logout 函数
        }
      }
    };

    const logout = async () => {
      try {
        await authStore.clearToken();
        
        // 检查当前路由是否已经是登录页
        if (router.currentRoute.value.name !== 'Login') {
          await router.push({ 
            name: 'Login',
            replace: true // 使用 replace 而不是 push
          });
        }
      } catch (error) {
        console.error('登出错误:', error);
      }
    };

    const handleToggleCollapse = (isCollapsed) => {
      isAsideCollapsed.value = isCollapsed;
    };

    

    onMounted(async () => {
      // 检查 token 是否存在或是否过期
      if (!authStore.token || authStore.isTokenExpired()) {
        router.push({ name: 'Login' }); // 跳转到登录页
      }
      // 立即执行token超时检查
      checkTokenTimeout();
      await fetchSettings(); 
    });

    return {
      logout,
      isAsideCollapsed,
      handleToggleCollapse,
      settings,
    };
  },
};
</script>

<style scoped>
.home-page {
  display: flex;
  height: 100vh; /* 设置视图高度为100%视窗高度 */
  overflow: hidden;
}

.content {
  flex: 1;
  background-color: #474747; /* 设置内容背景色 */
  overflow: auto;
}
:deep(.el-switch.is-checked .el-switch__core){
  background-color: #057ab2;
  border-color: #057ab2;
}
:deep(.el-pagination.is-background .el-pager li.is-active){
  background-color: #057ab2;
  border-color: #057ab2;
}
:deep(.el-button--primary){
  background-color: #057ab2;
  border-color: #057ab2;
}
:deep(.el-button--primary:hover){
  background-color: #045a8d;
  border-color: #045a8d;
}
:deep(.el-checkbox){
  color: #057ab2;
}
:deep(.el-checkbox__input.is-checked+.el-checkbox__label){
  color: #057ab2;
}
:deep(.el-checkbox__input.is-checked .el-checkbox__inner){
  background-color: #057ab2;
}
:deep(.el-radio__input.is-checked .el-radio__inner){
  background-color: #057ab2;
}
:deep(.el-radio__input.is-checked+.el-radio__label){
  color: #057ab2;
}
:deep(.el-checkbox__input.is-indeterminate .el-checkbox__inner){
  color: #057ab2;
  background-color:#057ab2 ;
}
</style>

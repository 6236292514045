<template>
  <div class="registration-page">
    <div class="header">
      <div class="filter-bar">
        <label for="hospital">医院</label>
        <el-select v-model="hospital" placeholder="选择医院" class="filter-input small-font">
          <el-option v-for="hospital in hospitalList" :key="hospital.hospitalCode" :label="hospital.hospitalName"
            :value="hospital.hospitalCode">
          </el-option>
        </el-select>

        <label for="department">科室</label>
        <el-select v-model="department" placeholder="选择科室" class="filter-input small-font"
          @change="filterDevicesByDepartment">
          <el-option v-for="department in departmentList" :key="department.id" :label="department.name"
            :value="department.id">
          </el-option>
        </el-select>

        <label for="device">设备</label>
        <el-select v-model="device" placeholder="选择设备" class="filter-input small-font">
          <el-option v-for="device in filteredDevices" :key="device.id" :label="device.name" :value="device.id">
          </el-option>
        </el-select>

        <label for="dateStart">检测日期</label>
        <el-date-picker v-model="dateStart" type="date" placeholder="申请日期" class="filter-date small-font" />
        -
        <el-date-picker v-model="dateEnd" type="date" placeholder="截止日期" class="filter-date small-font" />

        <label for="status" v-show="false">筛选状态</label>
        <el-select v-model="status" v-show="false" placeholder="选择状态" class="filter-input small-font">
          <el-option label="待绑定" value="await"></el-option>
          <el-option label="已保存" value="saved"></el-option>
          <el-option label="已审核" value="audited"></el-option>
          <el-option label="已发布" value="publish"></el-option>
          <el-option label="已作废" value="invalid"></el-option>
        </el-select>
      </div>
      <div class="button-group">
        <el-button type="primary" @click="search" class="ctrl-button small-font">查询</el-button>
        <el-button @click="reset" class="ctrl-button small-font">重置</el-button>
      </div>
    </div>
    <el-container class="content1">
      <el-header class="top">检测检查</el-header>
      <el-container>
        <el-aside class="left-pane small-font" width="340px">
          <el-scrollbar style="height: 80vh">
            <el-table :data="samples" height="80%" width="100%" @row-click="handleRowClick" highlight-current-row
              ref="leftTable" class="small-table" :default-sort="{ prop: 'inspectionTime', order: 'descending' }">
              <template v-for="column in sortedColumnKeys">
                <el-table-column v-if="column.field === 'analyzeDate'" :prop="column.field"
                  @current-change="handleCurrentChange" :row-class-name="tableRowClassName" :label="column.label"
                  :width="column.width" :sortable="column.sortable" v-show="column.showable">
                  <template v-slot:default="scope">
                    {{ formatDateTime(scope.row.analyzeDate) }}
                  </template>
                </el-table-column>
                <el-table-column v-if="column.field === 'sampleNum'" :prop="column.field" :label="column.label"
                  :width="column.width" :sortable="column.sortable" v-show="column.showable"></el-table-column>
                <el-table-column v-if="column.field === 'applyStatus'" :prop="column.field" :label="column.label"
                  :width="column.width" :sortable="column.sortable" v-show="column.showable">
                  <template v-slot:default="scope">
                    <span :class="getStatusClass(scope.row.applyStatus)">
                      {{ mapStatus(scope.row.applyStatus) }}
                    </span>
                  </template>
                </el-table-column>
              </template>
            </el-table>
          </el-scrollbar>
          <div class="pagination">
            <el-pagination layout="prev, pager, next, total" size="small" :total="totalRecords" background
              :page-size="pageSize" :pager-count="5" :current-page="currentPage" @current-change="handlePageChange"
              @size-change="handleSizeChange">
            </el-pagination>
          </div>
        </el-aside>
        <el-container class="right-pane">
          <el-header class="form-header" style="padding: 0px" height="auto">
            <template v-if="
              selectedSample.applyStatus &&
              selectedSample.applyStatus !== 'await'
            ">
              <div class="grid-container">
                <!-- 第一列 -->
                <div class="grid-col" style="min-width: 160px">
                  <div class="grid-item">
                    <span style="font-size: 16px">{{ patientDetails.name }}</span> &nbsp;&nbsp;<span
                      style="color: #45494c">{{ patientDetails.bedNum }}</span>
                  </div>
                  <div class="grid-item">
                    <span style="color: #45494c">{{ patientDetails.sex === 'woman' ? '女' : '男' }}</span> /
                    <span style="color: #45494c">{{ formatDate(patientDetails.birthdayDate) }}</span> /
                    <span>{{ patientDetails.age }}岁</span>
                  </div>
                  <div class="grid-item">
                    <label class="label2">患者ID：</label><span>{{ patientDetails.patientId }}</span>
                  </div>
                  <div class="vertical-line"></div>
                </div>

                <!-- 第二列 -->
                <div class="grid-col" style="min-width: 180px">
                  <div class="grid-item">
                    <label class="label2">住院号</label><span>{{ patientDetails.inpatNum }}</span>
                  </div>
                  <div class="grid-item">
                    <label class="label2">条码号</label><span>{{ patientDetails.accessionNum }}</span>
                  </div>
                  <div class="grid-item">
                    <label class="label2">卡号</label><span>{{ patientDetails.cardNum }}</span>
                  </div>
                  <div class="vertical-line"></div>
                </div>

                <!-- 第三列 -->
                <div class="grid-col" style="min-width: 180px">
                  <div class="grid-item">
                    <label class="label3">诊断：</label>
                    <span :title="patientDetails.diagnosis" class="truncate">{{ patientDetails.diagnosis }}</span>
                  </div>
                  <div class="grid-item">
                    <label class="label3">来源：</label>
                    <span>{{ patientDetails.sourceCode === 'inpatient' ? '住院' : '门诊' }}</span>
                  </div>
                  <div class="grid-item">
                    <label class="label3">描述：</label>
                    <span :title="patientDetails.remark" class="truncate">{{ patientDetails.remark }}</span>
                  </div>
                  <div class="vertical-line"></div>
                </div>

                <!-- 第四列 -->
                <div class="grid-col" style="min-width: 180px">
                  <div class="grid-item double-height" style="display: flex; align-items: flex-start">
                    <label class="label3" style="vertical-align: top">地址：</label>
                    <span :title="patientDetails.address" class="truncate-address">{{ patientDetails.address }}</span>
                  </div>
                  <div class="grid-item">
                    <label class="label3">电话：</label>
                    <span>{{ patientDetails.phone }}</span>
                  </div>
                  <div class="vertical-line"></div>
                </div>

                <!-- 第五列 -->
                <div class="grid-col" style="min-width: 180px">
                  <div class="grid-item">
                    <label class="label3">样本类型：</label>
                    <span>{{ patientDetails.sampleType }}</span>
                  </div>
                  <div class="grid-item">
                    <label class="label3">样本说明：</label>
                    <span :title="patientDetails.sampleDesc" class="truncate">{{ patientDetails.sampleDesc }}</span>
                  </div>
                  <div class="vertical-line"></div>
                </div>

                <!-- 第六列 -->
                <div class="grid-col" style="min-width: 180px">
                  <div class="grid-item">
                    <label class="label4">采</label><span>{{ formatDateH(patientDetails.sampleCollectionDate) }}</span>
                  </div>
                  <div class="grid-item">
                    <label class="label4">收</label><span>{{ formatDateH(patientDetails.sampleReceiveDate) }}</span>
                  </div>
                  <div class="grid-item">
                    <label class="label4">测</label><span>{{ formatDateH(patientDetails.analyzeDate) }}</span>
                  </div>
                  <div class="vertical-line"></div>
                </div>

                <!-- 第七列 -->
                <div class="grid-col" style="min-width: 180px">
                  <div class="grid-item">
                    <label class="label3">申请医生：</label><span>{{ patientDetails.applyDoctorName }}</span>
                  </div>
                  <div class="grid-item">
                    <label class="label3">检测人员：</label><span>{{ patientDetails.analyzeDoctorName }}</span>
                  </div>
                </div>
              </div>
            </template>
            <!-- 当状态为  待绑定 -->
            <template v-else>
              <el-row :gutter="10" class="form-row">
                <!-- 根据sort排序并分成4列显示 -->
                <template v-for="colIndex in 4" :key="colIndex">
                  <el-col :span="6" style="min-width: 300px">
                    <!-- 过滤并排序要显示的字段 -->
                    <template v-for="field in getSortedFields(colIndex)" :key="field.column">
                      <!-- 根据isShow控制显示隐藏 -->
                      <div v-if="field.isShow" style="display: flex; align-items: center">
                        <!-- 使用配置中的label -->
                        <label>{{ field.isRequired ? '*' : '' }}{{ field.label }}：</label>

                        <!-- 根据字段column判断使用什么组件 -->
                        <template v-if="field.column === 'sex'">
                          <el-select v-model="patientDetails[field.column]" :placeholder="`请选择${field.label}`"
                            :disabled="!field.isEditable" size="small" class="small-font" style="width: 160px">
                            <el-option label="男" value="man"></el-option>
                            <el-option label="女" value="woman"></el-option>
                          </el-select>
                        </template>

                        <template v-else-if="field.column === 'birthdayDate'">
                          <el-date-picker v-model="patientDetails[field.column]" :placeholder="`请选择${field.label}`"
                            :disabled="!field.isEditable" style="width: 160px" size="small" class="small-font">
                          </el-date-picker>
                        </template>
                        <!-- 来源 -->
                        <template v-else-if="field.column === 'sourceCode'">
                          <el-select v-model="patientDetails[field.column]" :placeholder="`请选择${field.label}`"
                            :disabled="!field.isEditable" size="small" class="small-font" style="width: 160px">
                            <el-option v-for="option in sourceOptions" 
                              :key="option.value" 
                              :label="option.label" 
                              :value="option.value">
                            </el-option>
                          </el-select>
                        </template>
                        <!-- 样本类型 -->
                        <template v-else-if="field.column === 'sampleType'">
                          <el-select v-model="patientDetails[field.column]" :placeholder="`请选择${field.label}`"
                            :disabled="!field.isEditable" size="small" class="small-font" style="width: 160px"
                            filterable allow-create
                            :filter-method="(val) => {
                              if (val && !sampleTypeOptions.some(opt => opt.value === val)) {
                                sampleTypeOptions.push({
                                  value: val,
                                  label: val
                                });
                              }
                              return true;
                            }">
                            <el-option v-for="option in sampleTypeOptions" 
                              :key="option.value" 
                              :label="option.label" 
                              :value="option.value">
                            </el-option>
                          </el-select>
                        </template>
                        <!-- 日期 -->
                        <template v-else-if="
                          ['sampleCollectionDate', 'sampleReceiveDate', 'analyzeDate'].includes(field.column)
                        ">
                          <el-date-picker v-model="patientDetails[field.column]" type="datetime"
                            :placeholder="`请选择${field.label}`" :disabled="!field.isEditable" style="width: 160px"
                            size="small" class="small-font">
                          </el-date-picker>
                        </template>

                        <template v-else-if="['applyDoctorCode', 'analyzeDoctorCode'].includes(field.column)">
                          <el-select v-model="patientDetails[
                            field.column === 'applyDoctorCode' ? 'applyDoctorName' : 'analyzeDoctorName'
                          ]
                            " :placeholder="`请选择${field.label}`" :disabled="!field.isEditable" size="small"
                            class="small-font" filterable remote reserve-keyword :remote-method="fetchDoctors" @change="
                              field.column === 'applyDoctorCode' ? handleApplyDoctorChange : handleAnalyzeDoctorChange
                              " style="width: 160px">
                            <el-option v-for="doctor in doctorList" :key="doctor.code"
                              :label="`${doctor.name} (${doctor.code})`" :value="`${doctor.name} (${doctor.code})`">
                            </el-option>
                          </el-select>
                        </template>
                        <!--采样模式-->
                        <template v-else-if="field.column === 'sampleMode'">
                          <el-select v-model="patientDetails[field.column]" :placeholder="`请选择${field.label}`"
                            :disabled="!field.isEditable" size="small" class="small-font" style="width: 160px"
                            filterable allow-create
                            :filter-method="(val) => {
                              if (val && !sampleModeOptions.some(opt => opt.value === val)) {
                                sampleModeOptions.push({
                                  value: val,
                                  label: val
                                });
                              }
                              return true;
                            }">
                            <el-option v-for="option in sampleModeOptions" 
                              :key="option.value" 
                              :label="option.label" 
                              :value="option.value">
                            </el-option>
                          </el-select>
                        </template>
                        <!-- 其他字段 -->
                        <template v-else>
                          <el-input v-model="patientDetails[field.column]" :placeholder="`请输入${field.label}`"
                            :disabled="!field.isEditable" @keyup.enter="handleKeyupEnter(field.column)" size="small"
                            class="small-font" style="width: 160px">
                          </el-input>
                        </template>
                      </div>
                    </template>
                  </el-col>
                </template>
              </el-row>
            </template>
          </el-header>

          <!-- 下方内容区域 -->
          <el-main class="content-area" :class="{ 'higher-tabs': !isBindingStatus }">
            <div style="position: relative; height: calc(100% - 10px)">
              <el-tabs @tab-click="handleTabClick">
                <el-tab-pane label="检查项目">
                  <el-row :gutter="10" style="height: 100%">
                    <el-col :span="12">
                      <el-scrollbar style="height: 100%" always>
                        <el-table :data="leftColumnItems" size="small" style="width: 100%" ref="leftItemsTable">
                          <el-table-column prop="index" label="序号" width="auto" min-width="10%"></el-table-column>
                          <el-table-column prop="parameter" label="参数" width="auto" min-width="20%"></el-table-column>
                          <el-table-column prop="itemName" show-overflow-tooltip label="项目名称" width="auto"
                            min-width="28%"></el-table-column>
                          <el-table-column prop="result" label="结果" width="auto" min-width="15%"></el-table-column>
                          <el-table-column prop="hint" label="提示" width="auto" min-width="10%">
                            <template #default="scope">
                              <span :class="{ high: scope.row.hint === '↑', low: scope.row.hint === '↓' }">
                                {{ scope.row.hint }}
                              </span>
                            </template>
                          </el-table-column>
                          <el-table-column prop="unit" label="单位" width="auto" min-width="10%"></el-table-column>
                          <el-table-column prop="referenceRange" label="参考范围" width="auto"
                            min-width="15%"></el-table-column>
                        </el-table>
                      </el-scrollbar>
                    </el-col>
                    <el-col :span="12">
                      <el-scrollbar style="height: 100%" always>
                        <el-table :data="rightColumnItems" size="small" style="width: 100%" ref="rightItemsTable">
                          <el-table-column prop="index" label="序号" width="auto" min-width="10%"></el-table-column>
                          <el-table-column prop="parameter" label="参数" width="auto" min-width="20%"></el-table-column>
                          <el-table-column prop="itemName" show-overflow-tooltip label="项目名称" width="auto"
                            min-width="28%"></el-table-column>
                          <el-table-column prop="result" label="结果" width="auto" min-width="15%"></el-table-column>
                          <el-table-column prop="hint" label="提示" width="auto" min-width="10%">
                            <template #default="scope">
                              <span :class="{ high: scope.row.hint === '↑', low: scope.row.hint === '↓' }">
                                {{ scope.row.hint }}
                              </span>
                            </template>
                          </el-table-column>
                          <el-table-column prop="unit" label="单位" width="auto" min-width="15%"></el-table-column>
                          <el-table-column prop="referenceRange" label="参考范围" width="auto"
                            min-width="15%"></el-table-column>
                        </el-table>
                      </el-scrollbar>
                    </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane label="历史参数" style="height: calc(100% - 10px)">
                  <el-scrollbar style="height: 100%">
                    <el-table :data="historicalParams" height="100%" size="small" style="width: 100%">
                      <!-- 序号列 -->
                      <el-table-column label="序号" width="auto" min-width="60">
                        <template #default="scope">
                          {{ scope.$index + 1 }}
                        </template>
                      </el-table-column>

                      <!-- 参数列 -->
                      <el-table-column prop="parameter" label="参数" width="auto" min-width="100"> </el-table-column>

                      <!-- 项目名称列 -->
                      <el-table-column prop="itemName" label="项目名称" show-overflow-tooltip width="auto" min-width="150">
                      </el-table-column>

                      <!-- 单位列 -->
                      <el-table-column prop="unit" label="单位" width="auto" min-width="80"> </el-table-column>

                      <!-- 参考范围列 -->
                      <el-table-column prop="referenceRange" label="参考范围" width="auto" min-width="120">
                        <template #default="scope">
                          <span v-if="scope.row.referenceRange">{{ scope.row.referenceRange }}</span>
                        </template>
                      </el-table-column>

                      <!-- 动态日期列 -->
                      <el-table-column v-for="(date, index) in uniqueDates" :key="index" :label="date" width="auto"
                        min-width="120">
                        <template #default="scope">
                          <span v-html="scope.row.values[index]"></span>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-scrollbar>
                </el-tab-pane>
                <el-tab-pane label="结果解读">
                  <div v-if="analyzeResult">
                    <p><strong>分析时间：</strong>{{ analyzeResult.timeanalyzed }}</p>
                    <p><strong>急性结果：</strong>{{ analyzeResult.resultacute }}</p>
                    <p><strong>慢性结果：</strong>{{ analyzeResult.resultchronic }}</p>
                    <p><strong>发布时间：</strong>{{ analyzeResult.publishdate }}</p>
                  </div>
                  <div v-else>
                    <p>无分析结果</p>
                  </div>
                </el-tab-pane>
              </el-tabs>
              <!-- 选择显示的条数 -->
              <el-select v-model="neartimes" v-show="showNeartimes" placeholder="选择显示次数" size="small"
                @change="handleTimesChange"
                style="position: absolute; right: 10px; top: 8px; width: 140px; height: 32px">
                <el-option label="近 3 次" select :value="3"></el-option>
                <el-option label="近 5 次" :value="5"></el-option>
                <el-option label="近 10 次" :value="10"></el-option>
                <el-option label="近 20 次" :value="20"></el-option>
                <el-option label="近 30 次" :value="30"></el-option>
                <el-option label="全部" :value="999"></el-option>
              </el-select>
            </div>
            <div v-if="samples.length > 0" class="bottom-info">
              <div class="left-info">
                <span><img src="@/assets/feiji.png" width="12px" style="margin-right: 2px" />报告状态:
                  <span style="color: green">{{ mapStatus(selectedSample.applyStatus) }}</span></span>
                <span><img src="@/assets/dayin.png" width="12px" style="margin-right: 2px" />打印状态:
                  <span style="color: orange">{{ printCount }}</span></span>
                <span v-if="
                  selectedSample.applyStatus !== 'await' &&
                  selectedSample.applyStatus !== 'saved' &&
                  selectedSample.applyStatus !== null
                ">
                  <img src="@/assets/yinzhang.png" width="12px" style="margin-right: 2px" />审核人: {{ auditorName }}
                </span>
                <span v-if="
                  selectedSample.applyStatus !== 'await' &&
                  selectedSample.applyStatus !== 'saved' &&
                  selectedSample.applyStatus !== null
                ">
                  <img src="@/assets/shijian.png" width="12px" style="margin-right: 2px" />审核时间: {{ auditedDate }}
                </span>
              </div>
              <div class="right-buttons">
                <template v-if="
                  selectedSample.applyStatus === '' ||
                  selectedSample.applyStatus === 'await' ||
                  selectedSample.applyStatus === null
                ">
                  <el-button type="primary" @click="handleSave" class="ctrl-button small-font">保存</el-button>
                  <el-button type="danger" @click="handleDelete" class="ctrl-button small-font">删除</el-button>
                </template>
                <template v-else-if="selectedSample.applyStatus === 'saved'">
                  <el-button type="danger" @click="handleInvalidate" class="ctrl-button small-font">作废</el-button>
                  <el-button type="primary" @click="handleAudit" class="ctrl-button small-font">审核</el-button>
                </template>
                <template v-else-if="selectedSample.applyStatus === 'invalid'">
                  <el-button type="success" @click="handleRestore" class="ctrl-button small-font">恢复</el-button>
                </template>
                <template v-else-if="selectedSample.applyStatus === 'audited'">
                  <el-button type="danger" @click="handleCancelAudit" class="ctrl-button small-font">取消审核</el-button>
                  <el-button type="success" @click="handlePublish" class="ctrl-button small-font">发布</el-button>
                </template>
                <template v-else>
                  <el-button type="primary" @click="handleRecycle" class="ctrl-button small-font">回收</el-button>
                  <el-button type="success" @click="handlePrint" class="ctrl-button small-font">打印</el-button>
                </template>
              </div>
            </div>
          </el-main>
        </el-container>
      </el-container>
    </el-container>
  </div>
  <!-- 回收弹出框 -->
  <el-dialog v-model="dialogVisible" title="回收报告原因" width="360px" @close="handleDialogClose" class="recycle-dialog">
    <div class="recycle-reason-container">
      <label for="recycleReason">填写原因:</label>
      <el-input v-model="recycleReason" placeholder="请输入" class="recycle-input"></el-input>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false" class="ctrl-button small-font">关闭</el-button>
      <el-button type="primary" @click="confirmRecycle" class="ctrl-button small-font">保存</el-button>
    </div>
  </el-dialog>
  <!--选择患者-->
  <el-dialog title="选择患者" v-model="visible" width="50%">
    <el-scrollbar max-height="400px">
      <el-table :data="patients" @row-click="handlePRowClick" highlight-current-row height="350">
        <el-table-column prop="patientId" label="患者ID" width="100"></el-table-column>
        <el-table-column prop="name" label="姓名" width="120"></el-table-column>
        <el-table-column prop="inpatNum" label="住院号" width="120"></el-table-column>
        <el-table-column prop="accessionNum" label="条码号" width="150"></el-table-column>
        <el-table-column prop="cardNum" label="卡号" width="120"></el-table-column>
        <el-table-column prop="bedNum" label="床号" width="120"></el-table-column>
      </el-table>
    </el-scrollbar>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visible = false">关闭</el-button>
        <el-button type="primary" @click="confirmSelection">确定</el-button>
      </span>
    </template>
  </el-dialog>
  <!-- 危急值列表 -->
  <el-dialog title="危急值确认" v-model="criticalValuesDialogVisible" width="30%">
    <el-table :data="criticalValues" style="width: 100%">
      <el-table-column label="选择">
        <template #header>
          <el-checkbox v-model="selectAll" @change="handleSelectAllChange"></el-checkbox>
        </template>
        <template #default="scope">
          <el-checkbox v-model="scope.row.checked" @change="handleRowCheckboxChange(scope.$index)"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column prop="parameter" label="参数"></el-table-column>
      <el-table-column prop="result" label="结果"></el-table-column>
      <el-table-column prop="unit" label="单位"></el-table-column>
      <el-table-column prop="criticalRange" label="危急值范围"></el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer" style="margin-top: 10px">
      <el-button @click="criticalValuesDialogVisible = false" style="width: 88px">关闭</el-button>
      <el-button type="primary" @click="confirmCriticalValues" style="width: 88px">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { useAuthStore } from '@/stores/authStore'
import { ElLoading, ElMessage } from 'element-plus'
import dayjs from 'dayjs'
import debounce from 'lodash/debounce'
import { ref, onMounted } from 'vue'
export default {
  name: 'ReportManager',
  setup() {
    const authStore = useAuthStore()
    const sourceOptions = ref([])
    const sampleTypeOptions = ref([])
    const sampleModeOptions = ref([])

    // 获取患者来源字典数据
    const getSourceOptions = async () => {
      try {
        const response = await authStore.getDictListByTypeCode('PatientSource')
        if (response.code === 200) {
          sourceOptions.value = response.data.map(item => ({
            label: item.dictLabel,
            value: item.dictValue
          }))
        }
      } catch (error) {
        console.error('获取患者来源数据失败:', error)
      }
    }

    // 获取样本类型字典数据
    const getSampleTypeOptions = async () => {
      try {
        const response = await authStore.getDictListByTypeCode('SampleType')
        if (response.code === 200) {
          sampleTypeOptions.value = response.data.map(item => ({
            label: item.dictLabel,
            value: item.dictValue
          }))
        }
      } catch (error) {
        console.error('获取样本类型数据失败:', error)
      }
    }

    // 获取采样模式字典数据
    const getSampleModeOptions = async () => {
      try {
        const response = await authStore.getDictListByTypeCode('SampleMode')
        if (response.code === 200) {
          sampleModeOptions.value = response.data.map(item => ({
            label: item.dictLabel,
            value: item.dictValue
          }))
        }
      } catch (error) {
        console.error('获取采样模式数据失败:', error)
      }
    }

    onMounted(() => {
      getSourceOptions()
      getSampleTypeOptions()
      getSampleModeOptions()
    })

    return {
      sourceOptions,
      sampleTypeOptions,
      sampleModeOptions
    }
  },
  data() {
    const today = new Date()
    const thirtyDaysAgo = new Date()
    thirtyDaysAgo.setDate(today.getDate() - 30)
    return {
      analyzeResult: null, // 存储分析结果
      currentRow: null,
      doctorList: [], // 存储医生列表
      neartimes: 3,
      showNeartimes: false,
      historicalParams: [],
      recycleReason: '',
      inspectionItems: [],
      hospital: '',
      department: '',
      device: '',
      dateStart:   today.setHours(0, 0, 0, 0),     
      dateEnd: today.setHours(23, 59, 59, 999),
      status: '',
      currentPage: 1,
      pageSize: 50,
      totalRecords: 0,
      selectedRecords: [],
      selectedRows: [],
      samples: [],
      selectedSample: {},
      hospitalList: [],
      departmentList: [],
      deviceList: [], // 全部设备列表
      filteredDevices: [], // 过滤后的设备列表
      patientInfo: {
        name: '',
        sex: '',
        bedNum: '',
        birthdayDate: '',
        patientId: '',
        inpatNum: '',
        cardNum: '',
        diagnosis: '',
        sourceCode: '',
        remark: '',
        address: '',
        phone: '',
        sampleType: '',
        sampleDesc: '',
        sampleCollectionDate: '',
        sampleReceiveDate: '',
        analyzeDate: '',
        applyDoctorName: '',
        analyzeDoctorName: '',
        accessionNum: ''
      },
      patientDetails: {},
      auditorName: '',
      auditedDate: '',
      printCount: '',
      dialogVisible: false,
      uniqueDates: [],
      visible: false,
      patients: [],
      selectedPatient: null,
      criticalValuesDialogVisible: false,
      criticalValues: [],
      selectAll: false,
      settings: {},
      userData: {} // 添加用户数据属性
    }
  },
  watch: {
    criticalValues: {
      handler(newVal) {
        this.updateSelectAll()
      },
      deep: true
    },
    // 监听容器大小变化
    '$refs.leftItemsTable.$el.clientHeight'() {
      this.$nextTick(() => {
        // 强制重新计算列表数据
        this.$forceUpdate()
      })
    }
  },
  methods: {
    show(patients) {
      this.patients = patients
      this.visible = true
    },
    handlePRowClick(row) {
      this.selectedPatient = row
    },
    confirmSelection() {
      if (this.selectedPatient) {
        this.patientDetails = this.selectedPatient
        this.visible = false
      } else {
        ElMessage.warning('请选择一个患者')
      }
    },
    // 回收按钮点击事件
    handleRecycle() {
      this.dialogVisible = true // 显示回收弹出框
    },
    //改变颜色
    getStatusClass(status) {
      const statusClasses = {
        null: 'status-orange',
        publish: 'status-green',
        await: 'status-orange',
        invalid: 'status-red',
        saved: 'status-blue',
        audited: 'status-orange'
      }
      return statusClasses[status] || ''
    },
    handleTabClick(TabsPaneContext, Event) {
      console.log(TabsPaneContext.props.label, 'TabsPaneContext')
      console.log(Event, 'TabsPaneEvent')
      if (TabsPaneContext.props.label == '历史参数') {
        this.showNeartimes = true
      } else this.showNeartimes = false
    },
    // 确认回收
    async confirmRecycle() {
      // 在这里添加回收逻辑
      if (this.recycleReason == null || this.recycleReason == '') {
        ElMessage.warning('回收理由不能为空')
        return
      }
      if (!this.selectedSample || !this.selectedSample.id) {
        ElMessage.warning('未选中任何样本，请选择一个样本。')
        return
      }
      // 构建回收请求的 payload
      const recyclePayload = {
        id: '',
        sampleid: this.selectedSample.id,
        opercode: '', // 操作人代码
        opername: '', // 操作人姓名
        reason: this.recycleReason,
        createtime: ''
      }

      try {
        const authStore = useAuthStore()
        const recycleResponse = await authStore.sampleRecycle(recyclePayload)
        if (recycleResponse.code === 200) {
          // 回收成功后，立即调用状态更新方法
          await this.handleRecycleReport() // 直接调用回收报告方法
          //this.$message.success('样本已回收');
          this.dialogVisible = false // 成功后关闭模态框
          this.recycleReason = ''
        } else {
          this.$message.error(`回收失败: ${recycleResponse.msg}`)
        }
      } catch (error) {
        console.error('回收过程中发生错误:', error)
        this.$message.error('操作失败，请检查网络或联系管理员')
      }
    },

    // 关闭弹出框
    handleDialogClose() {
      this.dialogVisible = false
    },
    async fetchHospitals() {
      try {
        const authStore = useAuthStore()
        console.log('正在获取医院列表...')
        const response = await authStore.hospitallist()
        if (response.code === 200) {
          if (this.userData.dataPermission.hospitals && this.userData.dataPermission.hospitals.length > 0) {
            this.hospitalList = response.data.filter(hospital => 
              this.userData.dataPermission.hospitals.includes(hospital.hospitalCode)
            )
            console.log('过滤后的医院列表:', this.hospitalList)
          } else {
            this.hospitalList = []
            console.log('用户没有医院权限')
          }
          
          if (this.hospitalList.length > 0) {
            this.hospital = this.hospitalList[0].hospitalCode
            console.log('已选择默认医院:', this.hospital)
            return true
          } else {
            ElMessage.warning('没有可用的医院列表')
            return false
          }
        } else {
          ElMessage.error(response.msg || '获取医院列表失败')
          return false
        }
      } catch (error) {
        console.error('获取医院列表出错:', error)
        ElMessage.error('获取医院列表失败')
        return false
      }
    },
    async fetchDepartments(hospitalCode) {
      try {
        const authStore = useAuthStore()
        console.log('userData before fetching departments:', this.userData)
        const response = await authStore.getDepartmentList(hospitalCode)
        if (response.code === 200) {
          if (this.userData.dataPermission.departments && this.userData.dataPermission.departments.length > 0) {
            this.departmentList = response.data.filter(department => this.userData.dataPermission.departments.includes(department.id))
          } else {
            //为空,则不显示
            this.departmentList = [];
          }
          if (this.departmentList.length > 0) {
            this.department = this.departmentList[0].id
            await this.filterDevicesByDepartment() // 自动获取该科室下的设备
          }
        }
      } catch (error) {
        console.error('Error fetching departments:', error)
      }
    },
    async fetchDevices() {
      // 获取设备列表
      try {
        const authStore = useAuthStore()
        const response = await authStore.devicelist()
        if (response.code === 200) {
          this.deviceList = response.data
          await this.filterDevicesByDepartment() // 初次加载时过滤设备
        }
      } catch (error) {
        console.error('Error fetching devices:', error)
      }
    },
    filterDevicesByDepartment() {
      // 根据选中的科室过滤设备
      if (this.department) {
        this.filteredDevices = this.deviceList.filter((device) => device.deptId === this.department)
        if (this.filteredDevices.length > 0) {
          this.device = this.filteredDevices[0].id // 默认选择第一个设备
        }
      } else {
        this.filteredDevices = []
        this.device = '' // 如果没有科室选中，则清空设备选择
      }
    },
    async search() {
      const loading = ElLoading.service({ lock: true, text: '加载中...', background: 'rgba(0, 0, 0, 0.1)', target: document.querySelector('.report-page') })
      try {
        const authStore = useAuthStore()
        const deviceId = this.device
        console.log('deviceId:', deviceId)
        if (deviceId == null || deviceId == '') {
          ElMessage.warning('请选择设备')
          return
        }
        // 检查设备质量状态
        const qualityStatus = await authStore.getDeviceQualityStatus(deviceId)
        const deviceName = this.deviceList.find((device) => device.id === deviceId)?.name || '未知设备'
        if (qualityStatus.data.qcStatus === '0' || qualityStatus.data.qcStatus === '2') {
          ElMessage.warning(`设备${deviceName}: ${qualityStatus.data.qcMessage}`)
        } else if (qualityStatus.data.qcStatus !== '1') {
          ElMessage.warning(`设备${deviceName}: ${qualityStatus.data.qcMessage}`)
          return // 不继续查询
        }

        // 继续查询样本数据
        const params = {
          deviceId: this.device,
          pageIndex: this.currentPage,
          pageSize: this.pageSize
        }

        const isLocalhost = window.location.hostname === 'localhost' && window.location.port === '8080'
        if (isLocalhost) {
          params.startCheckTime = Math.floor(new Date('2023-09-01').getTime() / 1000)
          params.endCheckTime = Math.floor(new Date('2023-09-30T23:59:59').getTime() / 1000)
        } else {
          params.startCheckTime = Math.floor(new Date(this.dateStart).getTime() / 1000)
          params.endCheckTime = Math.floor(new Date(this.dateEnd).getTime() / 1000)
        }

        const response = await authStore.getSamples(params)
        if (response.code === 200) {
          if (response.data.total === 0) {
            ElMessage({
              message: '没有找到匹配的记录，请调整搜索条件。',
              type: 'warning'
            })
            this.samples = []
            this.inspectionItems = []
            this.patientDetails = {}
            this.historicalParams = []
            this.analyzeResult = null
            this.selectedSample = {}
          } else {
            this.samples = response.data.data.map((item) => ({
              id: item.id || '',
              sampleNum: item.sampleNum || '',
              accessionNum: item.accessionNum || '',
              deviceId: item.deviceId || '',
              deviceName: item.deviceName || '',
              requestDeptName: item.requestDeptName || '',
              patientId: item.patientId || '',
              creatorEmplId: item.creatorEmplId || '',
              creatorEmplName: item.creatorEmplName || '',
              payStatus: item.payStatus || '',
              auditedEmplId: item.auditedEmplId || '',
              auditedEmplName: item.auditedEmplName || '',
              operationEmplId: item.operationEmplId || '',
              operationEmplName: item.operationEmplName || '',
              printCount: item.printCount || '',
              applyStatus: item.applyStatus,
              analyzeDate: item.analyzeDate || '',
              auditedDate: item.auditedDate || '',
              updateDate: item.updateDate || '',
              createDate: item.createDate || '',
              deptId: item.deptId || '',
              listParams: item.listParams
                ? item.listParams.map((param) => ({
                  id: param.id || '',
                  code: param.code || '',
                  name: param.name || '',
                  unit: param.unit || '',
                  sort: param.sort || 0,
                  status: param.status || 0,
                  type: param.type || '',
                  calcType: param.calcType || '',
                  payType: param.payType || '',
                  refRangeMin: param.refRangeMin || '',
                  refRangeMax: param.refRangeMax || '',
                  criticalRangeMin: param.criticalRangeMin || '',
                  criticalRangeMax: param.criticalRangeMax || '',
                  refRange: param.refRange || '',
                  criticalRange: param.criticalRange || '',
                  value: param.value || '',
                  sampleId: param.sampleId || '',
                  createTime: param.createTime ? new Date(param.createTime).toLocaleString() : '',
                  deviceId: param.deviceId || '',
                  dotNum: param.dotNum || 0,
                  //listSpecialParam: param.listSpecialParam || '',
                  standardParamCode: param.standardParamCode || ''
                }))
                : [],
              patient: item.patient || '',
              isSaved: item.isSaved || ''
            }))
            this.totalRecords = response.data.total
            this.selectFirstRow()
          }
        } else {
          ElMessage.error(response.msg || '获取数据失败')
        }
      } catch (error) {
        console.error('Error fetching samples:', error)
        ElMessage.error('搜索请求失败')
      } finally {
        loading.close()
      }
    },

    // 辅助方法：将状态码转换为中文
    mapStatus(status) {
      const statusMap = {
        await: '待绑定',
        saved: '已保存',
        audited: '已审核',
        publish: '已发布',
        invalid: '已作废'
      }
      // 考虑到 null 或未定义状态，均视为 '待绑定'
      return status ? statusMap[status] || '未知状态' : '待绑定'
    },
    selectFirstRow() {
      if (this.samples.length > 0) {
        this.$nextTick(() => {
          this.$refs.leftTable.setCurrentRow(this.samples[0]) // 默认选中第一行
          this.handleRowClick(this.samples[0])
        })
      }
    },
    reset() {
      const today = new Date()
      
      const thirtyDaysAgo = new Date()
      thirtyDaysAgo.setDate(today.getDate() - 30)
      this.hospital = ''
      this.department = ''
      this.device = ''
      this.dateStart = today.setHours(0, 0, 0, 0)
      this.dateEnd = today.setHours(23, 59, 59, 999)
      this.status = ''
      this.currentPage = 1
      this.pageSize = 23
      this.samples = []
      this.patientInfo = {} // 清空患者信息
    },
    handleTimesChange(value) {
      // 更新选中的显示次数
      this.neartimes = value
      console.log(1111)
      // 重新加载选中的行数据
      if (this.selectedPatientId) {
        this.fetchHistoricalParams(this.selectedPatientId)
      }
    },
    async fetchHistoricalParams(patientId) {
      const authStore = useAuthStore()
      try {
        const response = await authStore.getRecentInfo(patientId, this.neartimes) // 获取最近的3次数据
        if (response.code === 200) {
          // 提取所有唯一的参数
          const paramsMap = new Map()

          response.data.forEach((item) => {
            item.listParams.forEach((param) => {
              if (!paramsMap.has(param.code)) {
                paramsMap.set(param.code, {
                  parameter: param.code,
                  itemName: param.name,
                  unit: param.unit,
                  referenceRange: this.formatReferenceRange(param.refRangeMin, param.refRangeMax),
                  values: []
                })
              }
            })
          })

          // 为每个日期填充参数的值
          response.data.forEach((item) => {
            item.listParams.forEach((param) => {
              const paramData = paramsMap.get(param.code)
              const value = parseFloat(param.value)
              let hint = ''
              if (!isNaN(value)) {
                // 根据参考范围判断提示箭头
                const refRangeMin = parseFloat(param.refRangeMin)
                const refRangeMax = parseFloat(param.refRangeMax)
                if (!isNaN(refRangeMin) && value < refRangeMin) {
                  hint = '↓'
                } else if (!isNaN(refRangeMax) && value > refRangeMax) {
                  hint = '↑'
                }
              }
              const formattedValue = `${value}${hint ? `<span style="color:${hint === '↑' ? 'red' : 'green'};">${hint}</span>` : ''
                }`
              paramData.values.push(formattedValue)
            })
          })

          this.historicalParams = Array.from(paramsMap.values())

          // 提取唯一的日期
          this.uniqueDates = response.data
            .map((item) => this.formatDateH(item.analyzeDate))
            .filter((date, index, self) => self.indexOf(date) === index)
        } else {
          ElMessage.error(response.msg || '获取历史参数数据失败')
        }
      } catch (error) {
        console.error('Error fetching historical parameters:', error)
      }
    },

    formatReferenceRange(refRangeMin, refRangeMax) {
      if (!refRangeMin && !refRangeMax) return ''
      if (!refRangeMin) return `≤${refRangeMax}`
      if (!refRangeMax) return `≥${refRangeMin}`
      return `${refRangeMin}-${refRangeMax}`
    },
    async handleRowClick(row) {
      const loading = ElLoading.service({ lock: true, text: '加载中...', background: 'rgba(0, 0, 0, 0.1)', target: document.querySelector('.report-page') })  
      //点击行,获取检查项目
      this.currentRow = row
      const originalStatus = this.status // 保存原始状态
      this.auditedDate = ''
      this.auditorName = ''
      this.status = ''
      this.printCount = ''
      this.patientDetails = {}
      this.selectedSample = this.samples.find((patient) => patient.sampleNum === row.sampleNum)
      console.log('this.selectedSample:', this.selectedSample)
      if (this.selectedSample) {
        this.patientInfo = { ...this.selectedSample } // 使用返回的JSON中的sample数据
        console.log('patientInfo:', this.patientInfo)
        const authStore = useAuthStore()
        // 无论状态如何，都尝试获取病人详细信息
        if (this.patientInfo.patient == '') {
          if (this.selectedSample.accessionNum != null || this.selectedSample.accessionNum != '') {
            try {
              const response = await authStore.getPatient({ accessionNum: this.selectedSample.accessionNum })
              if (response.data && response.data.length > 0) {
                const patient = response.data[0]
                patient.age = this.calculateAge(patient.birthdayDate)
                // 修改医生显示格式
                if (patient.applyDoctorCode && patient.applyDoctorName) {
                  patient.applyDoctorName = `${patient.applyDoctorName} (${patient.applyDoctorCode})`
                }
                if (patient.analyzeDoctorCode && patient.analyzeDoctorName) {
                  patient.analyzeDoctorName = `${patient.analyzeDoctorName} (${patient.analyzeDoctorCode})`
                }
                this.patientDetails = patient
              } else {
                ElMessage.warning('本次搜索没有查询到患者信息')
              }
            } catch (error) {
              console.error('Error fetching patient details:', error)
            }finally{
              loading.close()
            }
          }
        } else {
          this.patientDetails = this.patientInfo.patient
          this.patientDetails.age = this.calculateAge(this.patientDetails.birthdayDate)
          // 修改医生显示格式
          if (this.patientDetails.applyDoctorCode && this.patientDetails.applyDoctorName) {
            this.patientDetails.applyDoctorName = `${this.patientDetails.applyDoctorName} (${this.patientDetails.applyDoctorCode})`
          }
          if (this.patientDetails.analyzeDoctorCode && this.patientDetails.analyzeDoctorName) {
            this.patientDetails.analyzeDoctorName = `${this.patientDetails.analyzeDoctorName} (${this.patientDetails.analyzeDoctorCode})`
          }
        }
        this.patientDetails.analyzeDate = this.patientInfo.analyzeDate

        // 尝试获取检查项目数据
        const id = this.selectedSample.id
        const res = await authStore.getSample(id)
        if (res.data && res.data.length > 0) {
          this.selectedSample.listParams = res.data
        }
        //this.status = selectedPatient.status;
        this.printCount = this.selectedSample.printCount === '' ? '未打印' : '已打印'

        // 获取审核人信息
        if (this.selectedSample.auditedEmplId) {
          const userResponse = await authStore.getUser({
            emplIds: this.selectedSample.auditedEmplId,
            pageIndex: 1,
            pageSize: 1
          })
          if (userResponse.data && userResponse.data.data.length > 0) {
            this.auditorName = userResponse.data.data[0].name
            this.auditedDate = this.formatDateH(this.selectedSample.auditedDate)
          }
        }

        // 获取历史参数数据
        this.selectedPatientId = row.patient.patientId
        await this.fetchHistoricalParams(this.selectedPatientId)

        // 处理检查项目数据
        if (this.selectedSample.listParams) {
          console.log('this.selectedSample.listParams:', this.selectedSample.listParams)
          this.inspectionItems = this.selectedSample.listParams.map((item, index) => {
            const value = parseFloat(item.value)
            let hint = ''
            let formattedRefRange = item.refRange

            if (item.refRange) {
              const refRange = item.refRange.match(/(≥?)([0-9.]+)(&&≤?([0-9.]+))?/)
              if (refRange) {
                const lowerBound = refRange[1] === '≥' ? parseFloat(refRange[2]) : -Infinity
                const upperBound = refRange[4] ? parseFloat(refRange[4]) : Infinity

                if (value < lowerBound) {
                  hint = '↓'
                } else if (value > upperBound) {
                  hint = '↑'
                }

                formattedRefRange = `${refRange[2]}-${refRange[4] || ''}`
              }
            }

            return {
              index: index + 1,
              parameter: item.code,
              itemName: item.name,
              result: item.value,
              criticalRangeMax: item.criticalRangeMax,
              criticalRangeMin: item.criticalRangeMin,
              criticalRange: item.criticalRange,
              refRangeMax: item.refRangeMax,
              refRangeMin: item.refRangeMin,
              hint: hint,
              unit: item.unit,
              referenceRange: formattedRefRange,
              standardParamCode: item.standardParamCode
            }
          })
          //console.log("this.inspectionItems:", this.inspectionItems);
        } else {
          this.inspectionItems = []
        }
        //if (this.selectedSample.applyStatus === 'publish') {
        await this.fetchAnalyzeResult()
        //} else {
        //this.analyzeResult == null;
        //}
      }
      loading.close()
      this.status = originalStatus // 恢复之前的状态
    },
    async fetchAllDoctors() {
      try {
        const authStore = useAuthStore()
        const response = await authStore.getUser({ pageIndex: 1, pageSize: 50 }) // 不传任何参数，获取所有用户
        if (response.code === 200 && response.data && response.data.data.length > 0) {
          console.log("doctorlist:",response.data.data)
          this.doctorList = response.data.data // 将获取的医生列表存储在 doctorList 中
        } else {
          ElMessage.error(response.msg || '获取医生列表失败')
        }
      } catch (error) {
        console.error('Error fetching doctors:', error)
      }
    },
    // 动态搜索医生的方法 (在用户输入时调用) - 使用防抖机制
    fetchDoctors: debounce(async function (searchKey) {
      //this.loadingDoctors = true;
      try {
        const authStore = useAuthStore()
        // 根据用户输入的 searchKey 参数进行搜索
        const response = await authStore.getUser({
          searchKey: searchKey,
          pageIndex: 1,
          pageSize: 50
        })
        if (response.code === 200 && response.data && response.data.data.length > 0) {
          this.doctorList = response.data.data // 更新医生列表
        } else {
          this.doctorList = [] // 如果没有结果，清空医生列表
        }
      } catch (error) {
        console.error('Error fetching doctors:', error)
        this.doctorList = []
      } finally {
        //this.loadingDoctors = false;
      }
    }, 500),
    calculateAge(birthday) {
      if (!birthday) return ''

      const birthDate = dayjs(birthday)
      const today = dayjs()

      // Calculate the difference in years
      let age = today.diff(birthDate, 'year')
      console.log('age:', age)

      // Adjust the age if the birthday hasn't occurred yet this year
      if (
        today.month() < birthDate.month() ||
        (today.month() === birthDate.month() && today.date() < birthDate.date())
      ) {
        age -= 1
      }

      return age
    },
    formatDate(date) {
      if (!date) return ''
      return dayjs(date).format('YYYY-MM-DD') // 格式化为 年-月-日
    },
    formatDateH(date) {
      if (!date) return ''
      const date1 = new Date(date)
      return dayjs(date).format('YYYY-MM-DD HH:mm:ss') // 格式化为 年-月-日-时分秒
    },
    handlePageChange(page) {
      this.currentPage = page
      this.search()
    },
    handleSizeChange(size) {
      this.pageSize = size
      this.currentPage = 1
      this.search()
    },
    getItemsForColumn(colIndex) {
      return this.items[colIndex]
    },
    getItemClass(colIndex, rowIndex) {
      if (colIndex === 3 && rowIndex === 0) {
        return 'double-height'
      }
      if (colIndex === 4 && rowIndex === 1) {
        return 'double-height'
      }
      if (colIndex === 6) {
        return 'center-content'
      }
      return ''
    },
    getValue(key) {
      return this.patientDetails[key]
    },
    async fetchPatient(type) {
      const authStore = useAuthStore()
      let params = {}
      if (type === 'inpatNum') {
        if (this.patientDetails.inpatNum) {
          params.inpatNum = this.patientDetails.inpatNum
        } else {
          ElMessage.warning('请输入住院号')
          return
        }
      } else if (type === 'accessionNum') {
        if (this.patientDetails.accessionNum) {
          params.accessionNum = this.patientDetails.accessionNum
        } else {
          ElMessage.warning('请输入条码号')
          return
        }
      } else if (type === 'name') {
        if (this.patientDetails.name) {
          params.name = this.patientDetails.name
        } else {
          ElMessage.warning('请输入姓名')
          return
        }
      } else {
        ElMessage.warning('无效的查询类型')
        return
      }
      const loadingInstance = ElLoading.service({ fullscreen: false })
      try {
        const response = await authStore.getPatient(params)
        if (response.data && response.data.length > 0) {
          if (response.data.length === 1) {
            // 保存原样本的信息
            const originalSample = { ...this.selectedSample }
            // 更新患者信息
            this.patientDetails = response.data[0]
            // 恢复原样本的信息
            this.selectedSample = originalSample
            // 将样本的检测时间赋值给患者详情
            this.patientDetails.analyzeDate = this.selectedSample.analyzeDate
          } else {
            this.patients = response.data
            this.visible = true
          }
        } else {
          ElMessage.warning('本次搜索没有查询到患者信息')
        }
      } catch (error) {
        console.error('Error fetching patient details:', error)
        ElMessage.error('获取患者信息失败')
      } finally {
        loadingInstance.close()
      }
    },
    convertToISOTimestamp(dateString) {
      // 检查是否已经是ISO 8601格式 (简单��查)
      const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/
      if (iso8601Regex.test(dateString)) {
        return dateString // 如果已经是ISO格式，则原样返回
      }

      // 假设输入日期时间为本地日期格式，进行转换为ISO 8601格式
      const date = new Date(dateString)
      if (!isNaN(date.getTime())) {
        return date.toISOString()
      } else {
        // 如果转换失败（例如，由于无效的日期字符串），返回原始字符串或进行错误处理
        return dateString
      }
    },
    async handleSave() {
      // 字段验证
      if (
        !this.patientDetails.name ||
        !this.patientDetails.patientId ||
        !this.patientDetails.sampleCollectionDate ||
        !this.patientDetails.sampleReceiveDate ||
        !this.selectedSample.analyzeDate ||
        !this.patientDetails.analyzeDoctorName
      ) {
        ElMessage.warning('请完整填写必需信息，并确保所有日期时间段都已填写。')
        return
      }
      // 时间验证
      const collectionDate = new Date(this.patientDetails.sampleCollectionDate).getTime()
      const receiveDate = new Date(this.patientDetails.sampleReceiveDate).getTime()
      const analyzeDate = new Date(this.selectedSample.analyzeDate).getTime()
      if (!(analyzeDate > receiveDate && receiveDate > collectionDate)) {
        ElMessage.warning('检测时间必须晚于接收时间，接收时间必须晚于采样时间。')
        return
      }
      // 获取当前选中的样本
      if (!this.selectedSample) {
        ElMessage.warning('没有找到选中的样本，请确认是否选中了样本。')
        return
      }
      // 构建提交到后端的对象
      const patientData = {
        id: this.patientDetails.id,
        patientId: this.patientDetails.patientId,
        sourceCode: this.patientDetails.sourceCode,
        inpatNum: this.patientDetails.inpatNum,
        accessionNum: this.patientDetails.accessionNum,
        cardNum: this.patientDetails.cardNum,
        name: this.patientDetails.name,
        sex: this.patientDetails.sex,
        bedNum: this.patientDetails.bedNum,
        deptId: this.patientDetails.deptId,
        deptName: this.patientDetails.deptName === null ? '' : this.patientDetails.deptName,
        applyNo: this.patientDetails.applyNo === null ? 'applyNo' : this.patientDetails.applyNo,
        orderId: this.patientDetails.orderId === null ? 'orderid' : this.patientDetails.orderId,
        orderCode: this.patientDetails.orderCode === null ? 'orderCode' : this.patientDetails.orderCode,
        orderName: this.patientDetails.orderName === null ? 'orderName' : this.patientDetails.orderName,
        diagnosis: this.patientDetails.diagnosis,
        remark: this.patientDetails.remark,
        applyDoctorCode: this.patientDetails.applyDoctorName ? 
          (this.patientDetails.applyDoctorName.includes('(') ? 
            this.patientDetails.applyDoctorName.split(' (')[1].replace(')', '') : 
            '') : '',
        applyDoctorName: this.patientDetails.applyDoctorName ? 
          (this.patientDetails.applyDoctorName.includes('(') ? 
            this.patientDetails.applyDoctorName.split(' (')[0] : 
            this.patientDetails.applyDoctorName) : '',
        address: this.patientDetails.address,
        phone: this.patientDetails.phone,
        sampleType: this.patientDetails.sampleType,
        sampleMode: this.patientDetails.sampleMode,
        sampleDesc: this.patientDetails.sampleDesc,
        analyzeDoctorCode: this.patientDetails.analyzeDoctorName ? 
          (this.patientDetails.analyzeDoctorName.includes('(') ? 
            this.patientDetails.analyzeDoctorName.split(' (')[1].replace(')', '') : 
            '') : '',
        analyzeDoctorName: this.patientDetails.analyzeDoctorName ? 
          (this.patientDetails.analyzeDoctorName.includes('(') ? 
            this.patientDetails.analyzeDoctorName.split(' (')[0] : 
            this.patientDetails.analyzeDoctorName) : '',
        birthdayDate: this.convertToISOTimestamp(this.patientDetails.birthdayDate),
        sampleCollectionDate: this.convertToISOTimestamp(this.patientDetails.sampleCollectionDate),
        sampleReceiveDate: this.convertToISOTimestamp(this.patientDetails.sampleReceiveDate)
      }
      console.log(patientData)

      const sampleData = {
        id: this.selectedSample.id,
        sampleNum: this.selectedSample.sampleNum,
        accessionNum: this.selectedSample.accessionNum,
        deviceId: this.selectedSample.deviceId,
        patientPk: this.selectedSample.patientPk,
        patientId: this.selectedSample.patientId,
        creatorEmplId: this.selectedSample.creatorEmplId,
        payStatus: this.selectedSample.payStatus,
        operationEmplId: this.selectedSample.operationEmplId,
        applyStatus: 'saved',
        analyzeDate: this.convertToISOTimestamp(this.selectedSample.analyzeDate),
        updateDate: new Date().toISOString(), // 直接获取当前日期和时间的ISO格式
        listParams: this.selectedSample.listParams
      }

      const saveData = {
        patient: patientData,
        sample: sampleData
      }

      // 调用store中的保存方法
      try {
        const authStore = useAuthStore()
        const response = await authStore.sampleSave(saveData) // 确保传递JSON字符
        if (response.code === 200) {
          ElMessage.success('样本保存成功')
          // 更新samples数组中的selectedSample
          const index = this.samples.findIndex((sample) => sample.id === this.selectedSample.id)
          if (index !== -1) {
            this.samples[index] = { ...this.samples[index], ...response.data } // 合并更新数据
            this.selectedSample = this.samples[index] // 更新selectedSample引用
          }
          // 重置选中的行
          this.$nextTick(() => {
            this.$refs.leftTable.setCurrentRow(this.selectedSample)
          })
        } else {
          ElMessage.error(`保存失败: ${response.msg}`)
        }
      } catch (error) {
        console.error('保存过程中发生错误:', error)
        ElMessage.error('保存失败，请检查网络或联系管理员')
      }
    },
    formatDateTime(dateString) {
      const date = new Date(dateString)
      return date.toLocaleString() // 根据需要可能要调整区域设置
    },
    handleCurrentChange(currentRow) {
      this.currentRow = currentRow
    },
    tableRowClassName({ row }) {
      if (row === this.currentRow) {
        return 'row-highlight'
      }
      return ''
    },
    // 处理申请医生选择变化
    handleApplyDoctorChange(selectedValue) {
      if (selectedValue) {
        const [name, code] = selectedValue.split(' (')
        this.patientDetails.applyDoctorName = selectedValue
        this.patientDetails.applyDoctorCode = code.replace(')', '')
      }
    },
    // 处理检测医生选择变化
    handleAnalyzeDoctorChange(selectedValue) {
      if (selectedValue) {
        const [name, code] = selectedValue.split(' (')
        this.patientDetails.analyzeDoctorName = selectedValue
        this.patientDetails.analyzeDoctorCode = code.replace(')', '')
      }
    },
    async handleInvalidate() {
      if (!this.selectedSample) {
        ElMessage.warning('未选中任何样本，请选择一个样本。')
        return
      }

      // 构建请求
      const payload = {
        samples: [
          {
            id: this.selectedSample.id,
            applyStatus: 'saved' // 确保这是在发起作废请求前的必要状态
          }
        ],
        targetStatus: 'invalid'
      }

      try {
        const authStore = useAuthStore()
        const response = await authStore.sampleUpdate(payload)
        if (response.code === 200) {
          ElMessage.success('样本已成功作废')
          // 更新前端状态
          this.selectedSample.applyStatus = 'invalid'
          const index = this.samples.findIndex((sample) => sample.id === this.selectedSample.id)
          if (index !== -1) {
            this.samples[index].applyStatus = 'invalid'
            this.currentRow = this.samples[index]
          }
          // 你可能还需要重新加载或更新UI的其他部分，视具体情况而定
        } else {
          ElMessage.error(`作废失败: ${response.msg}`)
        }
      } catch (error) {
        console.error('作废过程中发生错误:', error)
        ElMessage.error('作废失败，请检查网络或联系管理员')
      }
    },
    async handleDelete() {
      try {
        const authStore = useAuthStore()
        const samplesToDelete1 = this.selectedRows.length > 0 ? this.selectedRows : [this.selectedSample]
        const samplesToDelete = samplesToDelete1.map((row) => ({
          id: row.id,
          applyStatus: row.applyStatus
        }))
        const response = await authStore.deleteSamples(samplesToDelete)
        if (response.code === 200) {
          ElMessage.success('删除成功')
          // 直接在界面上删除
          this.samples = this.samples.filter((sample) => !samplesToDelete.some((deleted) => deleted.id === sample.id))
          this.selectedSample = ''
        } else {
          ElMessage.error(response.msg || '删除失败')
        }
      } catch (error) {
        console.error('Error deleting samples:', error)
        ElMessage.error('删除请求失败')
      }
    },
    async handleRestore() {
      if (!this.selectedSample || !this.selectedSample.id) {
        ElMessage.warning('未选中任何样本，请选择一个样本。')
        return
      }

      const payload = {
        samples: [
          {
            id: this.selectedSample.id,
            applyStatus: 'invalid'
          }
        ],
        targetStatus: 'await'
      }

      try {
        const authStore = useAuthStore()
        const response = await authStore.sampleUpdate(payload)
        if (response && response.code === 200) {
          ElMessage.success('样本已恢复到待绑定状态')
          this.selectedSample.applyStatus = 'await' // 更新内存中的状态
          // 更新列表中对应的样本状态
          const index = this.samples.findIndex((sample) => sample.id === this.selectedSample.id)
          if (index !== -1) {
            this.samples[index].applyStatus = 'await'
            this.currentRow = this.samples[index]
          }
        } else {
          ElMessage.error(`恢复失败: ${response.msg || '未知错误'}`)
        }
      } catch (error) {
        console.error('恢复过程中发生错误:', error)
        ElMessage.error('恢复失败，请检查网络或联系管理员')
      }
    },

    async handleAudit() {
      // 审核
      if (!this.selectedSample || !this.selectedSample.id) {
        ElMessage.warning('未选中任何样本，请选择一个样本。')
        return
      }
      // 检查配置文件中的 REVIEWER_DIFF_CHECKER
      if (this.settings.sh) {
        const authStore = useAuthStore()
        const currentUser = authStore.userData.name
        const analyzeDoctor = this.patientDetails.analyzeDoctorName
        if (currentUser === analyzeDoctor) {
          ElMessage.warning('审核人不能与检测医生相同!')
          return
        }
      }
      // 获取危急值数据
      this.criticalValues = this.getCriticalValues()
      if (this.criticalValues.length === 0) {
        // 如果危急值为空，则直接执行审核
        this.confirmCriticalValues()
      } else {
        // 弹出危急值确认框
        this.criticalValuesDialogVisible = true
      }
    },
    confirmCriticalValues() {
      const criticalCodeList = this.criticalValues.filter((item) => item.checked).map((item) => item.parameter)
      const payload = {
        samples: [
          {
            id: this.selectedSample.id,
            applyStatus: 'saved' // 确保这是在发起作废请求前的必要状态
          }
        ],
        targetStatus: 'audited',
        CriticalCodeList: criticalCodeList
      }

      this.submitAudit(payload)
      this.criticalValuesDialogVisible = false
    },
    getCriticalValues() {
      // 获取危急值数据并返回
      const criticalValues = []
      if (this.selectedSample.listParams) {
        this.inspectionItems.forEach((param) => {
          if (param.criticalRangeMin && param.criticalRangeMax) {
            const value = parseFloat(param.result)
            const minValue = parseFloat(param.criticalRangeMin)
            const maxValue = parseFloat(param.criticalRangeMax)
            const isCritical = value <= minValue || value >= maxValue
            if (isCritical) {
              criticalValues.push({
                parameter: param.parameter,
                unit: param.unit,
                result: param.result,
                criticalRange: param.criticalRange,
                criticalRangeMin: param.criticalRangeMin,
                criticalRangeMax: param.criticalRangeMax,
                checked: true
              })
            }
          }
        })
      }
      console.log("criticalValues:", criticalValues)
      return criticalValues
    },
    async submitAudit(payload) {
      try {
        const authStore = useAuthStore()
        const response = await authStore.sampleUpdate(payload)
        if (response.code === 200) {
          if (response.data[0].applyStatus === 'publish') {
            ElMessage.success('审核成功')
            console.log(response.data.applyStatus)
            this.selectedSample.applyStatus = 'publish'
            const index = this.samples.findIndex((sample) => sample.id === this.selectedSample.id)
            if (index !== -1) {
              this.samples[index].applyStatus = 'publish'
              this.currentRow = this.samples[index]
            }
          } else {
            ElMessage.success('审核成功')
            // 更新前端状态
            this.selectedSample.applyStatus = 'audited'
            const index = this.samples.findIndex((sample) => sample.id === this.selectedSample.id)
            if (index !== -1) {
              this.samples[index].applyStatus = 'audited'
              this.currentRow = this.samples[index]
            }
          }
          // 你可能还需要重新加载或更新UI的其他部分，视具体情况而定
          //console.log(authStore.userData);
          this.auditorName = authStore.userData.name
        } else {
          ElMessage.error(`审核失败: ${response.msg}`)
        }
      } catch (error) {
        console.error('审核过程中发生错误:', error)
        ElMessage.error('审核失败，请检查网络或联系管理员' + error)
      }
    },
    async handleCancelAudit() {
      if (!this.selectedSample || !this.selectedSample.id) {
        ElMessage.warning('未选中任何样本，请选择一个样本。')
        return
      }
      // 构建请求体
      const payload = {
        samples: [
          {
            id: this.selectedSample.id,
            applyStatus: 'audited' // 确保这是在发起取消审核请求前的必要状态
          }
        ],
        targetStatus: 'saved'
      }

      try {
        const authStore = useAuthStore()
        const response = await authStore.sampleUpdate(payload) // 确保这个API地址和操作是准确的
        if (response.code === 200) {
          ElMessage.success('审核已取消')
          this.selectedSample.applyStatus = 'saved' // 更新本地状态为已保存
          // 更新列表中的样本状态
          const index = this.samples.findIndex((sample) => sample.id === this.selectedSample.id)
          if (index !== -1) {
            this.samples[index].applyStatus = 'saved'
            this.currentRow = this.samples[index]
          }
        } else {
          ElMessage.error(`取消审核失败: ${response.msg}`)
        }
      } catch (error) {
        console.error('取消审核过程中发生错误:', error)
        ElMessage.error('操作失败，请检查网络或联系管理员')
      }
    },

    async handlePublish() {
      if (!this.selectedSample || !this.selectedSample.id) {
        ElMessage.warning('未选中任何样本，请选择一个样本。')
        return
      }
      // 构建请求体
      const payload = {
        samples: [
          {
            id: this.selectedSample.id,
            applyStatus: 'audited' // 确保这是在发起取消审核请求前的必要状态
          }
        ],
        targetStatus: 'publish'
      }

      try {
        const authStore = useAuthStore()
        const response = await authStore.sampleUpdate(payload) // 确保这个API地址和操作是准确的
        if (response.code === 200) {
          ElMessage.success('发布成功')
          this.selectedSample.applyStatus = 'publish' // 更新本地状态为已保存
          // 更新列表中的样本状态
          const index = this.samples.findIndex((sample) => sample.id === this.selectedSample.id)
          if (index !== -1) {
            this.samples[index].applyStatus = 'publish'
            this.currentRow = this.samples[index]
          }
        } else {
          ElMessage.error(`发布失败: ${response.msg}`)
        }
      } catch (error) {
        console.error('发布过程中发生错误:', error)
        ElMessage.error('发布失败，请检查网络或联系管理员')
      }
    },
    async handleRecycleReport() {
      if (!this.selectedSample || !this.selectedSample.id) {
        ElMessage.warning('未选中任何样本，请选择一个样本。')
        return
      }
      // 构建请求体
      const payload = {
        samples: [
          {
            id: this.selectedSample.id,
            // 你可能需要提供当前状态，根据业务逻辑决定是否需要
            applyStatus: 'publish' // 当前状态
          }
        ],
        targetStatus: 'saved' // 目标状态为已保存
      }

      try {
        const authStore = useAuthStore()
        const response = await authStore.sampleUpdate(payload) // 使用 Vuex 或 Pinia 的 authStore 方法
        if (response.code === 200) {
          ElMessage.success('报告已回收')
          this.selectedSample.applyStatus = 'saved' // 更新本地状态为已保存
          // 更新列表中的样本状态
          const index = this.samples.findIndex((sample) => sample.id === this.selectedSample.id)
          if (index !== -1) {
            this.samples[index].applyStatus = 'saved'
            this.currentRow = this.samples[index] // 更新当前行为选中行
          }
        } else {
          ElMessage.error(`回收失败: ${response.msg}`)
        }
      } catch (error) {
        console.error('回收报告过程中发生错误:', error)
        ElMessage.error('回收失败，请检查网络或联系管理员')
      }
    },
    async handlePrint() {
      if (!this.selectedSample || !this.selectedSample.id) {
        ElMessage.warning('未选中任何样本，请选择一个样本。')
        return
      }

      try {
        const authStore = useAuthStore() // 调用store中的方法
        const response = await authStore.samplePrint(this.selectedSample.id) // 发送请求获取PDF
        if (response && response.data) {
          // 创建一个Blob对象存储PDF文件
          const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))

          // 创建一个隐藏的链接元素并触发下载
          const link = document.createElement('a')
          link.href = fileURL
          link.setAttribute('download', this.selectedSample.id + 'sample_report.pdf') // 设置默认下载文件名
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          // 打开新窗口进行打印
          const printWindow = window.open(fileURL)
          if (printWindow) {
            printWindow.onload = function () {
              printWindow.focus()
              printWindow.print() // 调用浏览器的打印功能
            }
          } else {
            ElMessage.error('无法打开打印窗口，请检查弹出窗口是否被阻止。')
          }
        } else {
          ElMessage.error('未获取到PDF文件，请检查网络或联系管理员。')
        }
      } catch (error) {
        console.error('打印过程中发生错误:', error)
        ElMessage.error('打印失败，请检查网络或联系管理员。')
      }
    },

    async fetchAnalyzeResult() {
      if (!this.selectedSample || !this.selectedSample.sampleNum || !this.selectedSample.deviceId) {
        ElMessage.warning('未选中任何样本，请选择一个样本')
        return
      }

      // 构建请求参数
      const params = {
        deviceId: this.selectedSample.deviceId,
        SampleNumber: this.selectedSample.sampleNum
      }

      try {
        const authStore = useAuthStore()
        const response = await authStore.sampleDiPOCAnalyze(params)
        if (response.code === 200) {
          this.analyzeResult = response.data // 将分析结果保存到组件状态中
        } else {
          ElMessage.error(response.msg || '获取分析结果失败')
        }
      } catch (error) {
        console.error('Error fetching analysis result:', error)
        ElMessage.error('分析结果请求失败')
      }
    },
    handleSelectAllChange(value) {
      this.criticalValues.forEach((item) => {
        item.checked = value
      })
    },
    handleRowCheckboxChange(index) {
      this.updateSelectAll()
    },
    updateSelectAll() {
      this.selectAll = this.criticalValues.every((item) => item.checked)
    },
    // 获取排序后的字段配置
    getSortedFields(colIndex) {
      if (!this.settings.patientFieldSettings) return []

      // 按sort排序
      const sortedFields = [...this.settings.patientFieldSettings].sort((a, b) => {
        // 确保sort值为数字进行比较
        return Number(a.sort) - Number(b.sort)
      })

      // 将字段平均分配到4列
      const fieldsPerCol = Math.ceil(sortedFields.length / 4)
      const start = (colIndex - 1) * fieldsPerCol
      const end = start + fieldsPerCol

      return sortedFields.slice(start, end)
    },

    // 处理回车事件
    handleKeyupEnter(column) {
      switch (column) {
        case 'name':
          this.fetchPatient('name')
          break
        case 'inpatNum':
          this.fetchPatient('inpatNum')
          break
        case 'accessionNum':
          this.fetchPatient('accessionNum')
          break
      }
    }
  },

  async mounted() {
    try {
      const authStore = useAuthStore()
      // 1. 先获取用户数据
      this.userData = await authStore.initUserData() || {}
      
      // 2. 获取设置
      this.settings = await authStore.settings || {}

      // 3. 安全地解析 dataPermission
      try {
        if (typeof this.userData.dataPermission === 'string') {
          this.userData.dataPermission = JSON.parse(this.userData.dataPermission)
        } else if (typeof this.userData.dataPermission === 'object') {
          this.userData.dataPermission = this.userData.dataPermission || {}
        } else {
          this.userData.dataPermission = {}
        }
      } catch (error) {
        console.error('解析 dataPermission 失败:', error)
        this.userData.dataPermission = {}
      }

      console.log('初始化用户数据:', this.userData)

      // 4. 按顺序获取医院、科室和设备
      await this.fetchHospitals()
      if (this.hospital) {
        await this.fetchDepartments(this.hospital)
        if (this.department) {
          await this.fetchDevices()
        }
      }
    } catch (error) {
      console.error('初始化过程出错:', error)
      ElMessage.error('页面初始化失败，请刷新重试')
    }
  },
  computed: {
    sortedColumnKeys() {
      // 从设置中获取字段配置，如果没有配置则使用默认值
      const defaultSettings = [
        { field: 'analyzeDate', label: '检测时间', showable: true, sortable: true, sort: 1, width: 120 },
        { field: 'sampleNum', label: '样本号', showable: true, sortable: true, sort: 2, width: 120 },
        { field: 'applyStatus', label: '状态', showable: true, sortable: true, sort: 3, width: 90 }
      ]

      // 合并用户设置与默认设置，用户设置优先
      const settings = this.settings.reportFieldsSettings || defaultSettings
      console.log('settings:', settings)
      // 根据sort属性排序并返回字段名数组
      return settings.sort((a, b) => a.sort - b.sort)
    },
    isBindingStatus() {
      return (
        this.selectedSample.applyStatus === '' ||
        this.selectedSample.applyStatus === 'await' ||
        this.selectedSample.applyStatus === null
      )
    },
    // 计算左侧列表数据
    leftColumnItems() {
      if (!this.inspectionItems || this.inspectionItems.length === 0) return []
      const totalItems = this.inspectionItems.length
      // 优先填充左侧列，左侧列至少显示一半的数据
      const leftColumnCount = Math.ceil(totalItems / 2)
      return this.inspectionItems.slice(0, leftColumnCount)
    },
    // 计算右侧列表数据
    rightColumnItems() {
      if (!this.inspectionItems || this.inspectionItems.length === 0) return []
      const totalItems = this.inspectionItems.length
      const leftColumnCount = Math.ceil(totalItems / 2)
      return this.inspectionItems.slice(leftColumnCount)
    }
  }
}
</script>

<style scoped>
.registration-page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 20px);
  width: calc(100% - 20px);
  background-color: #474747;
  overflow: hidden;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 12px;
}

.header {
  background-color: #474747;
  color: white;
  padding: 10px 20px;
  display: flex;
  flex-wrap: wrap;
  /* 允许内容换行 */
  gap: 10px;
  /* 各元素之间的间距 */
  align-items: flex-start;
  /* 顶部对齐 */
}

.filter-bar {
  display: flex;
  flex-wrap: wrap;
  /* 允许内容换行 */
  gap: 10px;
  /* 设置间距 */
  flex: 1;
  /* 占据剩余空间 */
  min-width: 300px;
  /* 设置最小宽度 */
}

.button-group {
  display: flex;
  gap: 10px;
  flex-shrink: 0;
  /* 防止按钮被压缩 */
  margin-left: auto;
  /* 将按钮组推到右侧 */
  align-self: flex-start;
  /* 顶部对齐 */
}

/* 调整筛选项的样式 */
.filter-bar label {
  font-size: 16px;
  white-space: nowrap;
  /* 防止label换行 */
  align-self: center;
  /* 垂直居中对齐 */
}

.filter-input,
.filter-date {
  width: 150px;
  flex-shrink: 0;
  /* 防止输入框被压缩 */
}

/* 确保按钮样式保持不变 */
.ctrl-button {
  width: 88px;
  flex-shrink: 0;
  /* 防止按钮被压缩 */
}

.content1 {
  border-radius: 12px;
  flex: 1;
  padding: 10px 10px 5px 10px;
  background-color: #f5f6f7;
  overflow: auto;
}

.top {
  padding: 5px;
  padding-bottom: 12px;
  color: #057ab2;
  text-align: left;
  font-weight: bold;
  box-sizing: border-box;
  font-size: 16px;
}

.left-pane {
  background-color: white;
  border-radius: 12px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  height: calc(100vh - 128px); /* 设置固定高度 */
  min-height: 300px; /* 设置最小高度 */
}

/* 确保表格容器也能正确响应高度 */
.left-pane :deep(.el-scrollbar) {
  height: calc(100% - 40px) !important; /* 减去分页器的高度 */
}

.left-pane :deep(.el-scrollbar__wrap) {
  height: 100%;
}

.left-pane :deep(.el-table) {
  height: 100%;
}

:deep(.small-table .el-table__cell) {
  padding: 3px;
  font-size: 12px;
}

.right-pane {
  margin-left: 5px;
  border-radius: 12px;
  position: relative;
  font-size: 12px;
}

.table-container {
  height: 100%;
  box-sizing: border-box;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 0;
  margin-top: auto;
  height: 32px; /* 固定分页器高度 */
}

.total-records {
  margin-left: 10px;
  color: #666;
  font-size: 12px;
}

.action-buttons {
  position: absolute;
  right: 15px;
  bottom: 8px;
  display: flex;
  gap: 10px;
}

.barcode-button {
  margin: 0;
}

/* .form-row {
  margin-bottom: 10px;
} */

.form-row label {
  width: 100px;
  display: inline-block;
  text-align: right;
  margin-right: 5px;
  font-size: 14px;
}

.form-header .el-col {
  height: auto;
}

.form-header .el-input {
  width: 200px;
  font-size: 14px;
  display: inline-block;
  margin-bottom: 5px;
}

:deep(.el-table__inner-wrapper:before) {
  height: 0;
}

.form-header .el-select {
  width: 160px;
  font-size: 12px;
  display: inline-block;
  margin-bottom: 5px;
}

.el-header {
  height: auto;
}

.high {
  color: red;
}

.low {
  color: blue;
}

/* 设置el-tabs的高度和滚动条 */
.el-tabs {
  display: flex;
  flex-direction: column;
}

.el-tabs__content {
  flex: 1;
}

.el-tab-pane {
  height: 100%;
}

.el-row {
  height: 100%;
}

.el-col {
  height: 100%;
}

.el-table {
  height: 100%;
}

.content-area {
  position: relative;
  height: 420px !important;
  background-color: white;
  padding-top: 0px;
  border-radius: 8px;
}

.content-area.higher-tabs {
  height: 580px !important;
}

:deep(.content-area .el-tabs__content) {
  height: 100%;
  overflow-y: hidden;  /* 默认隐藏滚动条 */
}

/* 只在有数据时显示滚动条 */
:deep(.content-area .el-tabs__content:not(:empty)) {
  overflow-y: auto;
}

/* 删除文件末尾的这些重复样式：
:deep(.el-tabs__content) { ... }
:deep(.higher-tabs:not(:empty) .el-tabs__content) { ... }
.content-area { ... }
:deep(.content-area .el-tabs__content) { ... }
*/

.grid-container {
  display: flex;
  min-height: 100px;
  position: relative;
  padding: 5px 5px;
  background-color: #ffffff;
  border-radius: 8px;
  flex-wrap: wrap;
  gap: 5px;
}

.grid-col {
  flex: 1 1 120px;
  display: flex;
  flex-direction: column;
  margin: 0 2px;
  overflow: hidden;
  position: relative;
  min-width: 120px;
  max-width: 160px;
}

.grid-item {
  text-align: left;
  display: flex;
  align-items: center;
  padding: 4px 2px;
  font-size: 14px;
  font-weight: 400;
  min-height: 30px;
  margin: 2px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grid-item span {
  text-align: left;
}

.grid-item label {
  text-align: left;
  margin-right: 5px;
  width: auto;
}

.grid-item .label2 {
  font-size: 12px;
  color: #057ab2;
}

.grid-item .label3 {
  color: #5e6266;
}

.grid-item .label4 {
  font-weight: 500;
  color: #003750;
  background-color: #dddde3;
  padding: 2px;
  border-radius: 4px;
}

.double-height {
  height: 64px;
  /* 设置为正常的两行高度 */
  overflow: hidden;
  text-overflow: ellipsis;
  /* 超过宽度用...表示 */
}

.center-content {
  height: 64px;
  /* 第七列的每一行高度为正常的两行��度 */
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-line {
  width: 1px;
  height: 50%;
  background-color: #ddd;
  /* 竖线颜色 */
  position: absolute;
  right: 0;
  /* 竖线位置调整 */
  top: 50%;
  /* 垂直居中 */
  transform: translateY(-50%);
  /* 垂直居中 */
}

.value {
  white-space: normal;
  /* 允许换行 */
  overflow: hidden;
  text-overflow: ellipsis;
  /* 超过宽度用...表示 */
}

:deep(.grid-container .el-header) {
  padding: 0;
}

/*底部信息*/
.bottom-info {
  position: absolute;
  /* 使用绝对定位 */
  bottom: 3px;
  /* 距离底部10px */
  left: 10px;
  /* 距离左侧10px，可根据需要调整 */
  right: 10px;
  /* 距离右侧10px，可根据需要调整 */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bottom-info .left-info span {
  margin-right: 10px;
}

.right-buttons {
  display: flex;
}

.recycle-reason-container {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  /* 设置间距 */
}

.recycle-reason-container label {
  margin-right: 10px;
}

.recycle-dialog .el-dialog__header {
  font-weight: 700;
}

.recycle-input {
  flex: 1;
}

/* 对于垂直滚动条 */
.el-table__body-wrapper::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 10px;
}

.el-table__body-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.el-table__body-wrapper::-webkit-scrollbar-track {
  background-color: transparent;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.ctrl-button {
  width: 88px;
}

.el-select .el-input__suffix {
  display: flex;
  align-items: center;
}

.el-input__suffix-inner {
  position: relative;
  right: 0;
}

.el-select__caret {
  display: block !important;
}

.row-highlight {
  background-color: #c0ddeb !important;
  /* 选择你喜欢的颜色 */
}

.status-green {
  color: green;
}

.status-orange {
  color: orange;
}

.status-red {
  color: red;
}

.status-blue {
  color: blue;
}

/* 在已有的样式中添加 */

.truncate {
  max-width: 116px;
  /* 设置最大宽度 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: bottom;
}

.truncate-address {
  max-width: 116px;
  /* 地址允许更长的显示长度 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: top;
}

/* 添加鼠标悬停提示的样式 */
.truncate:hover,
.truncate-address:hover {
  cursor: pointer;
}

/* 修改选择器的鼠标指针样式 */
.el-select :deep(.el-input__wrapper) {
  cursor: pointer !important;
}

.el-select :deep(.el-input__inner) {
  cursor: pointer !important;
}

.el-select.is-filterable :deep(.el-input__wrapper),
.el-select.is-filterable :deep(.el-input__inner) {
  cursor: pointer !important;
}

.el-select :deep(.el-select__input) {
  cursor: text !important;
}

/* 设置默认高度和待绑定状态高度 */
:deep(.el-tabs__content) {
  height: 480px !important; /* 默认高度和待绑定状态高度 */
  overflow-y: auto;
  transition: height 0.3s ease;
}

/* 只有在非待绑定状态下才应用更高的高度 */
:deep(.higher-tabs:not(:empty) .el-tabs__content) {
  height: 580px !important; /* 非待绑定状态下的高度 */
}

.content-area {
  position: relative;
  height: auto;
  background-color: white;
  padding-top: 0px;
  border-radius: 8px;
  transition: height 0.3s ease;
}
</style>

<template>
  <div class="device-management-container">
    <div class="device-management-box">
      <!-- 标题部分 -->
      <div class="header">
        <span class="title">设备管理</span>
        <el-input v-model="search" placeholder="设备名称或安装号过滤" class="search-input" clearable>
          <template #prefix>
            <el-icon>
              <Search />
            </el-icon>
          </template>
        </el-input>
        <el-button type="primary" @click="SyncDevice" class="sync-button">同步设备</el-button>
      </div>
      <!-- 设备表格部分 -->
      <el-scrollbar style="height: 100%" always>
        <el-table
          :data="paginatedDevices"
          class="custom-table"
          style="border: none"
          :header-cell-style="{ background: '#F5F6F7' }"
          :header-row-style="{ background: '#F5F6F7' }"
        >
          <el-table-column prop="name" label="设备名称" width="auto" min-width="20%"></el-table-column>
          <el-table-column prop="deptName" label="科室" width="auto" min-width="15%"></el-table-column>
          <el-table-column prop="installNum" label="安装号" width="auto" min-width="15%"></el-table-column>
          <el-table-column prop="typeName" label="类型" width="auto" min-width="10%"></el-table-column>
          <el-table-column label="使用码" width="auto" min-width="10%">
            <template v-slot="scope">
              <el-switch
                v-model="scope.row.useSpecimen"
                :active-value="1"
                :inactive-value="0"
                @change="
                  (value) => {
                    scope.row.useSpecimen = value
                    updateDevice(scope.row)
                  }
                "
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="启用" width="auto" min-width="10%" align="center" header-align="center">
            <template v-slot="scope">
              <el-switch
                v-model="scope.row.status"
                :active-value="1"
                :inactive-value="0"
                @change="
                  (value) => {
                    scope.row.status = value
                    updateDevice(scope.row)
                  }
                "
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="报告模板" width="auto" min-width="10%" align="center" header-align="center">
            <template v-slot="scope">
              <el-select
                v-model="scope.row.printTmplId"
                placeholder="请选择"
                @change="
                  (value) => {
                    scope.row.printTmplId = value
                    updateDevice(scope.row)
                  }
                "
              >
                <el-option
                  v-for="template in templates"
                  :key="template.name"
                  :label="template.name"
                  :value="template.id"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="auto" min-width="10%" align="center" header-align="center">
            <template v-slot="scope">
              <el-button style="border: none; color: #057ab2" class="action-button" @click="editDevice(scope.row)">
                <el-icon size="16">
                  <EditPen />
                </el-icon>
                参数设置
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-scrollbar>
      <!-- 分页部分 -->
      <!--      <div class="pagination-container">-->
      <!--        <el-pagination background layout="prev, pager, next" :total="filteredDevices.length" :page-size="pageSize"-->
      <!--          @current-change="handlePageChange" />-->
      <!--        <div class="pagination-info">-->
      <!--          共 {{ filteredDevices.length }} 条-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <!-- 参数设置抽屉 -->
    <div class="drawer-wrapper">
      <el-drawer
        v-model="drawerVisible"
        direction="btt"
        :wrapperClosable="false"
        :size="getDrawerHeight()"
        :style="getDrawerStyle()"
      >
        <template #header>
          <div class="drawer-header">
            <div class="drawer-title-section">
              <span class="drawer-title">参数设置</span>
              <span style="margin-left: 15px; font-weight: 400; color: #5e6266">设备名称: </span>
              <span style="margin-left: 10px; font-weight: 500; color: #000">{{ currentDevice.name }}</span>
              <span style="margin-left: 15px; font-weight: 400; color: #5e6266">科室: </span>
              <span style="margin-left: 10px; font-weight: 500; color: #000">{{ currentDevice.deptName }}</span>
            </div>
            <div class="drawer-actions">
              <el-button class="action1" @click="editAllParameters">{{
                isAllEditing ? '取消编辑' : '全部编辑'
              }}</el-button>
              <el-button class="action1" @click="syncParameters">同步参数</el-button>
              <el-button :class="{ 'remove-margin-right': showSaveButton }" class="action3" @click="copyParameters"
                >复制参数</el-button
              >
              <el-button
                v-if="isAllEditing || isSyncDrawer || showSaveButton"
                class="action2"
                type="success"
                @click="saveParameters"
                >保存</el-button
              >
            </div>
          </div>
        </template>
        <!-- <div class="drawer-content"> -->
        <el-scrollbar style="height: 100%" always>
          <el-table
            ref="parameterTable"
            :data="paginatedParameters"
            :row-class-name="tableRowClassName"
            style="width: 100%"
            height="1150px"
            :header-cell-style="{ background: '#F5F6F7' }"
          >
            <!-- :height="740" -->
            <el-table-column label="序号" width="60" type="index" :index="indexMethod"> </el-table-column>
            <el-table-column prop="code" label="参数" width="100" show-overflow-tooltip>
              <template v-slot="scope">
                {{ scope.row.code }}
              </template>
            </el-table-column>
            <el-table-column prop="standardParamCode" label="标准参数" width="100" show-overflow-tooltip>
              <template v-slot="scope">
                <div v-if="scope.row.editable">
                  <el-select v-model="scope.row.standardParamCode" placeholder="请选择">
                    <el-option v-for="value in standardValues" :key="value" :label="value" :value="value"></el-option>
                  </el-select>
                </div>
                <div v-else>{{ scope.row.standardParamCode }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="项目名称" show-overflow-tooltip width="120">
              <template v-slot="scope">
                <div v-if="scope.row.editable">
                  <el-input v-model="scope.row.name" />
                </div>
                <div v-else>{{ scope.row.name }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="unit" label="单位" width="80">
              <template v-slot="scope">
                {{ scope.row.unit }}
              </template>
            </el-table-column>
            <el-table-column prop="status" label="启用" width="80">
              <template v-slot="scope">
                <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0" />
              </template>
            </el-table-column>
            <el-table-column prop="type" label="参数类型" width="120">
              <template v-slot="scope">
                <div v-if="scope.row.editable">
                  <el-select v-model="scope.row.type" placeholder="请选择">
                    <el-option v-for="type in parameterTypes" :key="type" :label="type" :value="type"></el-option>
                  </el-select>
                </div>
                <div v-else>{{ scope.row.type }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="calcType" label="计算类型" width="100">
              <template v-slot="scope">
                <div v-if="scope.row.editable">
                  <el-select v-model="scope.row.calcType" placeholder="请选择">
                    <el-option v-for="type in calculationTypes" :key="type" :label="type" :value="type"></el-option>
                  </el-select>
                </div>
                <div v-else>{{ scope.row.calcType }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="payType" label="付费项目" width="120">
              <template v-slot="scope">
                <div v-if="scope.row.editable">
                  <el-select v-model="scope.row.payType" placeholder="请选择">
                    <el-option v-for="item in payType" :key="item" :label="item" :value="item"></el-option>
                  </el-select>
                </div>
                <div v-else>{{ scope.row.payType }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="refRangeMin" label="参考下限" width="120">
              <template v-slot="scope">
                <div v-if="scope.row.editable">
                  <el-input v-model="scope.row.refRangeMin" />
                </div>
                <div v-else>{{ scope.row.refRangeMin }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="refRangeMax" label="参考上限" width="120">
              <template v-slot="scope">
                <div v-if="scope.row.editable">
                  <el-input v-model="scope.row.refRangeMax" />
                </div>
                <div v-else>{{ scope.row.refRangeMax }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="criticalRangeMin" label="危急值下限" width="100">
              <template v-slot="scope">
                <div v-if="scope.row.editable">
                  <el-input v-model="scope.row.criticalRangeMin" />
                </div>
                <div v-else>{{ scope.row.criticalRangeMin }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="criticalRangeMax" label="危急值上限" width="100">
              <template v-slot="scope">
                <div v-if="scope.row.editable">
                  <el-input v-model="scope.row.criticalRangeMax" />
                </div>
                <div v-else>{{ scope.row.criticalRangeMax }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="dotNum" label="显示小数位数" min-width="120">
              <template v-slot="scope">
                <div v-if="scope.row.editable">
                  <el-input-number
                    v-model="scope.row.dotNum"
                    :min="0"
                    :step="1"
                    controls-position="right"
                    style="width: 100px"
                  ></el-input-number>
                </div>
                <div v-else>{{ scope.row.dotNum }}</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="auto" min-width="200" align="center" header-align="center">
              <template v-slot="scope">
                <div v-if="!isAllEditing && scope.row.editable" class="action-buttons">
                  <el-button @click="confirmEditing(scope.row)" class="action-button">
                    <el-icon size="16">
                      <Check />
                    </el-icon>
                    确认
                  </el-button>
                  <el-button @click="cancelEditing(scope.row)" class="action-button">
                    <el-icon size="16">
                      <Close />
                    </el-icon>
                    取消
                  </el-button>
                </div>
                <div v-if="!isAllEditing && !scope.row.editable" class="action-buttons">
                  <el-button @click="startEditing(scope.row)" class="action-button">
                    <el-icon size="16">
                      <EditPen />
                    </el-icon>
                    编辑
                  </el-button>
                  <el-button @click="specialParameters(scope.row)" class="action-button" style="margin-left: 2px">
                    <img src="@/assets/setting.png" width="16" height="16" style="margin-right: 3px" />特殊参数
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>

        <!-- <div class="drawer-pagination-container">
            <el-pagination background layout="prev, pager, next" :total="currentDeviceParameters.length"
              :page-size="drawerPageSize" @current-change="handleDrawerPageChange" />
          </div> -->
        <!-- </div> -->
      </el-drawer>
    </div>

    <!-- 复制参数对话框 -->
    <el-dialog
      title="复制参数"
      v-model="copyDialogVisible"
      width="620px"
      style="border-radius: 8px"
      class="copy-para-content"
    >
      <div class="copy-parameters-header">
        <span>从设备</span>
        <el-select
          v-model="selectedDeviceId"
          placeholder="请选择设备"
          class="dialog-select"
          @change="handleDeviceChange"
        >
          <el-option v-for="device in availableDevices" :key="device.id" :label="device.name" :value="device.id">
          </el-option>
        </el-select>
        <span style="margin-left: 15px">复制到当前 </span
        ><span style="margin-left: 20px">{{ currentDevice.name }} - {{ currentDevice.department }}</span>
      </div>
      <div class="copy-parameters-container">
        <div class="parameters-box">
          <div class="parameters-header">
            <span>源设备参数</span>
          </div>
          <el-table
            ref="sourceTable"
            :data="filteredSourceParameters"
            height="300"
            class="copy-para-table"
            style="width: 100%"
          >
            <el-table-column type="selection" width="26" fixed></el-table-column>
            <el-table-column prop="name" label="参数名称" show-overflow-tooltip min-width="200"> </el-table-column>
          </el-table>
        </div>
        <div class="copy-buttons">
          <el-button class="action1" @click="moveParameters('toSource')">&lt;</el-button>
          <el-button class="action1" @click="moveParameters('toTarget')">&gt;</el-button>
        </div>
        <div class="parameters-box">
          <div class="parameters-header">
            <span>目标设备参数</span>
          </div>
          <el-table ref="targetTable" :data="targetParameters" height="300" class="copy-para-table" style="width: 100%">
            <el-table-column type="selection" width="26" fixed></el-table-column>
            <el-table-column prop="name" label="参数名称" show-overflow-tooltip min-width="200"> </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="copyDialogVisible = false" style="width: 88px">关闭</el-button>
        <el-button type="primary" @click="saveCopiedParameters" style="width: 88px" class="action1">保存</el-button>
      </div>
    </el-dialog>

    <!-- 特殊参数对话框 -->
    <el-dialog
      title="特殊参数"
      v-model="specialParamsDialogVisible"
      width="620px"
      :style="{ borderRadius: '8px', height: '600px' }"
      :header-style="{ background: '#f5f6f7' }"
    >
      <template #header>
        <div class="dialog-title">
          <span>特殊参数</span>
          <el-button type="primary" @click="openSpecialRangeDialog" class="title-button">添加设置</el-button>
        </div>
      </template>
      <el-table
        :data="specialParams"
        class="special-params-table"
        style="width: 100%"
        :header-cell-style="{ background: '#f5f6f7' }"
        :fit="true"
      >
        <el-table-column prop="specialRange" label="范围" width="320"></el-table-column>
        <el-table-column prop="specialRule" label="条件"></el-table-column>
        <el-table-column label="操作" align="center" header-align="center">
          <template v-slot="scope">
            <el-popover placement="left" width="200" v-model:visible="scope.row.confirmDeleteVisible" trigger="manual">
              <p>
                <el-icon size="16" color="red"> <Warning /> </el-icon>确定要删除吗？
              </p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="scope.row.confirmDeleteVisible = false">取消</el-button>
                <el-button type="primary" size="mini" @click="confirmDelete(scope.row)">确定</el-button>
              </div>
              <template #reference>
                <el-button @click="removeSpecialParam(scope.row)" type="text">
                  <el-icon size="16" color="black">
                    <Close />
                  </el-icon>
                </el-button>
              </template>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <!-- 特殊范围设置对话框 -->
    <el-dialog
      title="特殊范围设置"
      v-model="specialRangeDialogVisible"
      width="740px"
      :style="{ borderRadius: '8px', height: '380px' }"
      :header-style="{ background: '#f5f6f7', fontWeight: '700' }"
    >
      <div class="range-setting-content">
        <div class="range-setting-section">
          <div class="section-header">
            <h3>范围</h3>
            <el-button type="text" @click="addRange">
              <el-icon size="16" color="black">
                <Plus />
              </el-icon>
            </el-button>
          </div>
          <el-scrollbar height="160px">
            <div v-for="(range, index) in ranges" :key="index" class="range-row">
              <el-select v-model="range.field" placeholder="请选择范围">
                <el-option label="参考下限" value="refRangeMin"></el-option>
                <el-option label="参考上限" value="refRangeMax"></el-option>
                <el-option label="危急值下限" value="criticalRangeMin"></el-option>
                <el-option label="危急值上限" value="criticalRangeMax"></el-option> </el-select
              ><span style="margin-left: 2px; margin-right: 2px">=</span>
              <el-input v-model="range.value" placeholder="值"></el-input>
              <el-button type="text" @click="removeRange(index)">
                <el-icon size="16" color="black">
                  <Minus />
                </el-icon>
              </el-button>
            </div>
          </el-scrollbar>
        </div>
        <div class="rule-setting-section">
          <div class="section-header">
            <h3>规则</h3>
            <el-button type="text" @click="addRule">
              <el-icon size="16" color="black">
                <Plus />
              </el-icon>
            </el-button>
          </div>
          <el-scrollbar height="160px">
            <div v-for="(rule, index) in rules" :key="index" class="rule-row">
              <el-select v-if="index > 0" v-model="rule.conjunction" placeholder="请选择" disabled>
                <el-option label="且" value="&&"></el-option>
              </el-select>
              <el-select v-model="rule.field" placeholder="请选择" @change="handleFieldChange(index)">
                <el-option label="性别" value="sex_condition"></el-option>
                <el-option label="年龄" value="age_condition"></el-option>
              </el-select>
              <el-select v-model="rule.operator" placeholder="请选择">
                <el-option v-if="rule.field === 'sex_condition'" label="=" value="="></el-option>
                <el-option v-if="rule.field === 'age_condition'" label="=" value="="></el-option>
                <el-option v-if="rule.field === 'age_condition'" label="≥" value="≥"></el-option>
                <el-option v-if="rule.field === 'age_condition'" label="≤" value="≤"></el-option>
                <el-option v-if="rule.field === 'age_condition'" label=">" value=">"></el-option>
                <el-option v-if="rule.field === 'age_condition'" label="<" value="<"></el-option>
                <el-option v-if="rule.field === 'age_condition'" label="≠" value="≠"></el-option>
              </el-select>
              <el-select v-if="rule.field === 'sex_condition'" v-model="rule.value" placeholder="请选择性别">
                <el-option label="男" value="man"></el-option>
                <el-option label="女" value="woman"></el-option>
              </el-select>
              <el-input v-else v-model="rule.value" placeholder="值"></el-input>
              <el-button type="text" @click="removeRule(index)">
                <el-icon size="16" color="black">
                  <Minus />
                </el-icon>
              </el-button>
            </div>
          </el-scrollbar>
        </div>
      </div>
      <div slot="footer" class="dialog-footer1">
        <el-button @click="closeSpecialRangeDialog">关闭</el-button>
        <el-button type="primary" @click="saveSpecialRangeSetting" class="action1">保存</el-button>
      </div>
    </el-dialog>
    <el-dialog title="设备同步确认" v-model="syncDialogVisible" width="720px" style="border-radius: 8px">
      <el-table :data="newDevices" style="width: 100%">
        <el-table-column prop="name" label="设备名称" width="auto" min-width="20%"></el-table-column>
        <el-table-column prop="deptName" label="科室" width="auto" min-width="20%"></el-table-column>
        <el-table-column prop="typeName" width="auto" min-width="20%" label="类型"></el-table-column>
        <el-table-column prop="installNum" label="安装号" width="auto" min-width="30%"></el-table-column>
        <el-table-column label="状态" width="auto" min-width="10%">
          <template v-slot="scope">
            <span style="color: green">新增</span>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="syncDialogVisible = false" style="margin-top: 10px; width: 88px">关闭</el-button>
        <el-button type="primary" @click="saveNewDevices" style="margin-top: 10px; width: 88px">保存</el-button>
      </span>
    </el-dialog>
    <!-- 确认删除弹框 -->
    <el-dialog title="确认删除" v-model="confirmDeleteDialogVisible" width="30%">
      <span>确要删除这条特殊参数吗？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="confirmDeleteDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="confirmDelete">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { Search, EditPen, Check, Close, Plus, Minus, Delete, Warning } from '@element-plus/icons-vue'
import { useAuthStore } from '@/stores/authStore'
import { h } from 'vue'

export default {
  components: {
    Search,
    EditPen,
    Check,
    Close,
    Plus,
    Minus,
    Delete,
    Warning
  },
  props: {
    isAsideCollapsed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      syncDialogVisible: false,
      newDevices: [],
      search: '',
      devices: [],
      templates: [],
      drawerVisible: false,
      currentDevice: {},
      isSyncDrawer: false,
      currentDeviceParameters: [],
      standardValues: ['标准值1', '标准值2', '标准值3'],
      parameterTypes: ['类型1', '类型2', '类型3'],
      calculationTypes: ['计算类型1', '计算类型2', '计算类型3'],
      payType: ['付费项目1', '付费项目2', '付费项目3'],
      currentPage: 1,
      pageSize: 200,
      drawerPageSize: 108,
      drawerCurrentPage: 1,
      editingRow: null,
      isAllEditing: false,
      originalParameters: [],
      copyDialogVisible: false,
      selectedDeviceId: null,
      selectedDevice: null,
      sourceDeviceSearch: '',
      filterByName: false,
      filterByNameTarget: false,
      sourceParameters: [
        { name: 'CKMB [肌酸激酶同工酶]' },
        { name: 'CRP [C反应蛋白]' },
        { name: 'D-dimer [D-二体测定]' },
        { name: 'Myo [肌红蛋白]' }
      ],
      targetParameters: [],
      specialParamsDialogVisible: false,
      specialRangeDialogVisible: false,
      specialParams: [],
      ranges: [],
      rules: [],
      showSaveButton: false,
      confirmDeleteDialogVisible: false,
      deletingSpecialParamId: null,
      availableDevices: []
    }
  },
  computed: {
    // 过滤设备数据
    filteredDevices() {
      if (this.search) {
        return this.devices.filter(
          (device) => device.name.includes(this.search) || device.installNumber.includes(this.search)
        )
      }
      return this.devices
    },
    // 分页显示设备数据
    paginatedDevices() {
      const start = (this.currentPage - 1) * this.pageSize
      const end = this.currentPage * this.pageSize
      return this.filteredDevices.slice(start, end)
    },
    // 分页显示参数数据
    paginatedParameters() {
      const start = (this.drawerCurrentPage - 1) * this.drawerPageSize
      const end = this.drawerCurrentPage * this.drawerPageSize
      return this.currentDeviceParameters.slice(start, end)
    },
    // 过滤备参数数据
    filteredSourceParameters() {
      if (this.filterByName) {
        return this.sourceParameters.filter((param) => param.name.includes(this.sourceDeviceSearch))
      }
      return this.sourceParameters
    }
  },
  watch: {
    // 监听侧边栏折叠状态变化，更新抽屉宽度
    isAsideCollapsed() {
      this.updateDrawerWidth()
    }
  },
  methods: {
    // 获取抽屉宽度和高度
    getDrawerStyle() {
      return {
        width: this.getDrawerWidth(),
        marginTop: '70px',
        borderRadius: '12px',
        position: 'absolute',
        right: '20px'
      }
    },
    // 获取抽屉宽度
    getDrawerWidth() {
      return this.isAsideCollapsed ? 'calc(100% - 88px)' : 'calc(100% - 220px)'
    },
    getDrawerHeight() {
      return 'calc(100% - 70px)'
    },
    // 打开同步设置抽屉
    openSyncDrawer() {
      this.isSyncDrawer = true
      this.drawerVisible = true
      this.showSaveButton = true
    },
    async SyncDevice() {
      // 此处同步设备
      try {
        await this.fetchDeviceList()
        const authStore = useAuthStore()
        // 再获取同步设备列表
        const syncResponse = await authStore.fetchDeviceList()
        if (syncResponse.code === 200) {
          this.newDevices = syncResponse.data.filter(
            (syncDevice) => !this.devices.some((existingDevice) => existingDevice.id === syncDevice.id)
          )

          if (this.newDevices.length > 0) {
            this.syncDialogVisible = true
          } else {
            this.$message.success('没有设备需要同步')
          }
        }
      } catch (error) {
        console.error('Failed to sync device list:', error)
      }
    },
    async saveNewDevices() {
      try {
        const authStore = useAuthStore()
        const response = await authStore.addDevice(this.newDevices) // Call the new addDevices method
        if (response.code === 200) {
          this.$message.success('新增设备保存成功')
          this.syncDialogVisible = false // 关闭弹窗
          this.newDevices = [] // 清空新增设备列表
          await this.fetchDeviceList() // 重新加载设备列表
        } else {
          this.$message.error('保存设备失败')
        }
      } catch (error) {
        console.error('Failed to save new devices:', error)
        this.$message.error('保存设备失败')
      }
    },
    async fetchDeviceList() {
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.1)',
        target: '.device-management-container'
      })

      try {
        const authStore = useAuthStore()
        const deviceListResponse = await authStore.devicelist()
        if (deviceListResponse.code === 200) {
          this.devices = deviceListResponse.data
        } else {
          this.$message.error('获取设备列表失败')
        }
      } catch (error) {
        console.error('Failed to fetch device list:', error)
        this.$message.error('获取设备列表失败')
      } finally {
        loading.close()
      }
    },

    async updateDevice(device) {
      const authStore = useAuthStore()
      try {
        const response = await authStore.updateDevice(device.id, {
          id: device.id,
          code: device.code,
          name: device.name,
          deptId: device.deptId,
          printTmplId: device.printTmplId,
          installNum: device.installNum,
          status: device.status,
          useSpecimen: device.useSpecimen,
          ipAddress: device.ipAddress,
          typeId: device.typeId,
          typeName: device.typeName
        })
        if (response.code === 200) {
          this.$message.success('设备信息更新成功')
        } else {
          this.$message.error('设备信息更新失败')
        }
      } catch (error) {
        console.error('Failed to update device:', error)
        this.$message.error('设备信息更新失败')
      }
    },
    async fetchTemplates() {
      const authStore = useAuthStore()
      try {
        const response = await authStore.getTemplateList()
        if (response.code === 200) {
          this.templates = response.data.data
        }
      } catch (error) {
        console.error('Failed to fetch templates:', error)
      }
    },
    // 参数设置
    async editDevice(device) {
      const loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.1)',
        target: '.device-management-container'
      })

      try {
        this.currentDeviceParameters = []
        this.currentDevice = device
        this.isSyncDrawer = false
        this.drawerVisible = true
        this.showSaveButton = false
        this.updateDrawerWidth()

        const authStore = useAuthStore()
        const parameters = await authStore.getDeviceParameters(device.id)
        this.currentDeviceParameters = parameters.data
      } catch (error) {
        console.error('Failed to fetch device parameters:', error)
        this.$message.error('获取设备参数失败')
      } finally {
        loading.close()
      }
    },
    // 开始编辑某行参数
    startEditing(row) {
      if (!row.original) {
        row.original = JSON.parse(JSON.stringify(row))
      }
      this.editingRow = row
      row.editable = true
    },
    // 确认编辑某行参数
    async confirmEditing(row) {
      this.editingRow = null
      row.editable = false
      row.original = null
      const authStore = useAuthStore()
      try {
        console.log('row:', row)
        const response = await authStore.saveDeviceParameters(this.currentDevice.id, row.id, {
          id: row.id,
          code: row.code,
          standardParamCode: row.standardParamCode,
          name: row.name,
          unit: row.unit,
          value: row.value,
          sampleId: row.sampleId,
          deviceId: row.deviceId,
          sort: row.sort,
          status: row.status,
          type: row.type,
          calcType: row.calcType,
          payType: row.payType,
          refRangeMin: row.refRangeMin,
          refRangeMax: row.refRangeMax,
          criticalRangeMin: row.criticalRangeMin,
          criticalRangeMax: row.criticalRangeMax,
          dotNum: row.dotNum
        })
        if (response.code === 200) {
          this.$message.success('设备参数保存成功')
        } else {
          this.$message.error('设备参数保存失败')
        }
      } catch (error) {
        console.error('Failed to save device parameters:', error)
        this.$message.error('设备参数保存失败')
      }
    },
    // 取消编辑某行参数
    cancelEditing(row) {
      if (row.original) {
        Object.assign(row, row.original)
        row.original = null
      }
      this.editingRow = null
      row.editable = false
      this.showSaveButton = false
    },
    // 处理分页变化
    handlePageChange(page) {
      this.currentPage = page
    },
    // 处理抽屉分页变化
    handleDrawerPageChange(page) {
      this.drawerCurrentPage = page
    },
    // 根据行状态设置行样式
    tableRowClassName({ row, rowIndex }) {
      if (row.isChanged) {
        return 'changed-row'
      }
      if (this.isSyncDrawer && rowIndex === 0) {
        return 'highlight-row'
      }
      if (row.editable) {
        return 'editing-row'
      }
      return ''
    },

    // 更新抽屉宽度
    updateDrawerWidth() {
      this.drawerWidth = this.getDrawerWidth()
    },
    // 全部编辑参数
    editAllParameters() {
      this.isAllEditing = !this.isAllEditing
      if (this.isAllEditing) {
        this.originalParameters = JSON.parse(JSON.stringify(this.currentDeviceParameters))
        this.showSaveButton = true
      } else {
        this.currentDeviceParameters = JSON.parse(JSON.stringify(this.originalParameters))
        this.showSaveButton = false
      }
      this.currentDeviceParameters.forEach((param) => {
        param.editable = this.isAllEditing
      })
    },
    // 同步参数
    syncParameters() {
      console.log('同步参数')
      const authStore = useAuthStore()
      authStore
        .getDeviceExParameters(this.currentDevice.id)
        .then((response) => {
          if (response.code === 200) {
            const newParameters = response.data
            
            // 创建一个Map来跟踪已处理的参数
            const processedParams = new Map()
            
            // 处理现有参数的更新
            this.currentDeviceParameters = this.currentDeviceParameters.map((oldParam) => {
              const newParam = newParameters.find((p) => p.code === oldParam.code)
              processedParams.set(oldParam.code, true)
              
              if (newParam) {
                // 检查是否有变化
                const hasChanges =
                  oldParam.code !== newParam.code || oldParam.name !== newParam.name || oldParam.unit !== newParam.unit

                if (hasChanges) {
                  return {
                    ...oldParam,
                    isChanged: true,
                    // 保存始值用于显示
                    originalValue: {
                      code: oldParam.code,
                      name: oldParam.name,
                      unit: oldParam.unit
                    },
                    // 直接更新主字段
                    code: newParam.code,
                    name: newParam.name,
                    unit: newParam.unit
                  }
                }
              }
              return oldParam
            })

            // 添加新参数
            const newAddedParams = newParameters.filter((param) => !processedParams.has(param.code))
            if (newAddedParams.length > 0) {
              // 将新参数添加到列表中，并标记为新增
              this.currentDeviceParameters.push(
                ...newAddedParams.map((param) => ({
                  ...param,
                  isNew: true, // 标记为新增
                  isChanged: true
                }))
              )
            }

            this.$nextTick(() => {
              const columns = this.$refs.parameterTable.columns
              ;['code', 'name', 'unit'].forEach((field) => {
                const column = columns.find((col) => col.property === field)
                if (column) {
                  column.renderCell = (context) => {
                    const { row } = context || {}
                    if (row && row.isNew) {
                      // 新增参数的显示样式
                      return h(
                        'div',
                        {
                          style: {
                            //backgroundColor: '#F0F9EB',
                            color: '#057AB2'
                          }
                        },
                        row[field]
                      )
                    } else if (row && row.isChanged && row.originalValue && row.originalValue[field] !== row[field]) {
                      // 修改参数的显示样式
                      return h(
                        'div',
                        {
                          style: {
                            backgroundColor: '#FEF9ED'
                          }
                        },
                        [
                          h(
                            'span',
                            {
                              style: {
                                textDecoration: 'line-through',
                                color: '#999',
                                marginRight: '8px'
                              }
                            },
                            row.originalValue[field]
                          ),
                          h(
                            'span',
                            {
                              style: {
                                color: '#057ab2'
                              }
                            },
                            row[field]
                          )
                        ]
                      )
                    }
                    return h('span', row ? row[field] : '')
                  }
                }
              })
            })

            this.showSaveButton = true
            this.isSyncDrawer = true
          } else {
            this.$message.error(response.message || response.msg || '获取设备参数失败')
          }
        })
        .catch((error) => {
          console.error('Failed to fetch device ex parameters:', error)
          this.$message.error('获取设备参数失败')
        })
    },
    // 复制参数
    async copyParameters() {
      // 重置数据
      this.sourceParameters = []
      this.targetParameters = []
      this.selectedDeviceId = null
      this.selectedDevice = null

      // 设置可用设备列表
      this.availableDevices = this.devices.filter((device) => device.id !== this.currentDevice.id)

      // 打开对话框
      this.copyDialogVisible = true

      // 默认选择第一个设备
      if (this.availableDevices.length > 0) {
        this.selectedDeviceId = this.availableDevices[0].id
        this.selectedDevice = this.availableDevices[0]
        await this.fetchDeviceParameters(this.availableDevices[0])
      }
    },
    // 处理设备选择变化
    async handleDeviceChange(deviceId) {
      if (deviceId) {
        const selectedDevice = this.availableDevices.find((d) => d.id === deviceId)
        if (selectedDevice) {
          this.selectedDevice = selectedDevice
          await this.fetchDeviceParameters(selectedDevice)
        }
      } else {
        this.selectedDevice = null
        this.sourceParameters = []
      }
    },
    // 获取设备参数方法保持不变
    async fetchDeviceParameters(device) {
      try {
        const authStore = useAuthStore()
        const response = await authStore.getDeviceParameters(device.id)
        if (response.status === 500) {
          this.$message.error('获取设备参数失败')
          return
        }
        if (response.code === 200) {
          this.sourceParameters = response.data.map((param) => ({
            id: param.id,
            name: `${param.code} [${param.name}]`,
            code: param.code,
            unit: param.unit,
            status: param.status,
            type: param.type,
            calcType: param.calcType,
            payType: param.payType,
            refRangeMin: param.refRangeMin,
            refRangeMax: param.refRangeMax,
            criticalRangeMin: param.criticalRangeMin,
            criticalRangeMax: param.criticalRangeMax,
            dotNum: param.dotNum
          }))
        }
      } catch (error) {
        console.error('获取设备参数失败:', error)
        this.$message.error('获取设备参数失败')
      }
    },
    // 保存参数
    async saveParameters() {
      const authStore = useAuthStore()
      console.log('this.syncDrawer', this.isSyncDrawer)
      try {
        if (this.isSyncDrawer) {
          // 同步设备逻辑
          const response = await authStore.saveDeviceParameter(this.currentDevice.id, this.currentDeviceParameters)
          if (response.code === 200) {
            this.$message.success('同步参数成功')
          } else {
            throw new Error(response.message || response.msg || '同步参数失败')
          }
        } else {
          // 全部编辑保存逻辑
          const response = await authStore.saveDeviceParameters(
            this.currentDevice.id,
            null,
            this.currentDeviceParameters
          )
          if (response.code === 200) {
            this.$message.success('参数保存成功')
          } else {
            throw new Error(response.message || response.msg || '参数保存失败')
          }
        }

        // 重置状态
        this.isAllEditing = false
        this.showSaveButton = false
        this.isSyncDrawer = false
        this.currentDeviceParameters.forEach((param) => {
          param.editable = false
          param.original = null
          param.isChanged = false // 清除修改标记
        })

        // 重新获取最新数据
        await authStore.getDeviceParameters(this.currentDevice.id)
      } catch (error) {
        console.error('保存参数失败:', error)
        this.$message.error(error.message || '保存参数失败')
      }
    },
    // 移动参数
    moveParameters(direction) {
      if (direction === 'toTarget') {
        const sourceSelections = this.$refs.sourceTable.getSelectionRows()
        this.targetParameters.push(...sourceSelections)
        this.sourceParameters = this.sourceParameters.filter((param) => !sourceSelections.includes(param))

        // 在下一个事件循环中设置全选
        this.$nextTick(() => {
          // 为新添加的每个参数设置选中状态
          sourceSelections.forEach((param) => {
            this.$refs.targetTable.toggleRowSelection(param, true)
          })
        })
      } else {
        const targetSelections = this.$refs.targetTable.getSelectionRows()
        this.sourceParameters.push(...targetSelections)
        this.targetParameters = this.targetParameters.filter((param) => !targetSelections.includes(param))
      }
    },
    // 保存复制的参数
    async saveCopiedParameters() {
      try {
        if (!this.selectedDevice) {
          this.$message.error('请选择源设备')
          return
        }

        const authStore = useAuthStore()
        const requestData = {
          [this.selectedDevice.id]: this.targetParameters.map((param) => param.id)
        }

        const response = await authStore.copyDeviceParameters(this.currentDevice.id, requestData)

        if (response.code === 200) {
          this.$message.success('参数复制成功')
          this.copyDialogVisible = false
          await this.editDevice(this.currentDevice)
        } else {
          this.$message.error(response.msg || '参数复制失败')
        }
      } catch (error) {
        console.error('复制参数失败:', error)
        this.$message.error('复制参数失败')
      }
    },
    // 打开特殊参数对话框
    specialParameters(row) {
      this.editingRow = row
      this.fetchSpecialParameters(this.currentDevice.id, row.id)
      this.specialParamsDialogVisible = true
    },
    // 获取特殊参数
    async fetchSpecialParameters(deviceId, paramId) {
      const authStore = useAuthStore()
      try {
        const response = await authStore.getSpecialParameters(deviceId, paramId)
        if (response.code === 200) {
          this.specialParams = response.data.map((item) => {
            const range = item.listRangeMap
              .map((range) => {
                const keyMap = {
                  refRangeMin: '参考下限',
                  refRangeMax: '参考上限',
                  criticalRangeMin: '危急值下限',
                  criticalRangeMax: '危急值上限'
                }
                return `${keyMap[range.key]}=${range.value}`
              })
              .join(' 且 ')

            const condition = item.listRuleMap
              .map((rule) => {
                const keyMap = {
                  sex_condition: '性别',
                  age_condition: '年龄'
                }
                const opMap = {
                  '=': '=',
                  '≥': '≥',
                  '≤': '≤',
                  '>': '>',
                  '<': '<',
                  '≠': '≠'
                }
                const valueMap = {
                  man: '男',
                  woman: '女'
                }
                return `${keyMap[rule.key]}${opMap[rule.op]}${valueMap[rule.value] || rule.value}`
              })
              .join(' 且 ')

            // 获取回的所有字段
            return {
              id: item.id,
              paramId: item.paramId,
              deviceId: item.deviceId,
              specialRange: range,
              specialRule: condition,
              status: item.status,
              updateEmplId: item.updateEmplId,
              createEmplId: item.createEmplId,
              createTime: item.createTime,
              updateTime: item.updateTime,
              confirmDeleteVisible: false // 新增确认删除弹框的可见性
            }
          })
          console.log('11111', this.specialParams)
          this.specialParamsDialogVisible = true
        } else {
          this.$message.error('获取特殊参数失败')
        }
      } catch (error) {
        console.error('Failed to fetch special parameters:', error)
        this.$message.error('获取特殊参数失败')
      }
    },
    // 添加特殊参数
    openSpecialRangeDialog() {
      this.ranges = [
        { field: 'refRangeMin', value: '' },
        { field: 'refRangeMax', value: '' }
      ]
      this.rules = [
        { field: 'sex_condition', operator: '=', value: '', conjunction: '' },
        { field: 'age_condition', operator: '≥', value: '', conjunction: '&&' }
      ]
      this.specialRangeDialogVisible = true
    },
    closeSpecialRangeDialog() {
      this.specialRangeDialogVisible = false
    },
    addSpecialParam() {
      this.specialParams.push({
        range: '',
        condition: ''
      })
    },
    addRange() {
      this.ranges.push({ field: '', value: '' })
    },
    removeRange(index) {
      this.ranges.splice(index, 1)
    },
    addRule() {
      this.rules.push({ field: '', operator: '', value: '', conjunction: '&&' })
    },
    removeRule(index) {
      this.rules.splice(index, 1)
    },
    handleFieldChange(index) {
      if (this.rules[index].field === 'sex_condition') {
        this.rules[index].operator = '='
      } else if (this.rules[index].field === 'age_condition') {
        this.rules[index].operator = '≥' // 默认选择大于等于
      }
    },
    async saveSpecialRangeSetting() {
      // 检查所有值是否不为空
      const isRangeValid = this.ranges.every((range) => range.field && range.value)
      const isRuleValid = this.rules.every((rule) => rule.field && rule.operator && rule.value)

      if (!isRangeValid || !isRuleValid) {
        this.$message.error('所有字段必须填写完整')
        return
      }

      // 检查是否存在重复的范围和规则
      const rangeFields = this.ranges.map((range) => range.field)
      const ruleFields = this.rules.map((rule) => rule.field)

      if (new Set(rangeFields).size !== rangeFields.length) {
        this.$message.error('范围字段不能重复')
        return
      }

      if (new Set(ruleFields).size !== ruleFields.length) {
        this.$message.error('规则字段不能重复')
        return
      }

      const params = {
        listRangeMap: this.ranges.map((range) => ({
          logic: '&&',
          key: range.field,
          op: '=',
          value: range.value
        })),
        listRuleMap: this.rules.map((rule) => ({
          logic: '&&',
          key: rule.field,
          op: rule.operator,
          value: rule.value
        }))
      }

      try {
        const authStore = useAuthStore()
        const response = await authStore.saveSpecialParameters(this.currentDevice.id, this.editingRow.id, params)
        if (response.code === 200) {
          this.$message.success('特殊参数保存成功')
          this.closeSpecialRangeDialog()
          await this.fetchSpecialParameters(this.currentDevice.id, this.editingRow.id) // 刷新列表
        } else {
          this.$message.error('特殊参数保存失败')
        }
      } catch (error) {
        console.error('Failed to save special parameters:', error)
        this.$message.error('特殊参数保存失败')
      }
    },
    async removeSpecialParam(row) {
      row.confirmDeleteVisible = true
    },
    async confirmDelete(row) {
      try {
        const authStore = useAuthStore()
        const response = await authStore.deleteSpecialParameter(row.id)
        if (response.code === 200) {
          this.$message.success('特殊参数删除成功')
          row.confirmDeleteVisible = false
          // 刷新列表
          await this.fetchSpecialParameters(this.currentDevice.id, this.editingRow.id)
        } else {
          this.$message.error('特殊参数删除失败')
        }
      } catch (error) {
        console.error('Failed to delete special parameter:', error)
        this.$message.error('特殊参数删除失败')
      }
    },
    indexMethod(index) {
      // 如果使用了分页，需要考虑当前页码
      return index + 1
    }
  },
  async mounted() {
    await this.fetchTemplates()
    await this.fetchDeviceList()
  }
}
</script>

<style scoped>
/* 样式 */
* {
  box-sizing: border-box;
}

.device-management-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #474747;
  min-height: 100vh;
  font-size: 16px;
  line-height: 20px;
}

.device-management-box {
  width: calc(100% - 20px);
  height: calc(100vh - 40px);
  background-color: #f0f3f5;
  border-radius: 12px;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  position: relative;
  margin: 20px 20px 20px 0;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.title {
  color: #057ab2;
  font-size: 16px;
  font-weight: 500;
  margin-right: auto;
}

.search-input {
  width: 300px;
  margin-right: 20px;
}

.sync-button {
  margin-left: 20px;
  background-color: #057ab2;
  border-color: #057ab2;
}

.sync-button:hover {
  background-color: #005f8a;
  border-color: #005f8a;
}

.drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.drawer-title-section {
  display: flex;
  align-items: center;
}

.drawer-title {
  color: #057ab2;
}

.drawer-actions {
  display: flex;
}

.drawer-actions .action1 {
  background-color: #057ab2;
  border-color: #057ab2;
  color: white;
}

.drawer-actions .action1:hover {
  background-color: #045a8d;
  border-color: #045a8d;
}

.drawer-actions .action3 {
  background-color: #057ab2;
  border-color: #057ab2;
  color: white;
  margin-right: 40px;
}

.drawer-actions .action3:hover {
  background-color: #045a8d;
  border-color: #045a8d;
}

.drawer-actions .remove-margin-right {
  margin-right: 0 !important;
}

.drawer-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 10px 20px;
  border-top: 1px solid #ebeef5;
  background-color: #fff;
}

.table-container {
  overflow: auto;
  position: relative;
  height: calc(100vh - 105px);
}

.pagination-container {
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.pagination {
  display: flex;
  align-items: center;
}

.pagination-info {
  text-align: center;
  margin-left: 10px;
}

.custom-table {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  width: 100%;
}

.drawer-pagination-container {
  text-align: center;
  margin-top: 10px;
}

.action-buttons .action-button {
  border: none;
  color: #057ab2;
  padding: 5px 10px;
  background-color: transparent;
}

.action-button:hover {
  background-color: rgba(5, 122, 178, 0.25);
  border: 1px solid rgba(5, 122, 178, 0.25);
  color: #057ab2;
}

:deep(.highlight-row) {
  background-color: #fff8dc !important;
}

:deep(.editing-row) {
  background-color: #c0ddeb !important;
}
.drawer-content {
  display: flex;
  flex-direction: column;
  background-color: '#057ab2';
  align-items: center;
}
:deep(.el-drawer__body) {
  overflow: hidden;
}

.drawer .drawer-content .el-input-number {
  width: 100px;
}

:deep(.el-table--enable-row-hover .el-table__body tr:hover > td) {
  background-color: rgba(0, 0, 0, 0) !important;
}

.copy-parameters-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  margin-left: 10px;
}

.copy-parameters-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.parameters-box {
  display: flex;
  flex-direction: column;
  width: 40%;
  border-radius: 8px;
  border: #c2c3c4 1px solid;
  overflow: hidden;
}

.parameters-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 5px;
  border-bottom: #c2c3c4 1px solid;
  background-color: #f0f3f5;
}

.copy-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}

.dialog-select {
  width: 184px;
  margin-left: 4px;
}

.copy-para-content {
  border-radius: 8px;
}

:deep(.copy-para-content .el-table td.el-table__cell),
:deep(.copy-para-content .el-table th.el-table__cell.is-leaf) {
  border-bottom: none;
}

:deep(.copy-para-content .el-table__inner-wrapper:before) {
  height: 0;
}

.copy-para-table {
  font-size: 12px;
  color: #5e6266;
}

:deep(.parameters-box .el-table .el-table__cell) {
  padding: 0px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.dialog-footer .action1,
.copy-buttons .action1 {
  background-color: #057ab2;
  border-color: #057ab2;
  color: white;
}

.dialog-footer .action1:hover,
.copy-buttons .action1:hover {
  background-color: #045a8d;
  border-color: #045a8d;
}

/* 特殊参数对话框样式 */
.dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.title-button {
  margin-left: auto;
  background-color: #057ab2;
  border-color: #057ab2;
  color: white;
  width: 88px;
}

.title-button:hover {
  background-color: #045a8d;
  border-color: #045a8d;
}

.special-params-table {
  width: 100%;
  border: none;
}

.el-dialog {
  border-radius: 8px;
}

.el-dialog__header {
  background-color: #f5f6f7;
}

.el-table__header-wrapper th {
  background-color: #f5f6f7;
}

/*特殊范围设置*/
.range-setting-content {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
}

.range-setting-section {
  width: 340px;
  height: 240px;
  background-color: #f0f3f5;
  border-radius: 8px;
  padding: 10px;
}

.rule-setting-section {
  width: 344px;
  height: 240px;
  background-color: #f0f3f5;
  border-radius: 8px;
  padding: 10px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.range-row,
.rule-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.el-button[type='text'] {
  margin-left: 10px;
}

.dialog-footer1 {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
  background-color: white;
  position: absolute;
  bottom: 10px;
  right: 20px;
  left: 20px;
}

.dialog-footer1 .el-button {
  width: 88px;
}

.dialog-footer1 .action1 {
  background-color: #057ab2;
  border-color: #057ab2;
  color: white;
}

.dialog-footer1 .action1:hover {
  background-color: #045a8d;
  border-color: #045a8d;
}

.action2 {
  width: 88px;
  margin-right: 40px;
}

:deep(.el-drawer.btt) {
  left: auto;
  right: 0;
}
:deep(.el-table__header-wrapper) {
  background-color: #f5f6f7;
  position: sticky;
  top: 0;
  z-index: 1;
}

:deep(.custom-loading) {
  .el-loading-spinner {
    width: 30px;
    height: 30px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .el-loading-spinner .circular {
    width: 30px;
    height: 30px;
    animation: loading-rotate 2s linear infinite;
  }

  .el-loading-spinner .path {
    stroke: #057ab2;
    stroke-width: 3;
    stroke-linecap: round;
    animation: loading-dash 1.5s ease-in-out infinite;
  }
}

@keyframes loading-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loading-dash {
  0% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -40px;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -120px;
  }
}

.changed-row {
  background-color: #fef9ed !important; /* 设置背景色为淡黄色 */
}

:deep(.highlight-row) {
  background-color: #fff8dc !important;
}

:deep(.editing-row) {
  background-color: #c0ddeb !important;
}

/* 修改有变化行的背景色 */
:deep(.el-table__row.changed-row) {
  background-color: #fef9ed !important;
}

/* 确保鼠标悬停时保持背景色 */
:deep(.el-table--enable-row-hover .el-table__body tr.changed-row:hover > td) {
  background-color: #fef9ed !important;
}

.parameters-box {
  display: flex;
  flex-direction: column;
  width: 40%;
  border-radius: 8px;
  border: #c2c3c4 1px solid;
  overflow: hidden;
}

.copy-para-table {
  font-size: 12px;
  color: #5e6266;
}

:deep(.el-table) {
  overflow-x: auto !important;
}

:deep(.el-table__body-wrapper) {
  overflow-x: auto !important;
}
</style>

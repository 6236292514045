<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App'
};
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};//处理抖动报错问题

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
};//处理抖动报错 问题

</script>
<style>
/* 全局 ElMessage 样式 */
.el-message {
  padding: 15px 15px 15px 20px !important;
  min-width: 380px !important;
}

.el-message__content {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 1.5 !important;
}

.el-message__icon {
  font-size: 18px !important;
  margin-right: 10px !important;
}
</style>

<template>
  <div class="registration-page">
    <div class="header">
      <div class="button-group">
        <el-button @click="show.uploadDocDialog = true" v-if="permission.upload">
          <img src="@/assets/upload.png" slot="icon">
          上传
        </el-button>
        <el-button @click="handleDocDownload" v-if="permission.download">
          <img src="@/assets/download.png" slot="icon">
          下载
        </el-button>
        <span class="button-split">|</span>
        <el-button @click="openDocInvalidDialog" v-if="permission.invalid">
          <img src="@/assets/refuse.png" slot="icon">
          作废
        </el-button>
        <el-button @click="openDocDelDialog" v-if="permission.delete">
          <img src="@/assets/delete.png" slot="icon">
          删除
        </el-button>

        <el-input v-model="keyword" class="search-btn" placeholder="请输入" @keyup.enter="searchDoc(true)">
          <template #prefix>
            <img src="@/assets/search.png" slot="icon">
          </template>
        </el-input>
      </div>
    </div>
    <div class="content">
      <div class="left-pane" @contextmenu.prevent="handleContextMenu($event)">
        <el-tree :data="treeData" @node-contextmenu="handleNodeContextMenu" @node-click="clickMenuNode"
          :default-expand-all="true" class="custom-tree">

          <template #default="{ node }">
            <el-icon v-if="node.expanded">
              <img src="@/assets/folderOpen.png" class="document-menu-icon" />
            </el-icon>
            <el-icon v-else>
              <img src="@/assets/folderClose.png" class="document-menu-icon" />
            </el-icon>
            <small style="margin-left: 12px;">{{ node.label }}</small>
          </template>

        </el-tree>
        <el-menu v-if="show.nodeManagerDialog" class="add-node"
          :style="{ top: menuPosition.y + 'px', left: menuPosition.x + 'px' }">
          <el-menu-item v-if="show.addNodeBtn" @click="openAddNodeDialog">添加节点</el-menu-item>
          <el-menu-item v-if="show.delNodeBtn" @click="openDeleteNodeDialog">删除节点</el-menu-item>
        </el-menu>
      </div>

      <div class="right-pane">
        <div class="table-container">
          <div class="table-list">
            <el-table :data="docList" @row-click="clickDocItem" @select="selectDocItem" @select-all="selectDocItem">
              <el-table-column prop="id" type="selection" label="选择" width="80" align="center"></el-table-column>
              <el-table-column prop="file_name" label="文档名称" width="auto" min-width="50%"></el-table-column>
              <el-table-column prop="create_time" label="上传日期" :formatter="formatDate" width="120"></el-table-column>
              <el-table-column prop="file_valid_time" label="有效日期" :formatter="formatDate" show-overflow-tooltip
                width="120"></el-table-column>
            </el-table>
          </div>

          <div class="pagination">
            <el-pagination layout="prev, pager, next, total" size="small" :total="pagination.total" background
              :page-size="pagination.size" :pager-count="pagination.count" :current-page.sync="pagination.current"
              @current-change="handlePageChange" @size-change="handleSizeChange">
            </el-pagination>
          </div>
        </div>
        <div class="detail-container">
          <div class="detail-header">
            <p>文档详情</p>
          </div>
          <div class="detail-body">
            <el-form label-width="100px" label-position="left" class="detail-form">
              <el-form-item label="文档所有人" class="detail-item">{{ docDetail.owner }}</el-form-item>
              <el-form-item label="上传日期" class="detail-item">{{ docDetail.uploadDate }}</el-form-item>
              <el-form-item label="当前版本" class="detail-item">{{ docDetail.version }}</el-form-item>
              <el-form-item label="文档状态" class="detail-item">
                <span v-if="docDetail.status === '1'" style="color: #56B22B;">有效</span>
                <span v-else-if="docDetail.status === '-1'" style="color: #56B22B;"></span>
                <span v-else>无效</span>
              </el-form-item>
              <el-form-item label="有效日期" class="detail-item">{{ docDetail.expiredDate }}</el-form-item>
              <el-form-item label="文档类型" class="detail-item">{{ docDetail.type }}</el-form-item>
              <el-form-item label="文档大小" class="detail-item">{{ docDetail.size }}</el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <el-dialog v-model="show.addNodeDialog" title="添加节点" width="360" height="400">
    <el-form label-width="84px" label-position="left" class="upload-form">
      <el-form-item label="节点名称:">
        <el-input v-model="addNodeForm.name" style="width: 243px;" placeholder="">
        </el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="closeAddNodeDialog">关闭</el-button>
        <el-button type="primary" @click="handleAddNode">
          保存
        </el-button>
      </div>
    </template>
  </el-dialog>

  <el-dialog v-model="show.uploadDocDialog" title="文档上传" width="360" height="400">
    <el-form label-width="84px" label-position="left" class="upload-form">
      <el-form-item label="选择文件:">
        <el-input v-model="uploadForm.fileName" style="width: 176px;" disabled></el-input>
        <el-button style="width: 60px;margin-left: 8px;background: #057AB2;color: #FFFFFF;" @click="handleSelectFile">
          浏览
          <input type="file" hidden id="file-input" @change="handleFileChange">
        </el-button>
      </el-form-item>
      <el-form-item label="文件有限期:">
        <el-date-picker v-model="uploadForm.expiredDate" type="date" style="width:243px" :clearable="false"
          popper-class="custom-date-picker">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="文件版本:">
        <el-input v-model="uploadForm.version" style="width: 243px;" placeholder="">
        </el-input>
      </el-form-item>
      <el-form-item label="文档类型:">
        <el-input v-model="uploadForm.fileType" style="width: 243px" disabled>
        </el-input>
      </el-form-item>
      <el-form-item label="文档大小:">
        <el-input v-model="uploadForm.fileSize" style="width: 243px" disabled>
        </el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="closeUploadDialog">关闭</el-button>
        <el-button type="primary" @click="uploadDoc">
          保存
        </el-button>
      </div>
    </template>
  </el-dialog>

  <el-dialog v-model="show.invalidDocDialog" title="提示" width="200">
    <span>确认作废？</span>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="show.invalidDocDialog = false">取消</el-button>
        <el-button type="primary" @click="handleDocInvalid">
          确认
        </el-button>
      </div>
    </template>
  </el-dialog>

  <el-dialog v-model="show.delDocDialog" title="提示" width="200">
    <span>确认删除？</span>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="show.delDocDialog = false">取消</el-button>
        <el-button type="primary" @click="handleDocDel">
          确认
        </el-button>
      </div>
    </template>
  </el-dialog>

  <el-dialog v-model="show.delNodeDialog" title="提示" width="250">
    <span>该节点下文档都将被删除,确认删除？</span>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="show.delNodeDialog = false">取消</el-button>
        <el-button type="primary" @click="handleDelNode">
          确认
        </el-button>
      </div>
    </template>
  </el-dialog>

</template>

<script>
import { useAuthStore } from '@/stores/authStore';
import dayjs from 'dayjs';
import { ElMessage, ElLoading } from 'element-plus';
import { v4 as uuidv4 } from 'uuid';

export default {
  data() {
    return {
      treeData: [],
      docNode: [],
      docList: [],
      keyword: '',
      show: {
        nodeManagerDialog: false,
        delNodeBtn: false,
        addNodeBtn: false,
        addNodeDialog: false,
        delNodeDialog: false,
        uploadDocDialog: false,
        delDocDialog: false,
        invalidDocDialog: false,
      },
      uploadForm: {},
      docDetail: {
        owner: '',
        uploadDate: '',
        version: "",
        status: '-1',
        expiredDate: '',
        type: '',
        size: ''
      },
      pagination: {
        total: 0,
        size: 5,
        current: 1,
        count: 5,
      },
      selectedDoc: [],
      permission: {
        upload: true,
        download: true,
        delete: true,
        invalid: true,
      },
      menuPosition: { x: 0, y: 0 },
      selectedNode: null,
      addNodeForm: {
        name: '',
      }
    };
  },
  computed: {
  },
  methods: {
    clickMenuNode(node) {
      this.show.nodeManagerDialog = false;
      if (node.level == 0) {
        this.pagination.total = 0;
        this.docList = [];
      }
     else if (node.level == 1) {
      this.pagination.total = 0;
      this.docList = [];
      }
    else  if (node.level == 2) {
        this.setDocListFolderId(node.parent.typeid, node.parent.typename, node.row.foldid, node.row.fileFlodName);
      }
    },
    handleContextMenu(event) {
      event.preventDefault();
      this.show.nodeManagerDialog = false;
    },
    handleNodeContextMenu(event, node) {
      event.preventDefault();
      if (node.level == 0) {
       
        this.show.delNodeBtn = false;
        this.show.addNodeBtn = true;
      }
      if (node.level == 1) {
        this.show.delNodeBtn = true;
        this.show.addNodeBtn = true;
      }
      if (node.level == 2) {
        this.show.delNodeBtn = true;
        this.show.addNodeBtn = false;
      }
      this.menuPosition.x = event.clientX;
      this.menuPosition.y = event.clientY;
      this.selectedNode = node;
      this.show.nodeManagerDialog = true;
    },
    openAddNodeDialog() {
      this.show.nodeManagerDialog = false;
      this.show.addNodeDialog = true;
    },
    closeAddNodeDialog() {
      this.show.addNodeDialog = false;
      this.addNodeForm.name = '';
    },
    openDeleteNodeDialog() {
      this.show.delNodeDialog = true;
      this.show.nodeManagerDialog = false;
    },
    async getDocNode() {
      const loading = ElLoading.service({
        fullscreen: true,
        lock: true,
        text: '加载中...',
        background: 'rgba(255, 255, 255, 0.1)'
      })
      try {
        const authStore = useAuthStore();
        const response = await authStore.getDocumentNode();
        if (response.code === 200) {
          var nodes = [];
          for (let i = 0; i < response.data.FileType.length; i++) {
            var child = [];
            for (let j = 0; j < response.data.FileType[i].fileFoldDtoList.length; j++) {
              child.push({ "level": 2, "parent": response.data.FileType[i], "row": response.data.FileType[i].fileFoldDtoList[j], "label": response.data.FileType[i].fileFoldDtoList[j].fileFoldName })
            }
            nodes.push({ "level": 1, "row": response.data.FileType[i], "label": response.data.FileType[i].typename, "children": child })
          }
          this.treeData = [{ "level": 0, "label": "文档管理", "children": nodes }];
          this.docNode = response.data.FileType;
        }
      } catch (error) {
        ElMessage.error("获取节点列表失败");
        console.error('get doc node err:', error);
      } finally {
        loading.close()
      }
    },
    setDocListFolderId(fileTypeId, fileTypeName, folderId, folderName) {
      this.keyword = ""
      this.uploadForm.fileTypeId = fileTypeId
      this.uploadForm.fileTypeName = fileTypeName
      this.uploadForm.folderId = folderId
      this.uploadForm.folderName = folderName
      this.getDocList();
    },
    async getDocList() {
      const loading = ElLoading.service({
        fullscreen: true,
        lock: true,
        text: '加载中...',
        background: 'rgba(255, 255, 255, 0.1)'
      })
      try {
        const authStore = useAuthStore();
        const response = await authStore.getDocumentList({ folderId: this.uploadForm.folderId, pageIndex: this.pagination.current, pageSize: this.pagination.size });
        if (response.code === 200) {
          this.pagination.total = response.data.totalCount;
          this.docList = response.data.FileList;
        }
      } catch (error) {
        ElMessage.error("获取文档列表失败");
        console.error('Error fetching patients:', error);
      } finally {
        loading.close()
      }
    },
    clearUploadForm(clearFolderId) {
      if (clearFolderId) {
        this.uploadForm.fileTypeId = '';
        this.uploadForm.fileTypeName = '';
        this.uploadForm.folderId = '';
        this.uploadForm.folderName = '';
      }

      this.uploadForm.file = null;
      this.uploadForm.fileName = '';
      this.uploadForm.fileType = '';
      this.uploadForm.fileSize = '';
      this.uploadForm.owner = '';
      this.uploadForm.createTime = '';
      this.uploadForm.version = '';
      this.uploadForm.status = 0;
      this.uploadForm.expiredDate = '';
    },
    async searchDoc(resetPage) {
      if (this.keyword == "") {
        return;
      }

      const loading = ElLoading.service({
        fullscreen: true,
        lock: true,
        text: '加载中...',
        background: 'rgba(255, 255, 255, 0.1)'
      })

      if (resetPage) {
        this.pagination.current = 1
      }

      try {
        const authStore = useAuthStore();
        const response = await authStore.searchDocument({ fileName: this.keyword, pageIndex: this.pagination.current, pageSize: this.pagination.size });
        if (response.code === 200) {
          this.pagination.total = response.data.totalCount;
          this.docList = response.data.FileList;
          this.clearUploadForm(true);
        }
      } catch (error) {
        ElMessage.error("查询文档失败");
        console.error('search doc err:', error);
      } finally {
        loading.close()
      }
    },
    async uploadDoc() {
      if (this.uploadForm.folderId == '') {
        ElMessage.error("请选择文件夹");
        return;
      }

      if (this.uploadForm.file == null) {
        ElMessage.error("请选择文件");
        return;
      }

      if (this.uploadForm.expiredDate == '') {
        ElMessage.error("请选择过期时间");
        return;
      }

      if (this.uploadForm.version == '') {
        ElMessage.error("请填写文件版本");
        return;
      }

      const loading = ElLoading.service({
        fullscreen: true,
        lock: true,
        text: '加载中...',
        background: 'rgba(255, 255, 255, 0.1)'
      })

      const formData = new FormData();
      formData.append('file', this.uploadForm.file)
      const detail = {
        id: '',
        file_name: this.uploadForm.fileName,
        file_type: this.uploadForm.fileType,
        file_size: this.uploadForm.fileSize,
        owner: '',
        create_time: '',
        version: this.uploadForm.version,
        status: '',
        folder_id: this.uploadForm.folderId,
        folder_name: this.uploadForm.folderName,
        file_type_id: this.uploadForm.fileTypeId,
        file_type_name: this.uploadForm.fileTypeName,
        file_valid_time: this.getDate(this.uploadForm.expiredDate),
      };
      const json = JSON.stringify(detail)
      const blob = new Blob([json], {
        type: 'application/json'
      })
      formData.append('fileDetailDto', blob)

      try {
        const authStore = useAuthStore();
        const response = await authStore.uploadDocument(formData);
        if (response.code === 200) {
          ElMessage.success("上传成功");
          this.show.uploadDocDialog = false;
          this.clearUploadForm();
          this.refreshDocList();
        }
      } catch (error) {
        ElMessage.error("上传失败！");
        console.error('upload doc err:', error);
      } finally {
        loading.close()
      }
    },
    refreshDocList() {
      if (this.keyword != "") {
        this.searchDoc(false);
        return;
      }
      this.getDocList();
    },
    handlePageChange(page) {
      this.pagination.current = page;
      this.refreshDocList();
    },
    handleSizeChange(size) {
      this.pagination.size = size;
      this.pagination.current = 1;
      this.getDocList();
    },
    handleSelectFile() {
      document.getElementById('file-input').click();
    },
    getFileExtension(filename) {
      const parts = filename.split('.');
      if (parts.length > 1) {
        return parts.pop().toUpperCase(); // 转换为小写以进行不区分大小写的比较  
      }
      return ''; // 没有找到扩展名  
    },
    formatFileSize(bytes) {
      if (bytes === 0) return '0 B';
      const k = 1024; // 或 1000 如果使用十进制  
      const dm = decimalPlaces => num => num.toFixed(decimalPlaces).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return dm(2)(bytes / Math.pow(k, i)) + ' ' + sizes[i];
    },
    closeUploadDialog() {
      this.show.uploadDocDialog = false;
      this.clearUploadForm(false);
    },
    handleFileChange(event) {
      if (event.target.files.length == 0) {
        return;
      }
      this.uploadForm.file = event.target.files[0];
      this.uploadForm.fileName = event.target.files[0].name;
      this.uploadForm.fileType = this.getFileExtension(event.target.files[0].name);
      this.uploadForm.fileSize = this.formatFileSize(event.target.files[0].size);
    },
    getDate(val) {
      if (!val) return '';
      return dayjs(val).format('YYYY-MM-DD');
    },
    clearDocItem() {
      this.docDetail = {
        owner: '',
        uploadDate: '',
        version: '',
        status: '-1',
        expiredDate: '',
        type: '',
        size: '',
      }
    },
    clickDocItem(event) {
      this.docDetail = {
        owner: event.owner,
        uploadDate: this.getDate(event.create_time),
        version: event.version,
        status: event.status,
        expiredDate: event.file_valid_time,
        type: event.file_type,
        size: this.formatFileSize(event.file_size),
      }
    },
    selectDocItem(event) {
      this.selectedDoc = event
    },
    async initRoleList() {
      return;
      try {
        const authStore = useAuthStore();
        const response = await authStore.getRoleList({ folderId: folderId });
        console.log(response)
        if (response.code === 200) {
          // var list = [];
          // for (let i = 0; i < response.data.length; i++) {
          //   nodes.push({ "id": response.data[i].id, "name": response.data.FileType[i].typename, "child": child })
          // }
          this.docList = response.data;
        }
      } catch (error) {
        ElMessage.error("获取数据失败");
        console.error('Error fetching patients:', error);
      }
    },
    async handleDocDownload() {
      if (this.selectedDoc.length == 0) {
        return;
      }

      try {
        const authStore = useAuthStore();
        const response = await authStore.downloadDocument(this.selectedDoc);
        console.log('下载URL:', response);        
        if (response.code === 200 && response.data && response.data.length > 0) {
          let hasError = false;
          
          for (let i = 0; i < response.data.length; i++) {
            const currentDoc = this.selectedDoc[i];
            const currentUrl = response.data[i];        
            const xhr = new XMLHttpRequest();
            xhr.open('GET', currentUrl, true);
            xhr.responseType = 'blob';
            
            xhr.onload = () => {
              if (xhr.status === 200) {
                const blob = xhr.response;
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                
                // 使用原始文件名和类型
                const fileName = currentDoc.file_name + '.' + currentDoc.file_type.toLowerCase();
                a.download = fileName;
                
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
                
                if (i === response.data.length - 1 && !hasError) {
                  ElMessage.success("下载成功");
                }
              } else {
                console.error('Download failed with status:', xhr.status);
                hasError = true;
                ElMessage.error(`文件 ${currentDoc.file_name} 下载失败: ${xhr.status}`);
              }
            };
            
            xhr.onerror = () => {
              hasError = true;
              ElMessage.error(`文件 ${currentDoc.file_name} 下载失败`);
              console.error('XHR error');
            };
            
            xhr.send();
          }
        } else {
          ElMessage.error("获取下载地址失败");
        }
      } catch (error) {
        console.error('download doc err:', error);
        ElMessage.error("获取下载地址失败");
      }
    },
    openDocInvalidDialog() {
      if (this.selectedDoc.length == 0) {
        return;
      }
      this.show.invalidDocDialog = true;
    },
    async handleDocDel() {
      if (this.selectedDoc.length == 0) {
        return;
      }

      const loading = ElLoading.service({
        fullscreen: true,
        lock: true,
        text: '加载中...',
        background: 'rgba(255, 255, 255, 0.1)'
      })

      this.show.delDocDialog = false;
      var files = [];

      for (var i = 0; i < this.selectedDoc.length; i++) {
        files.push({ fileTypeId: this.selectedDoc[i].file_type_id, folderId: this.selectedDoc[i].folder_id, fileStr: [this.selectedDoc[i].id + "." + this.selectedDoc[i].file_type] })
      }

      try {
        const authStore = useAuthStore();
        const response = await authStore.deleteDocument(files);
        if (response.code === 200) {
          this.refreshDocList();
          this.clearDocItem();
        } else {
          ElMessage.error("删除失败");
        }
      } catch (error) {
        ElMessage.error("删除失败");
        console.error('del doc:', error);
      } finally {
        loading.close()
      }
    },
    async handleDocInvalid() {
      if (this.selectedDoc.length == 0) {
        ElMessage.error("请选择内容");
        return;
      }

      const loading = ElLoading.service({
        fullscreen: true,
        lock: true,
        text: '加载中...',
        background: 'rgba(255, 255, 255, 0.1)'
      })

      var fileIds = [];
      for (var i = 0; i < this.selectedDoc.length; i++) {
        fileIds.push(this.selectedDoc[i].id)
      }

      this.show.invalidDocDialog = true;
      try {
        const authStore = useAuthStore();
        const response = await authStore.invalidDocument(fileIds);
        if (response.code === 200) {
          this.show.invalidDocDialog = false;
          this.docDetail.status = '0';
          this.refreshDocList();
        } else {
          ElMessage.error("更新数据失败");
        }
      } catch (error) {
        ElMessage.error("更新数据失败");
        console.error('Error fetching patients:', error);
      } finally {
        loading.close()
      }
    },
    openDocDelDialog() {
      if (this.selectedDoc.length == 0) {
        ElMessage.error("请选择内容");
        return;
      }
      this.show.delDocDialog = true;
    },
    async handleAddNode() {
      if (this.addNodeForm.name == '') {
        ElMessage.error("请输入节点名称");
        return;
      }

      const loading = ElLoading.service({
        fullscreen: true,
        lock: true,
        text: '加载中...',
        background: 'rgba(255, 255, 255, 0.1)'
      })

      let nodes = JSON.parse(JSON.stringify(this.docNode));

      if (this.selectedNode.level == 0) {
        nodes.push({ typeid: uuidv4(), typename: this.addNodeForm.name, fileFoldDtoList: [] })
      } else {
        for (let i = 0; i < nodes.length; i++) {
          if (nodes[i].typeid == this.selectedNode.row.typeid) {
            nodes[i].fileFoldDtoList.push({ foldid: uuidv4(), fileFoldName: this.addNodeForm.name })
            break;
          }
        }
      }

      try {
        const authStore = useAuthStore();
        const response = await authStore.addDocumentNode({ FileType: nodes });
        if (response.code === 200) {
          this.show.addNodeDialog = false;
          this.addNodeForm.name = '';
          ElMessage.success("添加成功");
          this.getDocNode();
        }
      } catch (error) {
        ElMessage.error("添加失败");
        console.error('add doc node err:', error);
      } finally {
        loading.close()
      }
    },
    async handleDelNode() {
      const loading = ElLoading.service({
        fullscreen: true,
        lock: true,
        text: '加载中...',
        background: 'rgba(255, 255, 255, 0.1)'
      })

      var params = {};
      if (this.selectedNode.level == 1) {
        params = { fileTypeId: this.selectedNode.row.typeid, fileTypeName: this.selectedNode.row.typename, folderId: '', folderName: '' }
      } else if (this.selectedNode.level == 2) {
        params = { fileTypeId: this.selectedNode.parent.typeid, fileTypeName: this.selectedNode.parent.typename, folderId: this.selectedNode.row.foldid, folderName: this.selectedNode.row.fileFoldName }
      } else {
        return;
      }

      try {
        const authStore = useAuthStore();
        const response = await authStore.delDocumentNode(params);
        if (response.code === 200) {
          this.show.delNodeDialog = false;
          ElMessage.success("删除成功");
          this.getDocNode();
        }
      } catch (error) {
        ElMessage.error("删除失败");
        console.error('del doc node err:', error);
      } finally {
        loading.close()
      }
    },
  },
  async mounted() {
    document.addEventListener("click", (event) => {
      if (!event.target.closest('.add-node') && this.show.nodeManagerDialog) {
        this.show.nodeManagerDialog = false;
      }
    });
    await this.getDocNode();
    await this.initRoleList();
    this.clearUploadForm(true);
  },
};
</script>

<style scoped>
/* 样式保持不变 */

/* 覆盖展开/收起图标 */
:deep(.custom-tree .el-tree-node__expand-icon) {
  display: none !important;
}

/* 可选：覆盖节点图标，如果需要的话 */
:deep(.custom-tree .el-tree-node__icon) {
  display: none !important;
}

.add-node {
  background-color: white;
  width: 110px;
  position: absolute;
  z-index: 9999;
  border: 1px solid #C2C3C4;
  padding-bottom: 8px;
}

.search-btn {
  width: 200px;
  margin-left: 52px;
}

.button-group {
  width: 100%;
  float: right;
  right: 0;
  margin-right: 152px;
  display: flex;
  display: flex;
  justify-content: flex-end;
}

.button-group img {
  margin-right: 4px;
}

.button-split {
  width: 1px;
  margin: 0 12px;
  color: #C2C3C4;
}

.registration-page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 20px);
  width: calc(100% - 20px);
  background-color: #474747;
  overflow: hidden;
  margin-bottom: 20px;
  font-weight: 400;
}

:deep(.el-table__inner-wrapper:before) {
  height: 0;
}

:deep(.el-sub-menu__title) {
  margin-top: 12px;
  height: 20px;
  line-height: 20px;
}

:deep(.el-menu-item) {
  height: 20px !important;
  line-height: 20px !important;
  margin-top: 8px;
}

.header {
  background-color: #474747;
  color: white;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
}

.filter-bar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.filter-input,
.filter-date {
  width: 150px;
}

.ctrl-button {
  width: 88px;
}

.registration-page .content {
  border-radius: 12px;
  display: flex;
  flex: 1;
  padding: 10px;
  overflow: hidden;
  background-color: #f5f6f7;
}

.left-pane {
  width: 382px;
  padding: 5px;
  background-color: white;
  margin-right: 12px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 12px;
}

.document-menu-icon {
  width: 15px;
  height: 15px;
  margin-left: 12px;
}

.right-pane {
  flex: 1;
  overflow: hidden;
  border-radius: 12px;
  position: relative;
}

.table-container {
  height: 100%;
  width: calc(100% - 372px);
  background-color: white;
  overflow: hidden;
  border-radius: 12px;
  display: block;
  float: left;
}

.table-list {
  height: calc(100% - 56px);
  overflow-y: scroll;
}

.pagination {
  width: 100%;
  height: 50px;
}

.detail-container {
  height: 100%;
  width: 360px;
  float: right;
  background-color: white;
}

.detail-header {
  width: 100%;
  height: 36px;
  margin-top: 0;
  background-color: #F5F6F7;
}

.detail-header p {
  height: 20px;
  font-size: 14px;
  width: 50%;
  margin-top: 0;
  line-height: 36px;
}

.detail-body {
  background-color: white;
}

.detail-body .el-form-item {
  height: 36px;
  margin-top: 0;
}

.detail-item {
  border-bottom: 1px solid #DCDDDE;
  height: 36px;
  margin-bottom: 0;
}

.detail-form {
  margin: 0 12px;
}

:deep(.detail-container .el-form-item__label) {
  margin-left: 8px;
  color: #5E6266;
}

:deep(.detail-container .el-form-item__content) {
  color: #000;
}

.total-records {
  text-align: right;
  padding: 10px;
  font-size: 14px;
  color: #666;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 0;
  margin-top: auto;
}

.action-buttons {
  position: absolute;
  right: 15px;
  bottom: 8px;
  display: flex;
  gap: 10px;
}

.barcode-button {
  margin: 0;
}

.upload-form {
  width: 468px;
  font-size: 14px;
  color: #4D4D4D;
}

.upload-form .el-form-item {
  height: 36px;
  margin-top: 20px;
  margin-bottom: 0;
}

:deep(.el-form-item__label) {
  font-size: 14px;
  letter-spacing: 0em;
  padding: 0 !important;
}
</style>
